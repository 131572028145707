import React from 'react';
import { BsCalendar } from 'react-icons/bs';

const IconWelcome = () => {
    const getCurrentDate = () => {
        const date = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    return (
        <>
            <h5 className="font-semibold text-md">Dashboard Overview</h5>
            <div className="bg-blue-600 w-full rounded-lg mb-8">
                <div className="flex h-full">
                    <img src="/images/avatar.png" alt="Logo" className="h-full my-2 mx-2" />
                    <div className="flex flex-col">
                        <div className="flex items-center justify-end mr-6 mt-3">
                            <span className="text-xs text-right text-white mr-2 font-light">{getCurrentDate()}</span>
                            <BsCalendar size={16} className="text-white" />
                        </div>
                        <h5 className="font-semibold text-md text-white">Dashboard Overview</h5>
                        <p className="font-light text-sm text-white">
                            Here at Cupet, we're dedicated to helping you manage your finances with ease. Our goal is to simplify financial
                            tracking, so you can spend less time on spreadsheets and more time enjoying what you've worked hard for.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IconWelcome;
