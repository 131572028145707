import React from 'react';
import TransactionCategorizationComponent from '../steps/TransactionCategorizationComponent';
import SideBarContent from './SideBarContent';
import { SACK_DOLLAR_ICON, TRANSACTIONS_BUTTONS } from '../../constants/common';
import Header from './Header';
import LeftNavigation from './LeftNavigation';

const CategorizeSection = () => {
    return (
        <>
            <div className="bg-darkWhite">
                <Header />
                <div className="flex">
                    <div className="hidden md:block lg:block">
                        <LeftNavigation selectedButton={SACK_DOLLAR_ICON} />
                    </div>
                    <div className="grid grid-cols-12">
                        <div className="hidden md:col-span-0 lg:col-span-2 lg:block">
                            <SideBarContent
                                title={'Transactions'}
                                desc={'Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.'}
                                logo={'fas fa-sack-dollar'}
                                buttons={TRANSACTIONS_BUTTONS}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-10 mt-10">
                            <span className="text-lg font-semibold mx-14 pb-10">Categorize Your Transactions</span>
                            <TransactionCategorizationComponent hideButton/>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CategorizeSection;
