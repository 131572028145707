import React from 'react';
import NicknameAccountsComponent from '../steps/NicknameAccountsComponent';
import PlaidElement from '../steps/PlaidElement';
import ManualAccountModal from '../steps/ManualAccountModal';
import Header from './Header';
import LeftNavigation from './LeftNavigation';
import { SETTINGS_BUTTONS, USER_GEAR_ICON } from '../../constants/common';
import SideBarContent from './SideBarContent';

const BankInfoSection = () => {
    return (
        <div className='bg-darkWhite w-screen'>
            <Header />
            <div className='flex'>
                <div className='hidden md:block lg:block'>
                    <LeftNavigation selectedButton={USER_GEAR_ICON} />
                </div>
                <div className='grid grid-cols-12'>
                    <div className='hidden md:col-span-0 lg:col-span-2 lg:block'>
                        <SideBarContent
                            title={'Settings'}
                            desc={'  Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.'}
                            logo={'fa-solid fa-user-gear'}
                            buttons={SETTINGS_BUTTONS}
                        />
                    </div>
                    <div className='col-span-12 lg:col-span-10 font-manrope'>
                        <div className='h-screen'>
                            <div className='flex flex-col  h-5/6 m-5'>
                                <span className='font-semibold mb-5'>Bank Info</span>
                                <div className='flex flex-col items-start  w-full'>
                                    <span
                                        className='font-semibold mb-1 text-xl text-deepDarkBlue'>List of Bank Names</span>
                                    <NicknameAccountsComponent showCount={true} />
                                    <div className='flex justify-around mt-4 ml-0 md:ml-20'>
                                        <PlaidElement />
                                        <span className='flex items-center mx-2'>OR</span>
                                        <ManualAccountModal />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BankInfoSection;
