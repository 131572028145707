import { createSlice } from '@reduxjs/toolkit';
import network from '../../services/network';
import { GET_CATEGORY_INSIGHT_GRAPH_DATA, GET_EARLIEST_TRANSACTION_DATE } from '../../constants/network-calls';
import { showToast } from '../toast-handler/toast-handler';
import Cookies from 'js-cookie';
import { DATE_TIME_FORMAT, FAILURE } from '../../constants/common';
import moment from 'moment';

const reportSlice = createSlice({
    name: 'report',
    initialState: {
        earliestDate: null,
        individualGraphsData: null,
        collectionData: null,
        isDetailedDataLoading: false,
    },
    reducers: {
        setEarliestDate(state, action) {
            state.earliestDate = action.payload;
        },
        setIndividualGraphsData(state, action) {
            state.individualGraphsData = action.payload;
        },
        setCollectionData(state, action) {
            state.collectionData = action.payload;
        },
        setIsDetailedDataLoading(state, action) {
            state.isDetailedDataLoading = action.payload;
        },
    },
});

// --------------------------------------------THUNK FUNCTIONS----------------------------------------

export const getEarliestTransactionDate = () => (dispatch, getState) => {
    const id = Cookies.get('userId');

    const headers = {
        Authorization: `${id}`,
    };

    network
        .fetch('POST', GET_EARLIEST_TRANSACTION_DATE, { id }, null, headers)
        .then(res => {
            const { data } = res;
            dispatch(setEarliestDate(data.earliestDate));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getCategoryInsightGraphData = (selectedMonth, numberOfMonths) => (dispatch, getState) => {
    const id = Cookies.get('userId');
    const selectedMonthDate = moment(selectedMonth).format(DATE_TIME_FORMAT);
    const headers = {
        Authorization: `${id}`,
    };

    // dispatch(setIsDetailedDataLoading(true));

    network
        .fetch('POST', GET_CATEGORY_INSIGHT_GRAPH_DATA, { id, selectedMonthDate, numberOfMonths }, null, headers)
        .then(res => {
            const { data } = res;
            const { allData, collectionData } = data;
            dispatch(setIndividualGraphsData(allData));
            dispatch(setCollectionData(collectionData));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
    // .finally(() => {
    //     dispatch(setIsDetailedDataLoading(false));
    // });
};

export const { setEarliestDate, setIndividualGraphsData, setCollectionData, setIsDetailedDataLoading } = reportSlice.actions;

export default reportSlice.reducer;
