import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { addOwner } from '../user/user.slice';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const AccountManagerModal = ({ accounts }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { handleSubmit, register, reset } = useForm();

    const onSubmit = data => {
        dispatch(addOwner(data, selectedAccount));
        reset();
        setShow(false);
    };

    useEffect(() => {
        makeOptions();
        // eslint-disable-next-line
    }, []);

    const makeOptions = () => {
        const generatedOptions = [];
        for (let bank of accounts) {
            const { id, bankName, lastFourDigits, bankNickName } = bank;
            const label = `${bankName} - ${lastFourDigits} - ${bankNickName}`;

            generatedOptions.push({ value: id, label: label });
        }
        setOptions(generatedOptions);
    };

    const handleDropdownChange = selectedOption => {
        setSelectedAccount(selectedOption.value);
    };

    return (
        <div>
            <>
                <div className="flex items-end  justify-center md:justify-end">
                    <button className="flex items-end bg-blue-600 rounded-md px-2 py-2 w-56 justify-end " onClick={handleShow}>
                        <span className="text-base font-semibold text-white">Add New Account Manager</span>
                    </button>
                </div>

                <Modal
                    className="modal-popup"
                    show={show}
                    onHide={handleClose}
                    dialogClassName="modal-dialog modal-lg"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="p-3">Add New Account Manager:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-5">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-4 mb-2">Full Name</div>
                                <div className="col-8 mb-2">
                                    <input
                                        className="form-control input-lg"
                                        {...register('fullName', {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 mb-2">Email</div>
                                <div className="col-8 mb-2">
                                    <input
                                        className="form-control input-lg"
                                        {...register('email', {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 mb-2">SMS#</div>
                                <div className="col-8 mb-2">
                                    <input
                                        className="form-control input-lg"
                                        {...register('phone', {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 mb-2">Choose Account:#</div>
                                <div className="col-8 mb-2">
                                    <Dropdown
                                        options={options}
                                        // onChange={}
                                        onChange={handleDropdownChange}
                                        value={options[0]}
                                        placeholder="Select an option"
                                    />
                                </div>
                            </div>

                            <button type="submit" className="flex items-center bg-blue-600 rounded-md px-2 py-2 w-30 mt-4">
                                <span className="text-base font-semibold text-white">Add New Manager</span>
                            </button>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
        </div>
    );
};

export default AccountManagerModal;
