import React, { useState, useCallback } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker, DateRange } from 'react-date-range';
import { useDispatch } from 'react-redux';
import { formatDate } from '../utilities/helperFunctions';
import _ from 'lodash';
import { getMasterDataTableData } from '../dashboard/dashboard.slice';

const DateRangeComponent = () => {
    const dispatch = useDispatch();

    const currentDate = new Date();
    const sixMonthsAgo = new Date(currentDate);
    sixMonthsAgo.setMonth(currentDate.getMonth() - 6);

    const [selectedRange, setSelectedRange] = useState([
        {
            startDate: sixMonthsAgo,
            endDate: currentDate,
            key: 'selection',
        },
    ]);

    const [popupVisible, setPopupVisible] = useState(false);

    const handleDateChange = ranges => {
        setSelectedRange([ranges.selection]);
        handleInputDebounced(ranges.selection);
    };

    const handlePopupToggle = () => {
        setPopupVisible(!popupVisible);
    };

    const handleInputDebounced = useCallback(
        _.debounce(value => {
            const startDate = value.startDate;
            const endDate = value.endDate;
            dispatch(getMasterDataTableData(null, startDate, endDate));
        }, 2000),
        [],
    );

    return (
        <div className="relative pb-5 lg:pb-0">
            <div className="flex flex-col md:flex-row md:items-center md:space-x-2">
                <button className="bg-blue-600 rounded-md px-2 py-2 md:h-10 md:w-auto mt-2" onClick={handlePopupToggle}>
                    <span className="text-xs md:text-base font-semibold text-white ">Select Date Ranges</span>
                </button>
                <div className="mt-2 md:mt-0 flex flex-col md:flex-row md:space-x-2">
                    <div className="flex items-center justify-center border-2 border-blueBorder shadow rounded-md h-10 px-3">
                        {formatDate(selectedRange[0].startDate)}
                    </div>
                    <span className="flex justify-center md:items-center">to</span>
                    <div className="flex items-center justify-center border-2 border-blueBorder shadow rounded-md h-10 px-3">
                        {formatDate(selectedRange[0].endDate)}
                    </div>
                </div>
            </div>
            {popupVisible && (
                <div className="flex flex-col items-center date-range-popup fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-gray-300 shadow p-4 md:hidden">
                    <DateRange
                        editableDateInputs={true}
                        onChange={handleDateChange}
                        moveRangeOnFirstSelection={false}
                        ranges={selectedRange}
                    />
                    <button className="flex items-center bg-blue-600 rounded-md px-2 py-2 mt-4" onClick={handlePopupToggle}>
                        <span className="text-base font-semibold text-white"> Done Selecting</span>
                    </button>
                </div>
            )}

            {popupVisible && (
                <div className="flex flex-col items-center date-range-popup fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-gray-300 shadow p-4 hidden md:block">
                    <DateRangePicker ranges={selectedRange} onChange={handleDateChange} responsive={true} />

                    <button className="flex items-center bg-blue-600 rounded-md px-2 py-2 mt-4" onClick={handlePopupToggle}>
                        <span className="text-base font-semibold text-white"> Done Selecting</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default DateRangeComponent;
