import React from 'react';
import { formatDate } from '../utilities/helperFunctions';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const TransactionsList = ({ transactions }) => {
    return (
        <div className="my-10 mx-3 overflow-x-auto max-h-64">
            {transactions.map(transaction => {
                const { date, amount, description } = transaction;

                return (
                    <div key={transaction.id} className="flex pl-10">
                        <span data-tip={description} className={`truncate my-anchor-element`} title={description}>
                            {formatDate(date)} - {description} - ${amount}
                        </span>
                    </div>
                );
            })}

            <Tooltip />
        </div>
    );
};

export default TransactionsList;
