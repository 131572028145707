import React, { useEffect } from 'react';
import FileUploadContainer from './FileUploadContainer';
import { getBankList } from '../user/user.slice';
import { useDispatch, useSelector } from 'react-redux';

const UploadCsvComponent = () => {
    const dispatch = useDispatch();

    const { banks } = useSelector(state => state.user);

    useEffect(() => {
        dispatch(getBankList());
        // eslint-disable-next-line
    }, []);

    return <>{banks && <FileUploadContainer banks={banks} />}</>;
};

export default UploadCsvComponent;
