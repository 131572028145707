import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../user/user.slice';
import { FILE_INPUTS, DATE_FORMATS } from '../../constants/common';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Papa from 'papaparse';

const FileUploadContainer = ({ banks }) => {
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedDateFormat, setSelectedDateFormat] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [fileHeaders, setFileHeaders] = useState([]);
    const [options, setOptions] = useState([]);
    const [dateOptions, setDateOptions] = useState([]);
    const [dateValue, setDateValue] = useState(null);
    const [firstLine, setFirstLine] = useState(null);

    const { isFileUploading } = useSelector(state => state.user);

    useEffect(() => {
        makeOptions();
        // eslint-disable-next-line
    }, []);

    const handleOptionChange = (title, value, option) => {
        setSelectedOptions(prevSelectedOptions => {
            const existingOption = prevSelectedOptions.find(item => item.title === title);
            if (existingOption) {
                const updatedOptions = prevSelectedOptions.map(item => {
                    if (item.title === title) {
                        return { title, value, option };
                    }
                    return item;
                });

                if (value === 'date') {
                    const date = firstLine[option];
                    setDateValue(date);
                }
                return updatedOptions;
            } else {
                const newVal = [...prevSelectedOptions, { title, value, option }];
                if (value === 'date') {
                    const date = firstLine[option];
                    setDateValue(date);
                }
                return newVal;
            }
        });
    };

    const makeOptions = () => {
        const generatedOptions = [];
        for (let bank of banks) {
            const { id, bankName, lastFourDigits, bankNickName } = bank;
            const label = `${bankName} - ${lastFourDigits} - ${bankNickName}`;
            generatedOptions.push({ value: id, label: label });
        }
        setOptions(generatedOptions);

        const generatedDateOptions = DATE_FORMATS.map(dateFormat => {
            const { format, example } = dateFormat;
            const label = `${format} (Example: ${example})`;
            return { value: format, label: label };
        });

        setDateOptions(generatedDateOptions);
    };

    const handleFileChange = event => {
        const file = event.target.files[0];
        setSelectedFile(file);

        // Read the file contents and extract headers
        const reader = new FileReader();
        reader.onload = e => {
            const contents = e.target.result;

            const { data } = Papa.parse(contents);
            const headers = data[0];
            setFirstLine(data[1]);
            const alphabetArray = headers.map((header, index) => {
                const alphabetLetter = String.fromCharCode(65 + index);
                return `Col ${alphabetLetter} - ${header}`;
            });

            setFileHeaders(alphabetArray);
        };
        reader.readAsText(file);
    };

    const refreshForm = () => {
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.value = '';
        }

        setFileHeaders([]);
        setDateValue(null);
        setSelectedFile(null);
        setSelectedDateFormat(null);
        setSelectedAccount(null);
    };

    const handleUpload = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            dispatch(uploadFile(formData, selectedOptions, selectedAccount, selectedDateFormat, null, setDateValue, refreshForm));
        }
    };

    const handleDateDropdownChange = selectedOption => {
        setSelectedDateFormat(selectedOption.value);
    };

    const handleDropdownChange = selectedOption => {
        setSelectedAccount(selectedOption.value);
    };

    return (
        <div className="bg-white p-10 sm:mx-0 lg:mx-10">
            <div className="font-semibold text-lg pb-3 text-deepDarkBlue">Upload CSV File</div>
            <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2 md:col-span-1">
                    <div className="mb-3">
                        File Name:
                        <input id="fileInput" className="py-3" accept=".csv" type="file" onChange={handleFileChange} />
                    </div>
                    {fileHeaders.length > 0 && (
                        <>
                            {FILE_INPUTS.map(file => (
                                <div className="my-3" key={file.name}>
                                    {file.name}
                                    <Dropdown
                                        options={fileHeaders.map((option, index) => ({
                                            label: option,
                                            value: index,
                                        }))}
                                        onChange={selectedOption => handleOptionChange(file.name, file.value, selectedOption.value)}
                                        placeholder="Select an option"
                                    />
                                </div>
                            ))}
                        </>
                    )}
                </div>
                <div className="col-span-2 md:col-span-1">
                    {dateValue && (
                        <div className="mb-3">
                            <span className="font-semibold">Date Detected :</span> {dateValue}
                        </div>
                    )}
                    <div className="mb-3">
                        Choose Date Format :
                        <Dropdown
                            options={dateOptions}
                            onChange={handleDateDropdownChange}
                            placeholder="Choose Date Format..."
                            value={selectedDateFormat ? selectedDateFormat : 'Choose Date Format...'}
                        />
                    </div>
                    <div>
                        Choose Account :
                        <Dropdown
                            options={options}
                            onChange={handleDropdownChange}
                            placeholder="Choose Account..."
                            value={selectedAccount ? selectedAccount : 'Choose Account...'}
                        />
                    </div>
                </div>
            </div>
            {isFileUploading ? <h6 className="mt-4 text-green-600">File Uploading In Progress...</h6> : null}
            <button
                className={`flex items-center justify-center rounded-md px-3 py-2 w-40 ml-4 mt-4 ${
                    isFileUploading ? 'bg-gray-300' : 'bg-blue-600'
                }`}
                onClick={handleUpload}
                disabled={isFileUploading}
            >
                <span className="text-base font-semibold text-white">Save & Upload</span>
            </button>
        </div>
    );
};

export default FileUploadContainer;
