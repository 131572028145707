import React from 'react';
import { useDispatch } from 'react-redux';
import { excludeTransactionFromLists } from '../transaction/transaction.slice';
import Loader from '../utilities/Loader';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { formatDate } from '../utilities/helperFunctions';

const TransactionsList = ({ transactions, isKeywordCountLoading, keywordCount, selectedKeyword, firstName }) => {
    const dispatch = useDispatch();

    const excludeTransaction = transactionId => {
        dispatch(excludeTransactionFromLists(transactionId));
    };

    return (
        <>
            <div className="text-md font-bold">
                {isKeywordCountLoading ? (
                    <Loader />
                ) : (
                    <>
                        {keywordCount === 0 ? (
                            <>No Transactions Found...</>
                        ) : (
                            <>
                                {keywordCount} transactions with “{selectedKeyword}” keyword found from {firstName}’s account
                            </>
                        )}
                    </>
                )}
            </div>
            <div>
                <div className="h-60 2xl:h-full overflow-y-auto bg-lightBlue py-2 rounded-lg w-full scrollbar-thumb-blue-500 scrollbar-track-blue-200 scrollbar-thin">
                    {transactions && transactions.length > 0 ? (
                        <div>
                            {transactions.map(transaction => {
                                const { date, amount, description } = transaction;

                                return (
                                    <div key={transaction.id} className="px-3 flex">
                                        <span
                                            className="me-3 text-xs"
                                            key={transaction.id}
                                            onClick={() => excludeTransaction(transaction.id)}
                                        >
                                            <i className="material-icons text-red-800 cursor-pointer">close</i>
                                        </span>

                                        <span
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={description}
                                            className={`truncate my-anchor-element`}
                                        >
                                            {formatDate(date)} - {description.slice(0, 14)} - ${amount}
                                        </span>
                                    </div>
                                );
                            })}

                            <Tooltip id="my-tooltip" />
                        </div>
                    ) : transactions.length === 0 ? (
                        <>
                            <p className="px-3 ">No transactions found...</p>
                        </>
                    ) : (
                        <>
                            <Loader />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default TransactionsList;
