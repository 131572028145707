import React from 'react';
import { ProjectOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';

const LeftNavigation = ({ selectedButton }) => {
    const iconSize = 'text-xl';
    const navigate = useNavigate();

    return (
        <div className="bg-lightBlue h-full border-r">
            <div className="h-full px-2 pt-4 pb-2 flex flex-col items-start">
                <button
                    onClick={() => navigate('/dashboard')}
                    className={` ${selectedButton === 'dashboard' ? 'text-blue-500' : 'text-gray-400'} ${iconSize} my-1 py-1 `}
                >
                    <i className="material-icons">dashboard</i>
                </button>

                <button
                    onClick={() => navigate('/dashboard/transactions/categorize')}
                    className={`${
                        selectedButton === 'sack-dollar' ? 'text-blue-500' : 'text-gray-400'
                    } ${iconSize} my-1 py-1 focus:outline-none`}
                >
                    <i className="fas fa-sack-dollar"></i>
                </button>

                <button
                    onClick={() => navigate('/dashboard/reporting/detailed-summary')}
                    className={` ${
                        selectedButton === 'ant-design' ? 'text-blue-500' : 'text-gray-400'
                    } ${iconSize} my-1 py-1 focus:outline-none`}
                >
                    <ProjectOutlined className="mr-1" />
                </button>

                <button
                    onClick={() => navigate('/dashboard/settings/bank-info')}
                    className={` ${
                        selectedButton === 'user-gear' ? 'text-blue-500' : 'text-gray-400'
                    } ${iconSize} my-1 py-1 focus:outline-none`}
                >
                    <i className="fa-solid fa-user-gear"></i>
                </button>

                <button
                    onClick={() => navigate('/dashboard/master-data-table')}
                    className={` ${
                        selectedButton === 'table' ? 'text-blue-500' : 'text-gray-400'
                    } ${iconSize} my-1 py-1 focus:outline-none`}
                >
                    <i className="fa-solid fa-table"></i>
                </button>
            </div>
        </div>
    );
};

export default LeftNavigation;
