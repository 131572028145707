import React from 'react';

const CategoryFilterPopup = ({ options, selectedCategories, setSelectedCategories, onClose }) => {
    const handleCheckboxChange = category => {
        const updatedCategories = selectedCategories.includes(category)
            ? selectedCategories.filter(c => c !== category)
            : [...selectedCategories, category];
        setSelectedCategories(updatedCategories);
    }

    return (
        <div className="absolute top-60 md:top-20 right-20 bg-white p-4 border rounded shadow-md z-10">
            <h2 className="text-lg font-semibold mb-2">Filter Categories</h2>
            <div className="overflow-y-auto max-h-40">
                {options.map(option => (
                    <div key={option.value} className="flex items-center">
                        <input
                            type="checkbox"
                            checked={selectedCategories.includes(option.value)}
                            onChange={() => handleCheckboxChange(option.value)}
                            className="mr-2"
                        />
                        <span>{option.label}</span>
                        {selectedCategories.includes(option.value) && <i className="ml-2 text-green-500">✓</i>}
                    </div>
                ))}
            </div>
            <button onClick={onClose} className="mt-2 bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600">
                Apply
            </button>
        </div>
    );
};

export default CategoryFilterPopup;
