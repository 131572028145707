import React, { useState } from 'react';
import TransactionVerificationComponent from '../steps/TransactionVerificationComponent';
import 'react-datepicker/dist/react-datepicker.css';
import Header from './Header';
import LeftNavigation from './LeftNavigation';
import { TRANSACTIONS_BUTTONS } from '../../constants/common';
import SideBarContent from './SideBarContent';

const VerifySection = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    return (
        <>
            <div className="bg-darkWhite">
                <Header />
                <div className="flex">
                    <div className="hidden md:block lg:block">
                        <LeftNavigation selectedButton={'sack-dollar'} />
                    </div>
                    <div className="grid grid-cols-12">
                        <div className="hidden md:col-span-0 lg:col-span-2 lg:block">
                            <SideBarContent
                                title={'Transactions'}
                                desc={'Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.'}
                                logo={'fas fa-sack-dollar'}
                                buttons={TRANSACTIONS_BUTTONS}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-10 mx-14 my-10">
                            <span className="text-lg font-semibold mx-10">Let's Verify All Of Our Classifications</span>

                            <TransactionVerificationComponent startDate={startDate} endDate={endDate} hideButton />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VerifySection;
