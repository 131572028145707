export const HOME = '/';
export const SAVE_USER = '/user/save';
export const CREATE_LINK_TOKEN = '/user/create-link-token';
export const EXCHANGE_PUBLIC_TOKEN = '/user/exchange-public-token';
export const GET_ACCOUNTS_LIST = '/user/get-accounts-list';
export const GET_NO_MANAGER_LIST = '/user/get-no-manager-list';
export const SET_NICKNAME = '/user/save-nickname';
export const ADD_OWNER = '/user/add-owner';
export const GET_OWNER_LIST = '/user/get-owners';
export const GET_ACCOUNT_MANAGERS = '/user/get-account-managers';
export const SAVE_MANUAL_ACCOUNT = '/user/save-manual-account';
export const GET_CATEGORIES = '/user/get-categories';
export const ACCEPT_CATEGORY = '/user/accept-category';
export const REJECT_CATEGORY = '/user/reject-category';
export const OPENAI_CATEGORY_PREDICTION = '/user/openai-testing';

export const UPLOAD_FILE = '/user/upload-file';
export const INCLUDED_CATEGORIES = '/user/get-included-categories';

export const INCLUDED_CATEGORIES_COLLECTION = '/user/get-included-categories-collection';
export const SAVE_PLAID_TRANSACTIONS = '/user/fetch-plaid-transactions';
export const UPDATE_MANAGER_DETAILS = '/user/update-manager-details';
export const ASSIGN_ACCOUNT_MANAGER = '/user/assign-account-manager';
export const CATEGORISED_TRANSACTIONS_LIST = '/user/categorised-transactions-list';
export const GET_TRANSACTION_STATUS = '/user/get-transaction-status';
export const UNASSIGN_MANAGER = '/user/unassign';

// Transactions
export const GET_TRANSACTION_LIST = '/transaction/get-transaction-list';
export const COUNT_KEYWORD = '/transaction/get-keyword-count';
export const CATEGORISE = '/transaction/categorise';
export const GET_ALL_UNCATEGORISED_TRANSACTIONS = '/transaction/get-all-uncategorised-transaction-list';
export const CATEGORISE_SINGLE_TRANSACTION = '/transaction/categorise-single-transaction';
export const DELETE_CATEOGRY = '/transaction/delete-category';
export const VERIFICATION_TRANSACTIONS = '/transaction/verification-transactions';
export const VERIFICATION_TRANSACTIONS_BY_CATEGORY = '/transaction/verification-transactions-by-category';
export const CATEGORISED_VERIFICATION_TRANSACTIONS = '/transaction/categorised-verification-transactions';
export const VERIFY_TRANSACTIONS = '/transaction/verify-transactions';
export const APPLY_FILTERS = '/transaction/apply-filters';
export const DELETE_TRANSACTION = '/transaction/delete-transaction';
export const AUTOCATEGORIZE_TRANSACTION = '/transaction/autocategorize-transaction';

// Reports
export const CURRENT_MONTHLY_SPEND = '/report/current-monthly-spend';
export const LAST_MONTHLY_SPEND = '/report/last-monthly-spend';
export const AVERAGE_MONTHLY_SPEND = '/report/average-monthly-spend';
export const HIGHEST_MONTH_SPEND = '/report/highest-month-spend';
export const LOWEST_MONTH_SPEND = '/report/lowest-month-spend';
export const YEARLY_TRANSACTIONS_BY_CATEGORY = '/report/yearly-transactions-by-category';
export const YEARLY_TRANSACTIONS_BY_ALL_CATEGORIES = '/report/yearly-transactions-by-all-categories';
export const GET_EARLIEST_TRANSACTION_DATE = '/report/get-earliest-transaction-date';
export const GET_CATEGORY_INSIGHT_GRAPH_DATA = '/report/get-category-insight-graph-data';

// Bank/Account related
export const TOTAL_TRANSACTIONS_BY_ACCOUNT = '/bank/total-transactions-by-account';
export const PLAID_STATUS_OF_ITEM = '/bank/plaid-status-of-item';

export const BUDGET_CREATOR_DATA = '/report/budget-creator-data';

// Budget
export const GET_MONTHLY_INCOME = '/budget/get-monthly-income';
export const GET_MONTHLY_SAVINGS = '/budget/get-monthly-savings';
export const SET_MONTHLY_INCOME = '/budget/set-monthly-income';
export const SET_MONTHLY_SAVINGS = '/budget/set-monthly-savings';
export const SET_CATEGORY_BUDGET = '/budget/set-category-budget';
