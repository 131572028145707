import { combineReducers } from 'redux';
import userSlice from '../components/user/user.slice';
import transactionSlice from '../components/transaction/transaction.slice';
import dashboardSlice from '../components/dashboard/dashboard.slice';
import bankSlice from '../components/steps/bank.slice';
import budgetSlice from '../components/settings/budget.slice';
import reportSlice from '../components/reporting/report.slice';
import utilitiesSlice from '../components/utilities/utilities.slice';

export const combinedReducer = combineReducers({
    user: userSlice,
    transaction: transactionSlice,
    dashboard: dashboardSlice,
    bank: bankSlice,
    budget: budgetSlice,
    report: reportSlice,
    utilities: utilitiesSlice,
});
