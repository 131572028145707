import React from 'react';
import Header from './Header';
import LeftNavigation from './LeftNavigation';
import DashboardContent from './DashboardContent';
import SideBarContent from './SideBarContent';
import { DASHBOARD_ICON } from '../../constants/common';

const Dashboard = () => {
    return (
        <div className="bg-darkWhite">
            <Header />
            <div className="flex">
                <div className="hidden md:block lg:block">
                    <LeftNavigation selectedButton={DASHBOARD_ICON} />
                </div>
                <div className="grid grid-cols-12">
                    <div className="hidden md:col-span-0 lg:col-span-2 lg:block">
                        <SideBarContent
                            title={'Dashboard'}
                            desc={'Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.'}
                            logo={'dashboard'}
                            buttons={[]}
                        />
                    </div>
                    <div className="col-span-12 lg:col-span-10">
                        <DashboardContent />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
