import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ProjectOutlined } from '@ant-design/icons';

const SideBarContent = ({ title, desc, logo, buttons }) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="bg-lightBlue h-full min-h-screen font-manrope">
            <div className="h-full ">
                <div className="flex flex-col pt-10">
                    <div className="flex flex-col justify-between ml-5 mr-2 mt-4">
                        {logo === 'dashboard' ? (
                            <div className="bg-blue-300 w-12 rounded-lg mb-2">
                                <i className="material-icons text-mediumBlue mr-2 text-5xl">dashboard</i>
                            </div>
                        ) : logo === 'ant-design' ? (
                            <div className="bg-blue-300 w-12 rounded-lg mb-2">
                                <ProjectOutlined className="text-mediumBlue mr-2 text-5xl" />
                            </div>
                        ) : (
                            <div className="bg-blue-300 w-12 rounded-lg mb-2">
                                <i className={`${logo} text-mediumBlue text-5xl`}></i>
                            </div>
                        )}

                        <span className="text-xl font-semibold mb-2">{title}</span>
                        <p className="font-normal text-gray-500 leading-5">{desc}</p>

                        {buttons.map(button => {
                            return (
                                <button
                                    onClick={() => navigate(button.link)}
                                    key={button.label}
                                    className={[
                                        location.pathname.includes(button.link) ? 'bg-blue-500 text-white' : '',
                                        'border-1 border-blue-500 p-1 rounded-lg font-semibold w-5/6 mb-3',
                                    ].join(' ')}
                                >
                                    {button.label}
                                </button>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideBarContent;
