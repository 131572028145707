import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountMangers, getNoManagerList, assignAccountManager, getBankList } from '../user/user.slice';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const AccountManagerComponent = () => {
    const dispatch = useDispatch();

    const [selectedAccount, setSelectedAccount] = useState(null);
    const { accountManagersWithAccounts, accountManagers } = useSelector(state => state.user);

    useEffect(() => {
        dispatch(getBankList());
        dispatch(getNoManagerList());
        dispatch(getAccountMangers());
        // eslint-disable-next-line
    }, []);

    const handleDropdownChange = (accountId, selectedOption) => {
        dispatch(assignAccountManager(selectedOption.value, accountId));
        setSelectedAccount(selectedOption.value);
    };

    return (
        <div className="h-64 2xl:h-96 overflow-y-auto mb-3">
            <ul className="list-disc list-inside">
                {accountManagersWithAccounts && (
                    <>
                        <div>
                            {accountManagersWithAccounts.map(account => {
                                const {
                                    account_id,
                                    manager_full_name,
                                    account_bank_name,
                                    account_last_four_digits,
                                    account_bank_nick_name,
                                } = account;
                                return (
                                    <>
                                        <div key={account.account_id}>
                                            <div className="grid grid-cols-12 py-2  ">
                                                <div className="col-span-12 md:col-span-6 mt-2 ">
                                                    <span className="font-sm  ">
                                                        {account_bank_name} -{account_last_four_digits} -
                                                        {account_bank_nick_name ? account_bank_nick_name : 'No Nickname'}
                                                    </span>
                                                </div>
                                                <div className="col-span-12 md:col-span-6 ">
                                                    {accountManagers && (
                                                        <>
                                                            <Dropdown
                                                                className=" border-2 border-darkBlueStatisticBox "
                                                                options={accountManagers.map(manager => ({
                                                                    value: manager.id,
                                                                    label: manager.fullName,
                                                                }))}
                                                                onChange={selectedOption =>
                                                                    handleDropdownChange(account_id, selectedOption)
                                                                }
                                                                value={manager_full_name}
                                                                placeholder="Select an option"
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </>
                )}
            </ul>
        </div>
    );
};

export default AccountManagerComponent;
