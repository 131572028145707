import React from 'react';
import LegendWithColour from './LegendWithColour';
import { LEGEND_DETAILED_SUMMARY } from '../../constants/common';

const LegendSummaryGraph = () => {
    return (
        <div className="flex flex-col sticky top-0">
            {LEGEND_DETAILED_SUMMARY.map(legend => (
                <LegendWithColour key={legend.label} title={legend.label} color={`bg-${legend.color}`} />
            ))}
        </div>
    );
};

export default LegendSummaryGraph;
