import React, { useEffect } from 'react';
import { NICKNAME_ACCOUNTS, SELECT_CATEGORIES } from '../../constants/common';
import LeftBanner from './LeftBanner';
import Welcome from './Welcome';
import AccountManagerModal from './AccountManagerModal';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountMangers, getNoManagerList, getBankList } from '../user/user.slice';
import 'react-dropdown/style.css';
import { useNavigate } from 'react-router-dom';
import AccountManagerComponent from './AccountManagerComponent';

const AccountManager = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { banks } = useSelector(state => state.user);

    useEffect(() => {
        dispatch(getBankList());
        dispatch(getNoManagerList());
        dispatch(getAccountMangers());
        // eslint-disable-next-line
    }, []);

    return (
        <div className="grid grid-cols-12 lg:w-screen md:w-screen sm:w-screen font-manrope h-screen overflow-x-hidden relative">
            <div className="col-span-12  md:col-span-5 lg:col-span-4 h-full">
                <LeftBanner
                    step="3"
                    logo="card"
                    title="Create Account Managers"
                    desc="This step is about identifying everyone who has a card on each account. These arent necessarily the main account holders, but anyone with a card linked to the account. This will help us track individual spending accurately."
                    back={NICKNAME_ACCOUNTS}
                />
            </div>
            <div className="col-span-12 md:col-span-7 lg:col-span-8 mx-20 flex flex-col">
                <Welcome />

                <div className="grid grid-cols-12 2xl:py-10 mt-2">
                    <div className="col-span-12 items-center md:col-span-7 w-4/5 mb-2">
                        <h1 className="text-lg font-medium text-left my-2">Create account ‘’managers’’</h1>

                        <p>
                            This step is about identifying everyone who has a card on each account. These aren't necessarily the main
                            account holders, but anyone with a card linked to the account. This will help us track individual spending
                            accurately.
                        </p>
                    </div>
                    <div className="col-span-12 md:col-span-5 mt-2 items-end">{banks && <AccountManagerModal accounts={banks} />}</div>
                </div>

                <AccountManagerComponent />
                <div className="flex flex-row items-end justify-center md:justify-end mb-1 mt-auto">
                    <div>
                        <button
                            onClick={e => {
                                e.preventDefault();
                                navigate(SELECT_CATEGORIES);
                            }}
                            className="flex items-center bg-blue-600 rounded-md px-4 py-2 mt-1 sm:mt-0"
                        >
                            <span className="text-base font-semibold text-white ">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountManager;
