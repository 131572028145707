import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { deleteTransaction, getMasterDataTableData } from './dashboard.slice';
import { formatDate } from '../utilities/helperFunctions';
import { getIncludedCategories } from '../user/user.slice';
import Dropdown from './Dropdown';
import Loader from '../utilities/Loader';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { categorizeSingleTransaction } from '../transaction/transaction.slice';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { ASC, DESC, NORMAL } from '../../constants/common';
import CategoryFilterPopup from './CategoryFilterPopup';

const MasterDataTableComponent = () => {
    const dispatch = useDispatch();
    const [amountSortingOrder, setAmountSortingOrder] = useState(NORMAL);
    const [dateSortingOrder, setDateSortingOrder] = useState(NORMAL);

    const [isFilterPopupOpen, setFilterPopupOpen] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);

    useEffect(() => {
        dispatch(getIncludedCategories());
        const endDate = new Date();
        const startDate = new Date(endDate);
        startDate.setMonth(endDate.getMonth() - 6);
        dispatch(getMasterDataTableData(null, startDate, endDate, NORMAL, NORMAL));
    }, []);

    const { acceptedCategories } = useSelector(state => state.user);
    const { masterDataTableData, isMasterDataTableLoading } = useSelector(state => state.dashboard);

    const handleDelete = id => {
        confirmAlert({
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete this entry?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(deleteTransaction(id));
                    },
                },
                {
                    label: 'No',
                    onClick: () => {},
                },
            ],
        });
    };

    const handleDateSort = () => {
        setDateSortingOrder(prevOrder => {
            const newOrder = prevOrder === ASC ? DESC : prevOrder === DESC ? NORMAL : ASC;
            dispatch(getMasterDataTableData(null, null, null, newOrder, null));
            return newOrder;
        });
    };

    const handleAmountSort = () => {
        setAmountSortingOrder(prevOrder => {
            const newOrder = prevOrder === ASC ? DESC : prevOrder === DESC ? NORMAL : ASC;
            dispatch(getMasterDataTableData(null, null, null, null, newOrder));
            return newOrder;
        });
    };

    const options = acceptedCategories
        ? acceptedCategories.map(category => ({
              label: category.categoryName,
              value: category.id,
          }))
        : [];

    const handleChange = (event, transactionId) => {
        const userCategoryId = event.target.value;
        dispatch(categorizeSingleTransaction(transactionId, userCategoryId));
    };

    const openFilterPopup = () => {
        setFilterPopupOpen(true);
    };

    const closeFilterPopup = () => {
        dispatch(getMasterDataTableData(null, null, null, null, null, selectedCategories));
        setFilterPopupOpen(false);
    };

    return (
        <div className="bg-white py-10 h-screen px-5 w-full">
            <div className="overflow-y-scroll overflow-x-scroll h-full">
                {isMasterDataTableLoading ? (
                    <div className="w-screen border-collapse">
                        <Loader />
                    </div>
                ) : (
                    <div className="w-full">
                        <table className="w-5/6 border-collapse">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th
                                        onClick={handleDateSort}
                                        className="p-2 text-left border-t-2 border-r-2 border-b-2 font-semibold text-lg truncate cursor-pointer"
                                    >
                                        Date {dateSortingOrder === ASC && <i className="ml-1 fas fa-sort-amount-up"></i>}
                                        {dateSortingOrder === DESC && <i className="ml-1 fas fa-sort-amount-down"></i>}
                                        {dateSortingOrder === NORMAL && <i className="ml-1 fas fa-sort"></i>}
                                    </th>
                                    <th className="p-2 text-center text-deepNavyBlue border-t-2 border-b-2 border-l-2 font-semibold text-lg">
                                        Description
                                    </th>
                                    <th
                                        onClick={handleAmountSort}
                                        className="p-2 text-center text-deepNavyBlue border-t-2 border-b-2 border-l-2 font-semibold text-lg truncate cursor-pointer"
                                    >
                                        Amount {amountSortingOrder === ASC && <i className="ml-1 fas fa-sort-amount-up"></i>}
                                        {amountSortingOrder === DESC && <i className="ml-1 fas fa-sort-amount-down"></i>}
                                        {amountSortingOrder === NORMAL && <i className="ml-1 fas fa-sort"></i>}
                                    </th>
                                    <th className="py-2 px-5 text-center text-deepNavyBlue border-t-2 border-b-2 border-l-2 font-semibold text-lg truncate">
                                        Category <i onClick={openFilterPopup} className="ml-1 fas fa-filter"></i>
                                        {isFilterPopupOpen && (
                                            <CategoryFilterPopup
                                                options={options}
                                                selectedCategories={selectedCategories}
                                                setSelectedCategories={setSelectedCategories}
                                                onClose={closeFilterPopup}
                                                isPopupVisible={isFilterPopupOpen}
                                            />
                                        )}
                                    </th>
                                    <th className="p-2 text-center text-deepNavyBlue border-t-2 border-b-2 border-l-2 font-semibold text-lg truncate">
                                        Account Manager
                                    </th>
                                    <th className="p-2 text-center text-deepNavyBlue border-t-2 border-b-2 border-l-2 font-semibold text-lg truncate">
                                        ID
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {isMasterDataTableLoading ? (
                                    <tr>
                                        <td colSpan={7} className="text-center py-4">
                                            <Loader />
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {masterDataTableData && masterDataTableData.length > 0 ? (
                                            masterDataTableData.map(row => (
                                                <tr className="text-sm" key={row.id}>
                                                    <i
                                                        className="fas fa-trash-alt text-red-600 mr-2 mt-2 cursor-pointer"
                                                        onClick={() => handleDelete(row.id)}
                                                    ></i>

                                                    <td className="text-center border-l-0 px-2 w-1/6">{formatDate(row.date)}</td>
                                                    <td className="border-l-2 pl-5 truncate">
                                                        <span
                                                            data-tooltip-id="my-tooltip"
                                                            data-tooltip-content={row.description}
                                                            className={`truncate my-anchor-element`}
                                                        >
                                                            {row.description.length > 60 ? (
                                                                <span className="inline-block max-w-full truncate">
                                                                    {row.description.slice(0, 60)}...
                                                                </span>
                                                            ) : (
                                                                row.description
                                                            )}
                                                        </span>
                                                    </td>

                                                    <td className="text-center border-l-2 px-2 w-1/6">{row.amount}</td>
                                                    <td className="text-center border-l-2 truncate px-2 w-2/6">
                                                        <Dropdown
                                                            options={options}
                                                            onChange={handleChange}
                                                            defaultValue={row.categoryname}
                                                            transactionId={row.id}
                                                        />
                                                    </td>
                                                    <td className="text-center border-l-2 truncate px-2 w-1/6">
                                                        <span
                                                            data-tooltip-id="my-tooltip"
                                                            data-tooltip-content={row.accountmanager}
                                                            className={`truncate my-anchor-element inline-block max-w-full overflow-hidden whitespace-nowrap`}
                                                        >
                                                            {row.accountmanager.length > 20 ? (
                                                                <span className="inline-block max-w-full truncate">
                                                                    {row.accountmanager.slice(0, 20)}...
                                                                </span>
                                                            ) : (
                                                                row.accountmanager
                                                            )}
                                                        </span>
                                                    </td>
                                                    <td className="border-l-2 truncate w-1/6 px-2">{row.id}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={7} className="text-center py-4">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                )}
                            </tbody>
                            <Tooltip id="my-tooltip" />
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MasterDataTableComponent;
