import React from 'react';
import UserIconHeader from './UserIconHeader';

const Welcome = () => {
    return (
        <>
            <UserIconHeader />

            <div
                style={{ backgroundImage: 'url(/images/welcomebackground.png)' }}
                className="bg-blue-700 w-full rounded-xl bg-no-repeat bg-right px-3 py-1 lg:py-2 mt-2 hidden sm:block"
            >
                <div className="mx-auto  ">
                    <h1 className="text-white text-lg md:text-xl lg:text-3xl font-semibold text-left py-2 lg:py-4 ">Welcome to Cupet</h1>
                    <p className="text-white text-base sm:text-md md:text-md text-left py-1 lg:py-2">
                        Here at Cupet, we're dedicated to helping you manage your finances with ease. Our goal is to simplify financial
                        tracking, so you can spend less time on spreadsheets and more time enjoying what you've worked hard for.
                    </p>
                </div>
            </div>
        </>
    );
};

export default Welcome;
