import React, { useEffect } from 'react';
import SummaryGraph from './SummaryGraph';
import LegendSummaryGraph from './LegendSummaryGraph';
import { getBudgetCreatorData } from '../dashboard/dashboard.slice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../utilities/Loader';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../constants/common';

const DetailedSummaryGraphSection = ({ showDetailedTransactions }) => {
    const dispatch = useDispatch();

    const { budgetCreatorData, isBudgetCreaterDataLoading } = useSelector(state => state.dashboard);

    useEffect(() => {
        // since we don't want to include current month in avg
        const endDate = moment().subtract(1, 'month').endOf('month').format(DATE_TIME_FORMAT);
        const startDate = moment(endDate).subtract(6, 'month').startOf('month').format(DATE_TIME_FORMAT);

        dispatch(getBudgetCreatorData(startDate, endDate, null, true));
        // eslint-disable-next-line
    }, []);

    return (
        <div className="bg-white py-10 h-5/6 px-5 w-full grid grid-cols-12 overflow-y-scroll">
            <div className="col-span-12 lg:col-span-10">
                {isBudgetCreaterDataLoading ? (
                    <Loader />
                ) : (
                    <>
                        {budgetCreatorData && (
                            <>
                                {Object.keys(budgetCreatorData).map((categoryType, index) => (
                                    <div key={index}>
                                        <span className="font-bold text-sm">{categoryType}:</span>
                                        {budgetCreatorData[categoryType].map((row, index) => {
                                            const data = [
                                                {
                                                    name: 'Budget',
                                                    amount: Number(row.budget),
                                                    fill: '#335BF1',
                                                    height: 20,
                                                },
                                                {
                                                    name: 'Actual Expenses',
                                                    amount: Number(row.totalMonthlySpend),
                                                    fill: '#8499EB',
                                                    height: 25,
                                                },
                                                {
                                                    name: 'Average Monthly',
                                                    amount: Number(row.avgMonthlySpend),
                                                    fill: '#E2ECFF',
                                                    height: 20,
                                                },
                                            ];

                                            // Check if all values are zero
                                            const allValuesZero = data.every(item => item.amount === 0);

                                            // if (!allValuesZero) {

                                            return (
                                                <SummaryGraph
                                                    key={`${categoryType}-${index}`}
                                                    showDetailedTransactions={showDetailedTransactions}
                                                    data={data}
                                                    category={row.category}
                                                    transactions={row.transactions}
                                                />
                                            );
                                            // }

                                            // return null;
                                        })}
                                    </div>
                                ))}
                            </>
                        )}
                    </>
                )}
            </div>
            <div className="lg:col-span-2 hidden lg:block">
                <LegendSummaryGraph />
            </div>
        </div>
    );
};

export default DetailedSummaryGraphSection;
