import React from 'react';
import Header from './Header';
import LeftNavigation from './LeftNavigation';
import { SETTINGS_BUTTONS, USER_GEAR_ICON } from '../../constants/common';
import SideBarContent from './SideBarContent';
import AccountManagerComponent from '../steps/AccountManagerComponent';
import AccountManagerContainer from '../settings/AccountManagerContainer';

const AccountOwnersSection = () => {
    return (
        <div className="bg-darkWhite">
            <Header />
            <div className="flex">
                <div className="hidden md:block lg:block">
                    <LeftNavigation selectedButton={USER_GEAR_ICON} />
                </div>
                <div className="grid grid-cols-12 w-full">
                    <div className="hidden md:col-span-0 lg:col-span-2 lg:block">
                        <SideBarContent
                            title={'Settings'}
                            desc={'Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.'}
                            logo={'fa-solid fa-user-gear'}
                            buttons={SETTINGS_BUTTONS}
                        />
                    </div>
                    <div className="col-span-12 lg:col-span-10 px-10 py-5">
                        <div className="grid grid-cols-12">
                            <div className="col-span-12 lg:col-span-7 mr-12">
                                <div className="text-lg font-semibold pb-10">Account Managers</div>
                                <div className="text-lg font-semibold text-deepDarkBlue">List of Current Account Managers</div>
                                <AccountManagerComponent />
                            </div>
                            <div className="col-span-12 lg:col-span-5">
                                <AccountManagerContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountOwnersSection;
