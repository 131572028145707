import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { saveManualAccount } from '../user/user.slice';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { ACCOUNT_TYPES } from '../../constants/common';

const ManualAccountModal = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { handleSubmit, register, reset } = useForm();

    const onSubmit = data => {
        data['accountType'] = selectedAccount;
        dispatch(saveManualAccount(data));
        reset();
        setShow(false);
    };

    const handleDropdownChange = selectedOption => {
        setSelectedAccount(selectedOption.value);
    };

    return (
        <div>
            <>
                <button className='flex flex-row justify-center items-center bg-blue-600 rounded-md py-2 w-44 '
                        onClick={handleShow}>
                    <span className='text-base font-semibold text-white'>Add Manual Account</span>
                </button>

                <Modal
                    className='modal-popup'
                    show={show}
                    onHide={handleClose}
                    dialogClassName='modal-dialog modal-lg'
                    aria-labelledby='example-custom-modal-styling-title'
                >
                    <Modal.Header closeButton>
                        <Modal.Title className='p-3'>Add New Bank Account:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-5'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row'>
                                <div className='col-4 mb-2'>Bank Name</div>
                                <div className='col-8 mb-2'>
                                    <input
                                        className='form-control input-lg'
                                        {...register('bankName', {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-4 mb-2'>Account Nickname</div>
                                <div className='col-8 mb-2'>
                                    <input
                                        className='form-control input-lg'
                                        {...register('bankNickName', {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-4 mb-2'>Account Type</div>

                                <div className='col-8 mb-2'>
                                    {/* <input
										className='form-control input-lg'
										{...register('accountType', {
											required: true,
										})}
									/> */}
                                    <Dropdown options={ACCOUNT_TYPES} onChange={handleDropdownChange}
                                              placeholder='Choose Account...' />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-4 mb-2'>Account Last Four Digits</div>
                                <div className='col-8 mb-2'>
                                    <input
                                        className='form-control input-lg'
                                        {...register('lastFourDigits', {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </div>


                            <button type='submit'
                                    className='flex items-center bg-blue-600 rounded-md px-2 py-2 w-30 mt-4'>
                                <span className='text-base font-semibold text-white'>Add Name</span>
                            </button>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
        </div>
    );
};

export default ManualAccountModal;
