import React from 'react';
import CategoryTable from './CategoryTable';
import InformationTooltip from './InformationTooltip';

const BudgetBox = () => {
    return (
        <div className="relative rounded-lg h-64 mr-4 overflow-y-scroll bg-white">
            <div className="absolute top-1 right-2">
                <InformationTooltip desc={'Budget'} />
            </div>

            <div className="flex flex-col items-between px-3 py-4">
                <div className="flex justify-between mb-4 items-center">
                    <span className="text-sm font-semibold">Over Budget</span>
                </div>

                <div className="flex">
                    <CategoryTable />
                </div>
            </div>
        </div>
    );
};

export default BudgetBox;
