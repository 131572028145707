import React from 'react';
import UploadCsvComponent from '../settings/UploadCsvComponent';
import Header from './Header';
import LeftNavigation from './LeftNavigation';
import { TRANSACTIONS_BUTTONS } from '../../constants/common';
import SideBarContent from './SideBarContent';
import UploadedFile from '../settings/UploadedFile';

const UploadSection = () => {
    return (
        <div className="bg-darkWhite">
            <Header />
            <div className="flex">
                <div className="hidden md:block lg:block">
                    <LeftNavigation selectedButton={'sack-dollar'} />
                </div>
                <div className="grid grid-cols-12 w-full">
                    <div className="hidden md:col-span-0 lg:col-span-2 lg:block">
                        <SideBarContent
                            title={'Transactions'}
                            desc={'Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.'}
                            logo={'fas fa-sack-dollar'}
                            buttons={TRANSACTIONS_BUTTONS}
                        />
                    </div>
                    <div className="col-span-12 lg:col-span-10 px-10 py-5">
                        <div className="grid grid-cols-12">
                            <div className="col-span-12 lg:col-span-4">
                                <div className="text-lg font-semibold pb-10">Upload CSV Files</div>
                                <div className="text-lg font-semibold text-deepDarkBlue">List of CSV's</div>
                                <UploadedFile />
                            </div>
                            <div className="col-span-12 lg:col-span-8">
                                <UploadCsvComponent />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadSection;
