import React from 'react';
import { TRANSACTION_CATEGORIZATION } from '../../constants/common';
import LeftBanner from './LeftBanner';
import Welcome from './Welcome';
import TransactionVerificationComponent from './TransactionVerificationComponent';
import UserIconHeader from './UserIconHeader';
import TransactionCategorizationComponent from './TransactionCategorizationComponent';

const TransactionVerification = () => {
    return (
        <div className="grid grid-cols-12 font-manrope h-screen ">
            <div className="col-span-12  md:col-span-5 lg:col-span-4 h-full">
                <LeftBanner
                    step="6"
                    logo="verify"
                    title="Verify Classifications"
                    desc="This step is about identifying everyone who has a card on each account. These arent necessarily the main account holders, but anyone with a card linked to the account. This will help us track individual spending accurately."
                    back={TRANSACTION_CATEGORIZATION}
                />
            </div>
            <div className="col-span-12 md:col-span-7 lg:col-span-8">
                <div className="mt-2 mx-4">
                    <Welcome />
                </div>
                <TransactionVerificationComponent />
            </div>
        </div>
    );
};

export default TransactionVerification;
