import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBankList, getCategories } from '../user/user.slice';
import CategoryBox from './CategoryBox';

const ExcludedIncludedComponent = () => {
    const dispatch = useDispatch();

    const { acceptedCategories, rejectedCategories } = useSelector(state => state.user);

    useEffect(() => {
        dispatch(getBankList());
        dispatch(getCategories());
        // eslint-disable-next-line
    }, []);

    return (
        <div className="grid grid-cols-12 items-start h-full w-full">
            <div className="col-span-12 lg:col-span-5 h-5/6">
                <CategoryBox title={'Excluded'} accepted={false} categories={rejectedCategories} />
            </div>
            <div className="col-span-12 lg:col-span-2 items-center mx-auto my-auto hidden lg:flex">
                <img alt="twoarrows" src="/images/twoarrows.png" />
            </div>
            <div className="col-span-12 lg:col-span-2 items-center my-4 mx-auto justify-center lg:hidden">
                <img alt="twoarrows" src="/images/arrowupdown.svg" />
            </div>
            <div className="col-span-12 lg:col-span-5 h-5/6">
                <CategoryBox title={'Included'} accepted={true} categories={acceptedCategories} />
            </div>
        </div>
    );
};

export default ExcludedIncludedComponent;
