import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../utilities/Loader';
import { SIX_MONTHS } from '../../constants/common';
import { getCategoryInsightGraphData } from './report.slice';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../constants/common';

const DetailedTable = ({ selectedDate }) => {
    const dispatch = useDispatch();
    const [headerDates, setHeaderDates] = useState([]);
    const [categoryTotals, setCategoryTotals] = useState({ Collective: [], His: [], Her: [] });
    const { individualGraphsData, collectionData, isDetailedDataLoading } = useSelector(state => state.report);
    const [localGraphData, setLocalGraphData] = useState(null);

    useEffect(() => {
        const endDate = moment(selectedDate).subtract(1, 'month').format(DATE_TIME_FORMAT);
        const startDate = moment(endDate).subtract(5, 'month').startOf('month').format(DATE_TIME_FORMAT);

        const headerDatesArray = [];

        for (let i = 0; i <= 6; i++) {
            const startDate = moment(endDate)
                .subtract(5 - i, 'months')
                .startOf('month')
                .format('MM/YY');

            headerDatesArray.push(startDate);
        }

        setHeaderDates(headerDatesArray);

        dispatch(getCategoryInsightGraphData(endDate, SIX_MONTHS));
    }, [selectedDate]);

    useEffect(() => {
        console.log(categoryTotals);
    }, [categoryTotals]);

    useEffect(() => {
        setLocalGraphData(individualGraphsData);
    }, [individualGraphsData]);

    return (
        <>
            <div className="overflow-x-auto w-screen p-2">
                <table className="w-full border-collapse">
                    <thead>
                        <tr>
                            <th className="p-2 text-left border-t-2 border-r-2 border-b-2 "></th>
                            {headerDates.map((date, index) => (
                                <th
                                    key={index}
                                    className="p-2 text-center text-deepNavyBlue border-t-2 border-b-2 border-l-2 font-semibold text-xl"
                                >
                                    {date}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {isDetailedDataLoading ? (
                                <div className="w-screen h-screen flex justify-center items-center">
                                    <Loader />
                                </div>
                            ) : (
                                <>
                                    {individualGraphsData && (
                                        <>
                                            {Object.keys(individualGraphsData).map((categoryType, index) => (
                                                <>
                                                    <tr>
                                                        <td className="border-l-0 font-bold"> {categoryType} Accounts</td>
                                                        <td className="text-center border-l-2"></td>
                                                        <td className="text-center border-l-2"></td>
                                                        <td className="text-center border-l-2"></td>
                                                        <td className="text-center border-l-2"></td>
                                                        <td className="text-center border-l-2"></td>
                                                        <td className="text-center border-l-2"></td>
                                                        <td className="text-center border-l-2"></td>
                                                    </tr>
                                                    {individualGraphsData[categoryType].map((subCategory, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="pl-10 border-l-0">{subCategory.category}</td>
                                                                {subCategory.graphData.length > 0 ? (
                                                                    subCategory.graphData.map((data, dataIndex) => {
                                                                        const { totalAmount } = data;
                                                                        return (
                                                                            <td key={dataIndex} className="text-center border-l-2">
                                                                                {totalAmount > 0 ? totalAmount.toFixed(2) : 0}
                                                                            </td>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <>
                                                                        <td className="text-center border-l-2">0</td>
                                                                        <td className="text-center border-l-2">0</td>
                                                                        <td className="text-center border-l-2">0</td>
                                                                        <td className="text-center border-l-2">0</td>
                                                                        <td className="text-center border-l-2">0</td>
                                                                        <td className="text-center border-l-2">0</td>
                                                                    </>
                                                                )}
                                                            </tr>
                                                        );
                                                    })}
                                                </>
                                            ))}
                                            <div className="mt-10"></div>
                                            {Object.keys(collectionData).map((categoryType, idx) => {
                                                if (categoryType !== 'Total') {
                                                    return (
                                                        <tr key={idx}>
                                                            <td className=" border-l-0 font-semibold">Total {categoryType}</td>
                                                            {collectionData[categoryType].map((data, index) => (
                                                                <td key={index} className="text-center">
                                                                    {data.totalAmount.toFixed(2)}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}

                                            <div className="mt-10"></div>
                                            {Object.keys(collectionData).map((categoryType, idx) => {
                                                if (categoryType === 'Total') {
                                                    return (
                                                        <tr key={idx} className="bg-blue-100 text-blue-700 font-semibold h-16">
                                                            <td className="font-bold border-l-0 font-semibold pl-10 text-xl">
                                                                {categoryType}
                                                            </td>
                                                            {collectionData[categoryType].map((data, index) => (
                                                                <td key={index} className="text-center">
                                                                    {data.totalAmount.toFixed(2)}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default DetailedTable;
