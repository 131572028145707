import React, { useState } from 'react';

const GraphDropdown = ({ options, onChange, defaultValue }) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue);

    const handleOnChange = event => {
        setSelectedValue(event.target.value);
        onChange(event);
    };

    return (
        <select
            className="bg-transparent border-0 outline-none text-sm font-normal cursor-pointer py-1 px-2 border-b-2 border-black w-1/2"
            onChange={handleOnChange}
            value={selectedValue}
        >
            <option value="" disabled hidden>
                <span>Category</span>
            </option>

            {options && options.length > 0 && (
                <>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </>
            )}
        </select>
    );
};

export default GraphDropdown;
