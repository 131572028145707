import { createSlice } from '@reduxjs/toolkit';
import network from '../../services/network';
import { AUTOCATEGORIZE_TRANSACTION, OPENAI_CATEGORY_PREDICTION } from '../../constants/network-calls';
import { showToast } from '../toast-handler/toast-handler';
import Cookies from 'js-cookie';
import { FAILURE, SUCCESS } from '../../constants/common';

const utilitesSlice = createSlice({
    name: 'utilites',
    initialState: {
        category: null,
        isCategoriesLoading: false,
        gptCategories: [],
        ourCategories: [],
        withoutGPTCategory: null,
        transactions: [],
        transactionsMatches: [],
        descFromGoogle: null,
    },
    reducers: {
        setCategory(state, action) {
            state.category = action.payload;
        },
        setGptCategories(state, action) {
            state.gptCategories = action.payload;
        },
        setOurCategories(state, action) {
            state.ourCategories = action.payload;
        },
        setWithoutGPTCategory(state, action) {
            state.withoutGPTCategory = action.payload;
        },
        setIsCategoriesLoading(state, action) {
            state.isCategoriesLoading = action.payload;
        },
        setTransactions(state, action) {
            state.transactions = action.payload;
        },
        setTransactionsMatches(state, action) {
            state.transactionsMatches = action.payload;
        },
        setDescFromGoogle(state, action) {
            state.descFromGoogle = action.payload;
        },
    },
});

// --------------------------------------------THUNK FUNCTIONS----------------------------------------

export const getPredictedCategory = transaction => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    const headers = {
        Authorization: `${userId}`,
    };

    dispatch(setIsCategoriesLoading(true));

    network
        .fetch('POST', AUTOCATEGORIZE_TRANSACTION, { userId, transaction }, null, headers)
        .then(res => {
            const { data } = res;
            const { gptCategories, ourCategories, withoutGPTCategory, transactions, detailedTransactionsList, descFromGoogle } = data;
            dispatch(setGptCategories(gptCategories));
            dispatch(setOurCategories(ourCategories));
            dispatch(setWithoutGPTCategory(withoutGPTCategory));
            dispatch(setTransactions(transactions));
            dispatch(setTransactionsMatches(detailedTransactionsList));
            dispatch(setDescFromGoogle(descFromGoogle));
        })
        .catch(err => {
            showToast(err, FAILURE);
        })
        .finally(() => {
            dispatch(setIsCategoriesLoading(false));
        });
};

export const {
    setCategory,
    setIsCategoriesLoading,
    setGptCategories,
    setOurCategories,
    setWithoutGPTCategory,
    setTransactions,
    setTransactionsMatches,
    setDescFromGoogle,
} = utilitesSlice.actions;

export default utilitesSlice.reducer;
