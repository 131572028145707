import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVerificationTransactions, unCategorise, verifyTransactionCategory } from '../transaction/transaction.slice';
import Loader from '../utilities/Loader';
import { useNavigate } from 'react-router';
import { VERIFY_EXPENSE_ACCOUNT } from '../../constants/common';

const TransactionVerificationComponent = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { verifyTransactions, currentCategory, isVerifiedTransactionLoading } = useSelector(state => state.transaction);

    useEffect(() => {
        dispatch(getVerificationTransactions());
        // eslint-disable-next-line
    }, []);

    const handleVerification = () => {
        dispatch(verifyTransactionCategory(currentCategory.id));
    };

    const handleDelete = transactionId => {
        dispatch(unCategorise(transactionId));
    };

    const formatDate = dateString => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        });
        return formattedDate;
    };

    const renderVerifiedTransactions = () => {
        if (isVerifiedTransactionLoading) {
            return (
                <>
                    <div className="flex justify-center items-center w-full">
                        <Loader />
                    </div>
                </>
            );
        } else {
            if (verifyTransactions && verifyTransactions.length > 0) {
                return (
                    <div className="h-screen ">
                        <>
                            <div className="bg-white flex flex-col rounded-xl lg:px-5 py-2 w-full h-2/3 ">
                                <span className="text-lg font-semibold mt-2 text-fontDarkBlue">
                                    {currentCategory && currentCategory.categoryName}
                                </span>
                                <div className="flex overflow-y-auto bg-darkWhite h-screen px-2 mt-1">
                                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-2">
                                        {verifyTransactions.map(transaction => {
                                            const { date, amount, description } = transaction;
                                            return (
                                                <div className="flex flex-row">
                                                    <span
                                                        className="me-2 text-xs"
                                                        key={transaction.id}
                                                        onClick={() => handleDelete(transaction.id)}
                                                    >
                                                        <i className="material-icons text-red-800 cursor-pointer">close</i>
                                                    </span>
                                                    {formatDate(date)} - {description.slice(0, 20)} - ${amount}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <span className="font-semibold">{verifyTransactions.length} Transactions</span>
                                <div className="flex flex-col h-full items-end justify-end">
                                    <div>
                                        <button
                                            onClick={() => handleVerification()}
                                            className="flex  bg-blue-600 rounded-md px-4 py-2 ml-4 mt-2 "
                                        >
                                            <span className="text-base font-semibold text-white ">Verify Classifications</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                );
            } else if (!isVerifiedTransactionLoading) {
                return (
                    <div className="flex h-screen">
                        <div className="m-auto flex flex-col items-center justify-center">
                            <h1 className="text-center">All classifications Verified!</h1>
                            {!props.hideButton && (
                                <div className="mt-8">
                                    <button onClick={() => navigate(VERIFY_EXPENSE_ACCOUNT)} className="bg-blue-600 rounded-md px-4 py-2">
                                        <span className="text-base font-semibold text-white">Done!</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                );
            }
        }
    };

    return <>{renderVerifiedTransactions()}</>;
};

export default TransactionVerificationComponent;
