import axios from 'axios';
import { BASE_URL } from '../constants/config';

axios.defaults.withCredentials = true;
export const axiosClient = axios.create({
    baseURL: BASE_URL,
});

export default {
    fetch: (method, url, data = null, params = null, headers = null) => {
        return new Promise(function (resolve, reject) {
            const args = {
                url,
                method,
                data,
                params,
            };

            if (headers) {
                args['headers'] = headers;
            }

            if ((method === 'POST' || method === 'PUT') && data) {
                axiosClient
                    .request(args)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        console.error(error);
                        reject(error.response.data.message);
                    });
            } else {
                const args = {
                    url,
                    method,
                    params,
                };
                if (headers) {
                    // @ts-ignore
                    args['headers'] = headers;
                }
                axiosClient
                    .request({
                        url,
                        method,
                        params,
                        headers,
                    })
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        console.error(error);
                        reject(error.response.data.message);
                    });
            }
        });
    },
};
