import React from 'react';

const FirstTransation = ({ firstTransaction }) => {
    const formatDate = dateString => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        });
        return formattedDate;
    };

    return (
        <div className="mt-2 text-lightPurple ">
            <div className="flex justify-between ">
                <span className="text-xl font-semibold text-fontDarkBlue">Let's start classifying them!</span>
                <span className="text-xl font-semibold text-fontDarkBlue">
                    {firstTransaction.account.bankNickName}{' '}
                    <span className="font-light text-fontDarkBlue">
                        ({firstTransaction.account.bankName} - xxxx
                        {firstTransaction.account.lastFourDigits})
                    </span>
                </span>
            </div>

            <hr className="border-gray-400" />

            <div className="flex justify-between pb-2">
                <span className="text-lg font-semibold mx-auto text-fontDarkBlue">
                    {formatDate(firstTransaction.date)} - {firstTransaction.description.slice(0, 50)}
                </span>
                <span className="text-lg font-semibold text-fontDarkBlue">{firstTransaction.amount}</span>
            </div>
        </div>
    );
};

export default FirstTransation;
