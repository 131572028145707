import React, { useEffect } from 'react';
import { ACCOUNT_MANAGEMENT, TRANSACTION_CATEGORIZATION } from '../../constants/common';
import LeftBanner from './LeftBanner';
import Welcome from './Welcome';
import { useDispatch } from 'react-redux';
import { getBankList, getCategories } from '../user/user.slice';
import { useNavigate } from 'react-router-dom';
import ExcludedIncludedComponent from './ExcludedIncludedComponent';

const SelectCategories = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getBankList());
        dispatch(getCategories());
        // eslint-disable-next-line
    }, []);

    return (
        <div className="grid grid-cols-12 font-manrope min-h-screen">
            <div className=" col-span-12  md:col-span-5 lg:col-span-4 h-full">
                <LeftBanner
                    step="4"
                    logo="categories"
                    title="Select Categories"
                    desc="This step is about identifying everyone who has a card on each account. These arent necessarily the main account holders, but anyone with a card linked to the account. This will help us track individual spending accurately."
                    back={ACCOUNT_MANAGEMENT}
                />
            </div>

            <div className="flex flex-col col-span-12 md:col-span-7 lg:col-span-8 mx-20 bg-white">
                <Welcome />
                <div className="mt-4 h-full">
                    <ExcludedIncludedComponent />
                </div>

                <div className="flex flex-col items-center justify-center md:flex-row md:justify-end py-3 mt-auto">
                    <div>
                        <button
                            onClick={e => {
                                e.preventDefault();
                                navigate(TRANSACTION_CATEGORIZATION);
                            }}
                            className="flex items-center bg-blue-600 rounded-md px-4 py-2 mt-1 sm:mt-0"
                        >
                            <span className="text-base font-semibold text-white">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectCategories;
