import { createBrowserRouter } from 'react-router-dom';
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import ErrorPage from './error/ErrorPage';
import Root from './root/Root';
import LoginPage from './login/LoginPage';
import ConnectPlaid from './steps/ConnectPlaid';
import AccountVerification from './steps/AccountVerification';
import AccountManager from './steps/AccountManager';
import NicknameAccount from './steps/NicknameAccount';
import SelectCategories from './steps/SelectCategories';
import TransactionCategorization from './steps/TransactionCategorization';
import TransactionVerification from './steps/TransactionVerification';
import SignUpPage from './login/SignUpPage';
import Dashboard from '../components/dashboard/Dashboard';
import CategorizeSection from './dashboard/CategorizeSection';
import VerifySection from './dashboard/VerifySection';
import UploadSection from './dashboard/UploadSection';
import SummarySection from './dashboard/SummarySection';
import CategoryInsightSection from './dashboard/CategoryInsightSection';
import DetailedTableSection from './dashboard/DetailedTableSection';
import BankInfoSection from './dashboard/BankInfoSection';
import AccountOwnersSection from './dashboard/AccountOwnersSection';
import AddRemoveSection from './dashboard/AddRemoveSection';
import EditCreateSection from './dashboard/EditCreateSection';
import MasterDataTable from './dashboard/MasterDataTable';
import OpenAITestingPage from './utilities/OpenAITestingPage';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <ConnectPlaid />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/sign-up',
                element: (
                    <>
                        <SignedOut>
                            <SignUpPage />
                        </SignedOut>
                        <SignedIn>
                            <ConnectPlaid />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/connect-plaid',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <ConnectPlaid />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/account-verification',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <AccountVerification />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/nickname-accounts',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <NicknameAccount />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/account-manager',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <AccountManager />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/select-categories',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <SelectCategories />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/transaction-categorization',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <TransactionCategorization />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/transaction-verification',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <TransactionVerification />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/dashboard',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <Dashboard />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/dashboard/transactions/categorize',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <CategorizeSection />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/dashboard/transactions/verify',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <VerifySection />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/dashboard/transactions/upload-csv',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <UploadSection />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/dashboard/reporting/detailed-summary',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <SummarySection />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/dashboard/reporting/category-insights',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <CategoryInsightSection />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/dashboard/reporting/detailed-table',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <DetailedTableSection />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/dashboard/settings/bank-info',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <BankInfoSection />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/dashboard/settings/account-owners',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <AccountOwnersSection />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/dashboard/settings/add-remove-categories',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <AddRemoveSection />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/dashboard/settings/budget-creator',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <EditCreateSection />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/dashboard/master-data-table',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <MasterDataTable />
                        </SignedIn>
                    </>
                ),
            },
            {
                path: '/dashboard/openai',
                element: (
                    <>
                        <SignedOut>
                            <LoginPage />
                        </SignedOut>
                        <SignedIn>
                            <OpenAITestingPage />
                        </SignedIn>
                    </>
                ),
            },
        ],
    },
]);
