import React from 'react';

const UploadedFile = () => {
    return (
        <div className="bg-lightBlue flex w-1/2 lg:w-full px-4 py-3 text-md rounded-lg justify-between items-center my-3">
            <div>Upload File 1</div>

            <img src="/images/purplearrow.png" alt="Arrow" className="h-4" />
        </div>
    );
};

export default UploadedFile;
