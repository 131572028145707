import React from 'react';
import { SELECT_CATEGORIES } from '../../constants/common';
import LeftBanner from './LeftBanner';
import 'react-tooltip/dist/react-tooltip.css';
import TransactionCategorizationComponent from './TransactionCategorizationComponent';
import UserIconHeader from './UserIconHeader';

const TransactionCategorization = () => {
    return (
        <div className="grid grid-cols-12 font-manrope h-screen w-full  overflow-x-hidden">
            <div className="col-span-12  md:col-span-5 lg:col-span-4 h-full">
                <LeftBanner
                    step="5"
                    logo="categorize"
                    title="Categorise your transactions"
                    desc="This step is about identifying everyone who has a card on each account. These arent necessarily the main account holders, but anyone with a card linked to the account. This will help us track individual spending accurately."
                    back={SELECT_CATEGORIES}
                />
            </div>

            <div className="col-span-12  md:col-span-7 lg:col-span-8">
                <div className="mt-2 mr-4">
                    <UserIconHeader />
                </div>
                <TransactionCategorizationComponent />
            </div>
        </div>
    );
};

export default TransactionCategorization;
