import React from 'react';

const LegendWithColour = ({ title, color }) => {
    return (
        <div className="flex flex-col mb-2">
            <span className="mb-1 font-light">{title}</span>
            <div className={`w-full h-5 mr-2  shadow-md ${color}`}></div>
        </div>
    );
};

export default LegendWithColour;
