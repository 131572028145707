import { createSlice } from '@reduxjs/toolkit';
import network from '../../services/network';
import { TOTAL_TRANSACTIONS_BY_ACCOUNT, PLAID_STATUS_OF_ITEM } from '../../constants/network-calls';
import { showToast } from '../toast-handler/toast-handler';
import Cookies from 'js-cookie';
import { FAILURE, PLAID_NOT_CONNECTED } from '../../constants/common';

const bankSlice = createSlice({
    name: 'bank',
    initialState: {
        accountsWithCount: null,
        isAccountsWithCountLoading: false,
        bankAccounts: {},
        isStatusLoading: false,
    },
    reducers: {
        setAccountsWithCount(state, action) {
            state.accountsWithCount = action.payload;
        },
        setIsAccountsWithCountLoading(state, action) {
            state.isAccountsWithCountLoading = action.payload;
        },
        setIsStatusLoading(state, action) {
            state.isStatusLoading = action.payload;
        },
        updateAccountStatus(state, action) {
            const { accountId, status } = action.payload;
            if (state.bankAccounts[accountId]) {
                state.bankAccounts[accountId].status = status;
            }
        },
    },
});

// --------------------------------------------THUNK FUNCTIONS----------------------------------------

export const getTotalTransactionsByAccount = () => async (dispatch, getState) => {
    const id = Cookies.get('userId');

    dispatch(setIsAccountsWithCountLoading(true));
    dispatch(setIsStatusLoading(true));

    try {
        const res = await network.fetch('POST', TOTAL_TRANSACTIONS_BY_ACCOUNT, { id }, null);
        const { data } = res;
        dispatch(setAccountsWithCount(data));

        dispatch(setIsAccountsWithCountLoading(false));

        const statusPromises = [];
        for (const account of data) {
            if (account.plaidaccountid) {
                const { plaidaccountid, accesstoken } = account;
                statusPromises.push(
                    (async () => {
                        try {
                            const status = dispatch(getPlaidStatusOfItem(plaidaccountid, accesstoken));
                            return status;
                        } catch (error) {
                            // Handle the error here, and return an appropriate status
                            return PLAID_NOT_CONNECTED;
                        }
                    })(),
                );
            } else {
                statusPromises.push(null);
            }
        }

        const statuses = await Promise.all(statusPromises);

        // Update accountsWithCount directly
        const updatedAccountsWithCount = data.map((account, index) => ({
            ...account,
            status: statuses[index],
        }));

        dispatch(setAccountsWithCount(updatedAccountsWithCount));
    } catch (err) {
        showToast(err, FAILURE);
    } finally {
        dispatch(setIsAccountsWithCountLoading(false));
        dispatch(setIsStatusLoading(false));
    }
};

export const getPlaidStatusOfItem = (plaidAccountId, accessToken) => async (dispatch, getState) => {
    try {
        const res = await network.fetch('POST', PLAID_STATUS_OF_ITEM, { plaidAccountId, accessToken }, null);
        const { data } = res;
        return data;
    } catch (err) {
        showToast(err, FAILURE);
        throw err;
    }
};

export const { setAccountsWithCount, setIsAccountsWithCountLoading, updateAccountStatus, setIsStatusLoading } = bankSlice.actions;

export default bankSlice.reducer;
