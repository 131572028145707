import React from 'react';
import Header from './Header';
import LeftNavigation from './LeftNavigation';
import SideBarContent from './SideBarContent';
import MasterDataTableComponent from './MasterDataTableComponent';
import Searchbar from '../filter/Searchbar';
import DateRangeComponent from '../filter/DateRangeComponent';

const MasterDataTable = () => {
    return (
        <div className="bg-darkWhite">
            <Header />
            <div className="flex">
                <div className="hidden md:block lg:block">
                    <LeftNavigation selectedButton={'table'} />
                </div>
                <div className="grid grid-cols-12">
                    <div className="hidden md:col-span-0 lg:col-span-2 lg:block">
                        <SideBarContent
                            title={'Master Data Table'}
                            desc={'  Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.'}
                            logo={'fa-solid fa-table'}
                            buttons={[]}
                        />
                    </div>
                    <div className="col-span-12 lg:col-span-10 font-manrope px-10 py-5">
                        <div className="flex flex-col md:flex-row w-full justify-between">
                            <span className="text-lg font-semibold pb-10">Master Data Table</span>
                            <DateRangeComponent />
                        </div>
                        <Searchbar />
                        <MasterDataTableComponent />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MasterDataTable;
