import React, { useEffect } from 'react';
import TransactionRow from './TransactionRow';
import { useDispatch, useSelector } from 'react-redux';
import { getIncludedCategories } from '../user/user.slice';
import { getAllUncategorisedTransactionList } from '../transaction/transaction.slice';
import Loader from '../utilities/Loader';

const RecentTransactions = () => {
    const dispatch = useDispatch();

    const { transactions, isUncategorisedTransactionsLoading } = useSelector(state => state.transaction);
    const { acceptedCategories } = useSelector(state => state.user);

    const formatDate = inputDate => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        const date = new Date(inputDate);
        const day = date.getDate();
        const month = months[date.getMonth()];

        return `${month} ${day}`;
    };

    useEffect(() => {
        dispatch(getAllUncategorisedTransactionList());
        dispatch(getIncludedCategories());
        // eslint-disable-next-line
    }, []);
    return (
        <div className="h-5/6 rounded-lg bg-white mt-16 mr-3">
            <div className="flex flex-col h-full overflow-y-auto p-4">
                <div className="flex mb-3 justify-between">
                    <span className="text-lg font-semibold">Uncategorized Transactions</span>
                    <a className="text-decoration-none font-light" href="/dashboard/transactions/categorize">
                        View All
                    </a>
                </div>

                {isUncategorisedTransactionsLoading ? (
                    <div className="flex justify-center items-center h-full">
                        <Loader />
                    </div>
                ) : (
                    <>
                        {transactions && transactions.length > 0 ? (
                            <>
                                {transactions.map(transaction => {
                                    const { amount, description, date } = transaction;
                                    return (
                                        <TransactionRow
                                            key={transaction.id}
                                            amount={amount}
                                            desc={description}
                                            date={formatDate(date)}
                                            acceptedCategories={acceptedCategories}
                                            transactionId={transaction.id}
                                        />
                                    );
                                })}
                            </>
                        ) : (
                            <>No transactions left to categorize</>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default RecentTransactions;
