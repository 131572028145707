import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';

const InformationTooltip = ({ desc }) => {
    return (
        <>
            <Tooltip id={desc} />
            <AiOutlineInfoCircle data-tooltip-content={desc} data-tooltip-id={desc} size={20} className="text-orange-300 cursor-pointer" />
        </>
    );
};

export default InformationTooltip;
