import React, { useEffect, useState } from 'react';
import Graph from '../dashboard/Graph';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryInsightGraphData } from './report.slice';

const AllCategoryGraphs = ({ selectedNumberOfMonths, selectedMonthDate, showBudgetLine, showAverageMonthly }) => {
    const dispatch = useDispatch();
    const [selectedGraph, setSelectedGraph] = useState(null);

    const { individualGraphsData } = useSelector(state => state.report);

    const handleGraphClick = category => {
        setSelectedGraph(category);
    };

    useEffect(() => {
        switch (selectedNumberOfMonths) {
            case 3:
                dispatch(getCategoryInsightGraphData(selectedMonthDate, selectedNumberOfMonths - 1));
                break;
            case 6:
                dispatch(getCategoryInsightGraphData(selectedMonthDate, selectedNumberOfMonths - 1));
                break;
            case 12:
                dispatch(getCategoryInsightGraphData(selectedMonthDate, selectedNumberOfMonths - 1));
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [selectedMonthDate, selectedNumberOfMonths]);

    return (
        <div className="h-4/6 overflow-y-scroll">
            {individualGraphsData && (
                <>
                    {selectedGraph ? (
                        <div>
                            {Object.keys(individualGraphsData).map(categoryType => {
                                if (categoryType === selectedGraph.categoryType) {
                                    return (
                                        <div key={categoryType}>
                                            <div>
                                                {individualGraphsData[categoryType].map(subCategory => {
                                                    if (subCategory.category === selectedGraph.categoryName) {
                                                        const data = subCategory.graphData;
                                                        const budget = subCategory.budget;
                                                        const avgMonthlySpend = subCategory.avgMonthlySpend;
                                                        const movingAverageMonthly = subCategory.movingAverageMonthly;

                                                        const formattedData = data.map((item, index) => {
                                                            const date = new Date(item.year, item.month - 1);
                                                            const monthName = date.toLocaleString('en-US', { month: 'short' });

                                                            return {
                                                                name: `${monthName}, ${item.year}`,
                                                                amount: parseFloat(item.totalAmount),
                                                                movingAverageMonthly: movingAverageMonthly[index]
                                                                    ? movingAverageMonthly[index].movingAverage
                                                                    : 0,
                                                            };
                                                        });

                                                        return (
                                                            <div key={subCategory.category}>
                                                                <div className="font-semibold mb-1">
                                                                    {selectedGraph.categoryType} : {subCategory.category}
                                                                </div>
                                                                <div className="bg-white px-2 py-5 rounded-lg">
                                                                    <Graph
                                                                        data={formattedData}
                                                                        height={350}
                                                                        budgetLine={showBudgetLine}
                                                                        averageLine={showAverageMonthly}
                                                                        budget={budget}
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })}

                            <button
                                className="flex items-center bg-blue-600 rounded-md px-4 py-2 mt-4"
                                onClick={() => setSelectedGraph(null)}
                            >
                                <span className="text-base font-semibold text-white ">Back</span>
                            </button>
                        </div>
                    ) : (
                        <>
                            {Object.keys(individualGraphsData).map(categoryType => (
                                <div key={categoryType}>
                                    <div className="font-semibold mb-2 text-2xl mt-10 ">{categoryType}</div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        {individualGraphsData[categoryType].map(subCategory => {
                                            const data = subCategory.graphData;
                                            const budget = subCategory.budget;
                                            const avgMonthlySpend = subCategory.avgMonthlySpend;

                                            const movingAverageMonthly = subCategory.movingAverageMonthly;

                                            const formattedData = data.map((item, index) => {
                                                const date = new Date(item.year, item.month - 1);
                                                const monthName = date.toLocaleString('en-US', { month: 'short' });

                                                return {
                                                    name: `${monthName}, ${item.year}`,
                                                    amount: parseFloat(item.totalAmount),
                                                    movingAverageMonthly: movingAverageMonthly[index]
                                                        ? movingAverageMonthly[index].movingAverage
                                                        : 0,
                                                };
                                            });

                                            return (
                                                <div
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        handleGraphClick({
                                                            categoryType: categoryType,
                                                            categoryName: subCategory.category,
                                                        })
                                                    }
                                                    key={subCategory.category}
                                                >
                                                    <div className="font-semibold mb-1">{subCategory.category}</div>
                                                    <div className="bg-white px-2 py-5 rounded-lg">
                                                        <Graph
                                                            data={formattedData}
                                                            height={250}
                                                            budgetLine={showBudgetLine}
                                                            averageLine={showAverageMonthly}
                                                            budget={budget}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default AllCategoryGraphs;
