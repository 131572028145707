import { createSlice } from '@reduxjs/toolkit';
import network from '../../services/network';
import {
    CURRENT_MONTHLY_SPEND,
    LAST_MONTHLY_SPEND,
    AVERAGE_MONTHLY_SPEND,
    HIGHEST_MONTH_SPEND,
    LOWEST_MONTH_SPEND,
    YEARLY_TRANSACTIONS_BY_CATEGORY,
    BUDGET_CREATOR_DATA,
    APPLY_FILTERS,
    DELETE_TRANSACTION,
} from '../../constants/network-calls';
import { showToast } from '../toast-handler/toast-handler';
import Cookies from 'js-cookie';
import { FAILURE, SUCCESS } from '../../constants/common';

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        currentMonthlySpend: null,
        lastMonthlySpend: null,
        averageMonthlySpend: null,
        highestMonthSpend: null,
        lowestMonthSpend: null,
        annualTransactionsGraphData: [],
        selectedCategory: null,
        budgetCreatorData: null,
        isBudgetCreaterDataLoading: false,
        masterDataTableData: null,
        isMasterDataTableLoading: false,
        searchword: null,
        startDateFilter: null,
        endDateFilter: null,
        dateSort: null,
        amountSort: null,
        categoryFilter: [],
    },
    reducers: {
        setCurrentMonthlySpend(state, action) {
            state.currentMonthlySpend = action.payload;
        },
        setLastMonthlySpend(state, action) {
            state.lastMonthlySpend = action.payload;
        },
        setAverageMonthlySpend(state, action) {
            state.averageMonthlySpend = action.payload;
        },
        setHighestMonthSpend(state, action) {
            state.highestMonthSpend = action.payload;
        },
        setLowestMonthSpend(state, action) {
            state.lowestMonthSpend = action.payload;
        },
        setAnnualTransactionsGraphData(state, action) {
            state.annualTransactionsGraphData = action.payload;
        },
        setSelectedCategory(state, action) {
            state.selectedCategory = action.payload;
        },
        setBudgetCreatorData(state, action) {
            state.budgetCreatorData = action.payload;
        },
        setIsBudgetCreaterDataLoading(state, action) {
            state.isBudgetCreaterDataLoading = action.payload;
        },
        setMasterDataTableData(state, action) {
            state.masterDataTableData = action.payload;
        },
        setIsMasterDataTableLoading(state, action) {
            state.isMasterDataTableLoading = action.payload;
        },
        setSearchword(state, action) {
            state.searchword = action.payload;
        },
        setStartDateFilter(state, action) {
            state.startDateFilter = action.payload;
        },
        setEndDateFilter(state, action) {
            state.endDateFilter = action.payload;
        },
        removeTransaction: (state, action) => {
            const transactionIdToRemove = action.payload;
            state.masterDataTableData = state.masterDataTableData.filter(transaction => transaction.id !== transactionIdToRemove);
        },
        setDateSort(state, action) {
            state.dateSort = action.payload;
        },
        setAmountSort(state, action) {
            state.amountSort = action.payload;
        },
        setCategoryFilter(state, action) {
            state.categoryFilter = action.payload;
        },
    },
});

// --------------------------------------------THUNK FUNCTIONS----------------------------------------

export const getCurrentMonthlySpend = () => (dispatch, getState) => {
    const id = Cookies.get('userId');

    const headers = {
        Authorization: `${id}`,
    };

    network
        .fetch('POST', CURRENT_MONTHLY_SPEND, { id }, null, headers)
        .then(res => {
            const { data } = res;
            dispatch(setCurrentMonthlySpend(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getLastMonthlySpend = () => (dispatch, getState) => {
    const id = Cookies.get('userId');

    const headers = {
        Authorization: `${id}`,
    };

    network
        .fetch('POST', LAST_MONTHLY_SPEND, { id }, null, headers)
        .then(res => {
            const { data } = res;
            dispatch(setLastMonthlySpend(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getAverageMonthlySpend = () => (dispatch, getState) => {
    const id = Cookies.get('userId');

    network
        .fetch('POST', AVERAGE_MONTHLY_SPEND, { id }, null)
        .then(res => {
            const { data } = res;
            dispatch(setAverageMonthlySpend(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getHighestMonthSpend = () => (dispatch, getState) => {
    const id = Cookies.get('userId');

    const headers = {
        Authorization: `${id}`,
    };

    network
        .fetch('POST', HIGHEST_MONTH_SPEND, { id }, null, headers)
        .then(res => {
            const { data } = res;
            dispatch(setHighestMonthSpend(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getLowestMonthSpend = () => (dispatch, getState) => {
    const id = Cookies.get('userId');

    const headers = {
        Authorization: `${id}`,
    };

    network
        .fetch('POST', LOWEST_MONTH_SPEND, { id }, null, headers)
        .then(res => {
            const { data } = res;
            dispatch(setLowestMonthSpend(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getYearlyTransactionsByCategory = (userCategoryId, startDate, endDate) => (dispatch, getState) => {
    const id = Cookies.get('userId');
    const headers = {
        Authorization: `${id}`,
    };

    network
        .fetch('POST', YEARLY_TRANSACTIONS_BY_CATEGORY, { id: id, userCategoryId, startDate, endDate }, null, headers)
        .then(res => {
            const { data } = res;
            dispatch(setAnnualTransactionsGraphData(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getBudgetCreatorData = (startDate, endDate, budgetCreator, detailedSummary) => (dispatch, getState) => {
    const id = Cookies.get('userId');
    const headers = {
        Authorization: `${id}`,
    };
    dispatch(setIsBudgetCreaterDataLoading(true));
    network
        .fetch('POST', BUDGET_CREATOR_DATA, { id, startDate, endDate, budgetCreator, detailedSummary }, null, headers)
        .then(res => {
            const { data } = res;
            dispatch(setBudgetCreatorData(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        })
        .finally(() => {
            dispatch(setIsBudgetCreaterDataLoading(false));
        });
};

export const getMasterDataTableData = (keyword, startDate, endDate, dateSort, amountSort, categoryFilter) => (dispatch, getState) => {
    const userId = Cookies.get('userId');
    const headers = {
        Authorization: `${userId}`,
    };

    if (keyword) {
        dispatch(setSearchword(keyword));
    } else if (keyword === '') {
        dispatch(setSearchword(null));
    }

    if (startDate) {
        dispatch(setStartDateFilter(startDate));
    }
    if (endDate) {
        dispatch(setEndDateFilter(endDate));
    }
    if (dateSort) {
        dispatch(setDateSort(dateSort));
    }

    if (amountSort) {
        dispatch(setAmountSort(amountSort));
    }

    if (categoryFilter) {
        dispatch(setCategoryFilter(categoryFilter));
    }

    dispatch(setIsMasterDataTableLoading(true));
    const searchword = getState().dashboard.searchword;
    const startDateFilter = getState().dashboard.startDateFilter;
    const endDateFilter = getState().dashboard.endDateFilter;
    const dateSortFilter = getState().dashboard.dateSort;
    const amountSortFilter = getState().dashboard.amountSort;
    const categoryFilterArray = getState().dashboard.categoryFilter;

    network
        .fetch(
            'POST',
            APPLY_FILTERS,
            { userId, searchword, startDateFilter, endDateFilter, dateSortFilter, amountSortFilter, categoryFilterArray },
            null,
            headers,
        )
        .then(res => {
            const { data } = res;
            dispatch(setMasterDataTableData(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        })
        .finally(() => {
            dispatch(setIsMasterDataTableLoading(false));
        });
};

export const deleteTransaction = transactionId => (dispatch, getState) => {
    const userId = Cookies.get('userId');
    const headers = {
        Authorization: `${userId}`,
    };
    dispatch(removeTransaction(transactionId));
    network
        .fetch('POST', DELETE_TRANSACTION, { transactionId }, null, headers)
        .then(res => {
            showToast('Transaction Successfully Deleted!', SUCCESS);
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const {
    setCurrentMonthlySpend,
    setLastMonthlySpend,
    setAverageMonthlySpend,
    setHighestMonthSpend,
    setLowestMonthSpend,
    setAnnualTransactionsGraphData,
    setSelectedCategory,
    setBudgetCreatorData,
    setIsBudgetCreaterDataLoading,
    setMasterDataTableData,
    setIsMasterDataTableLoading,
    setSearchword,
    setStartDateFilter,
    setEndDateFilter,
    removeTransaction,
    setDateSort,
    setAmountSort,
    setCategoryFilter,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
