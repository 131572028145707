import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBudgetCreatorData } from '../dashboard/dashboard.slice';
import Cleave from 'cleave.js/react';
import { getMonthlyIncome, getMonthlySavings, setIncomeValue, setSavingsValue } from './budget.slice';
import BudgetCreatorTable from './BudgetCreatorTable';
import _ from 'lodash';
import moment from 'moment/moment';
import { DATE_TIME_FORMAT } from '../../constants/common';

const BudgetCreator = () => {
    const dispatch = useDispatch();
    const [inputIncome, setInputIncome] = useState(0);
    const [inputSavings, setInputSavings] = useState(0);
    const [localBudgetCreatorData, setLocalBudgetCreatorData] = useState({});

    const { monthlyIncome, monthlySavings } = useSelector(state => state.budget);
    const { budgetCreatorData } = useSelector(state => state.dashboard);

    useEffect(() => {
        const startDate = moment().subtract(6, 'month').startOf('month').format(DATE_TIME_FORMAT);
        // including the current month here, but will not be counted for avg, only used for total_current month expenses
        const endDate = moment().endOf('month').format(DATE_TIME_FORMAT);
        dispatch(getBudgetCreatorData(startDate, endDate, true));
        dispatch(getMonthlyIncome());
        dispatch(getMonthlySavings());
    }, []);

    useEffect(() => {
        setInputIncome(monthlyIncome);
    }, [monthlyIncome]);

    useEffect(() => {
        setInputSavings(monthlySavings);
    }, [monthlySavings]);

    useEffect(() => {
        setLocalBudgetCreatorData(budgetCreatorData);
    }, [budgetCreatorData]);

    const handleInputIncomeDebounced = useCallback(
        _.debounce(value => {
            if (value.length > 0) {
                dispatch(setIncomeValue(value));
            }
        }, 2000),
        [],
    );

    const handleInputSavingsDebounced = useCallback(
        _.debounce(value => {
            if (value.length > 0) {
                dispatch(setSavingsValue(value));
            }
        }, 2000),
        [],
    );

    const handleInputIncome = event => {
        setInputIncome(event.target.value);
        handleInputIncomeDebounced(event.target.value);
    };

    const handleInputSavings = event => {
        setInputSavings(event.target.value);
        handleInputSavingsDebounced(event.target.value);
    };

    return (
        <div className="h-full font-manrope">
            <div className="h-5/6 md:px-3 pt-1 lg:mt-3 md:mx-3">
                <div className="flex items-center mb-4 lg:mt-4 justify-center bg-lightBlue lg:bg-darkWhite rounded-b-lg py-10 lg:py-0">
                    <span className="font-semibold lg:block hidden w-48">Budget Creator</span>
                    <div className="flex flex-col lg:flex-row md:items-center md:w-full md:mr-10 lg:justify-end">
                        <label htmlFor="input1" className="text-sm md:mr-2 mt-2 md:mt-0 pb-2 lg:pb-0">
                            Net Household Monthly Income (NMI):
                        </label>
                        <Cleave
                            id="input1"
                            onChange={handleInputIncome}
                            value={monthlyIncome ? monthlyIncome : 0}
                            className="border-2 border-blueBorder shadow mr-2 rounded-md pl-3 h-10"
                            options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'none',
                                numeralPositiveOnly: true,
                            }}
                        />
                        <label htmlFor="input2" className="text-sm md:mr-2 mt-2 md:mt-0 pb-2 lg:pb-0">
                            Total Monthly Savings/Investments from NMI:
                        </label>
                        <Cleave
                            id="input2"
                            onChange={handleInputSavings}
                            value={monthlySavings ? monthlySavings : 0}
                            className="border-2 border-blueBorder shadow mr-2 rounded-md pl-3 h-10"
                            options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'none',
                                numeralPositiveOnly: true,
                            }}
                        />
                    </div>
                </div>
                <div className="mt-3 mx-3">
                    <div className="flex items-center mb-4 mt-4 ">
                        We have 18 months of data for each of your categories listed below. Take a look at the average money spent in each
                        category and set a budget amount for them. We will use this budget value in the monthly report each month
                    </div>
                    <BudgetCreatorTable
                        income={inputIncome}
                        savings={inputSavings}
                        budgetCreatorData={localBudgetCreatorData}
                        setLocalBudgetCreatorData={setLocalBudgetCreatorData}
                    />
                </div>
            </div>
        </div>
    );
};

export default BudgetCreator;
