import React, { useState, useEffect } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../user/user.slice';
import { FILE_INPUTS, DATE_FORMATS } from '../../constants/common';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Papa from 'papaparse';

const FilePopup = ({ banks }) => {
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedDateFormat, setSelectedDateFormat] = useState(null);
    const [show, setShow] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [fileHeaders, setFileHeaders] = useState([]);
    const [options, setOptions] = useState([]);
    const [dateOptions, setDateOptions] = useState([]);
    const [dateValue, setDateValue] = useState(null);
    const [firstLine, setFirstLine] = useState(null);

    const { isFileUploading } = useSelector(state => state.user);

    useEffect(() => {
        if (banks && banks.length > 0) {
            makeOptions();
        }
    }, [banks]);

    const handleOptionChange = (title, value, option) => {
        setSelectedOptions(prevSelectedOptions => {
            const existingOption = prevSelectedOptions.find(item => item.title === title);
            if (existingOption) {
                const updatedOptions = prevSelectedOptions.map(item => {
                    if (item.title === title) {
                        return { title, value, option };
                    }
                    return item;
                });

                if (value === 'date') {
                    const date = firstLine[option];
                    setDateValue(date);
                }
                return updatedOptions;
            } else {
                const newVal = [...prevSelectedOptions, { title, value, option }];
                if (value === 'date') {
                    const date = firstLine[option];
                    setDateValue(date);
                }
                return newVal;
            }
        });
    };

    const makeOptions = () => {
        const generatedOptions = [];
        for (let bank of banks) {
            const { id, bankName, lastFourDigits, bankNickName } = bank;
            const label = `${bankName} - ${lastFourDigits} - ${bankNickName}`;
            generatedOptions.push({ value: id, label: label });
        }
        setOptions(generatedOptions);

        const generatedDateOptions = DATE_FORMATS.map(dateFormat => {
            const { format, example } = dateFormat;
            const label = `${format} (Example: ${example})`;
            return { value: format, label: label };
        });

        setDateOptions(generatedDateOptions);
    };

    const handleClose = () => {
        setDateValue(null);
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const handleFileChange = event => {
        const file = event.target.files[0];
        setSelectedFile(file);

        // Read the file contents and extract headers
        const reader = new FileReader();
        reader.onload = e => {
            const contents = e.target.result;

            const { data } = Papa.parse(contents);
            const headers = data[0];
            setFirstLine(data[1]);
            const alphabetArray = headers.map((header, index) => {
                const alphabetLetter = String.fromCharCode(65 + index);
                return `Col ${alphabetLetter} - ${header}`;
            });

            setFileHeaders(alphabetArray);
        };
        reader.readAsText(file);
    };

    const handleUpload = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            dispatch(uploadFile(formData, selectedOptions, selectedAccount, selectedDateFormat, setShow, setDateValue));
        }
    };

    const handleDateDropdownChange = selectedOption => {
        setSelectedDateFormat(selectedOption.value);
    };

    const handleDropdownChange = selectedOption => {
        setSelectedAccount(selectedOption.value);
    };

    return (
        <div>
            <>
                <button className='flex items-center bg-blue-600 rounded-md px-3 py-2  ml-4' onClick={handleShow}>
                    <span className='text-base font-semibold text-white'>Upload CSV File for Manual Account</span>
                </button>

                <Modal
                    className='modal-popup'
                    show={show}
                    onHide={handleClose}
                    dialogClassName='modal-dialog modal-lg'
                    aria-labelledby='example-custom-modal-styling-title'
                >
                    <Modal.Header closeButton>
                        <Modal.Title className='p-3'>Add New Transaction File:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-5'>
                        <Row>
                            <Col>
                                <Row>
                                    File Name:
                                    <input className='py-3' accept='.csv' type='file' onChange={handleFileChange} />
                                </Row>

                                {fileHeaders.length > 0 && (
                                    <>
                                        {FILE_INPUTS.map(file => {
                                            return (
                                                <>
                                                    {file.name}
                                                    <Dropdown
                                                        options={fileHeaders.map((option, index) => ({
                                                            label: option,
                                                            value: index,
                                                        }))}
                                                        onChange={selectedOption =>
                                                            handleOptionChange(file.name, file.value, selectedOption.value)
                                                        }
                                                        placeholder='Select an option'
                                                    />
                                                </>
                                            );
                                        })}
                                    </>
                                )}
                            </Col>

                            <Col>
                                {dateValue && (
                                    <div className='mb-3'>
                                        <span className='font-semibold'>Date Detected :</span> {dateValue}
                                    </div>
                                )}
                                <div className='mb-3'>
                                    Choose Date Format :
                                    <Dropdown
                                        options={dateOptions}
                                        onChange={handleDateDropdownChange}
                                        placeholder='Choose Date Format...'
                                    />
                                </div>

                                <div>
                                    Choose Account :
                                    <Dropdown options={options} onChange={handleDropdownChange}
                                              placeholder='Choose Account...' />
                                </div>
                            </Col>
                        </Row>

                        {isFileUploading ?
                            <h6 className='mt-4 text-green-600'>File Uploading In Progress...</h6> : <></>}

                        <button
                            className={`flex items-center justify-center rounded-md px-3 py-2 w-40 ml-4 mt-4 ${
                                isFileUploading ? 'bg-gray-300' : 'bg-blue-600'
                            }`}
                            onClick={handleUpload}
                            disabled={isFileUploading}
                        >
                            <span className='text-base font-semibold text-white'>Save & Upload</span>
                        </button>
                    </Modal.Body>
                </Modal>
            </>
        </div>
    );
};

export default FilePopup;
