import { toast } from 'react-toastify';

export function showToast(message, status) {
    switch (status) {
        case 'SUCCESS':
            toast.success(message, {
                position: toast.POSITION.TOP_CENTER,
            });
            break;
        case 'FAILURE':
            toast.error(message, {
                position: toast.POSITION.TOP_CENTER,
            });
            break;
        default:
            toast.warning('There is an error in your card', {
                position: toast.POSITION.TOP_CENTER,
            });
            break;
    }
}
