import React from 'react';
import Welcome from './Welcome';
import { ACCOUNT_MANAGEMENT, CONNECT_PLAID } from '../../constants/common';
import LeftBanner from './LeftBanner';
import { useNavigate } from 'react-router-dom';
import NicknameAccountsComponent from './NicknameAccountsComponent';

const NicknameAccount = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 sm:flex-col lg:w-screen md:w-screen sm:w-screen font-manrope h-screen overflow-x-hidden">
            <div className="col-span-12 md:col-span-5 lg:col-span-4 h-full">
                <LeftBanner
                    step="2"
                    logo="idcard"
                    title="	Nickname the accounts"
                    desc='Now, lets make it personal! Assign nicknames to your accounts. This makes it easier to recognize each one. Remember to include the banks name and the main account holders name in the nickname (e.g., "Chase Sapphire - Garrett").'
                    back={CONNECT_PLAID}
                />
            </div>
            <div className="col-span-12  md:col-span-7 lg:col-span-8  mx-20 flex flex-col">
                <Welcome />

                <div className="flex flex-col 2xl:py-10 ">
                    <h1 className="text-xl font-medium my-2 2xl:py-4">Nickname the accounts</h1>
                    <p>
                        Now, let's make it personal! Assign nicknames to your accounts. This makes it easier to recognize each one. Remember
                        to include the bank's name and the main account holder's name in the nickname (e.g., "Chase Sapphire - Garrett").
                    </p>
                </div>

                <NicknameAccountsComponent equalDivision={true} />

                <div className="flex flex-row mt-2 h-full justify-center md:justify-end items-end">
                    <div>
                        <button
                            onClick={e => {
                                e.preventDefault();
                                navigate(ACCOUNT_MANAGEMENT);
                            }}
                            className="flex items-center justify-center bg-blue-600 rounded-md px-4 py-2 mb-2"
                        >
                            <span className="text-base font-semibold text-white ">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NicknameAccount;
