import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { addOwner } from '../user/user.slice';
import 'react-dropdown/style.css';

const AccountManagerContainer = () => {
    const dispatch = useDispatch();

    const { handleSubmit, register, reset } = useForm();

    const onSubmit = data => {
        dispatch(addOwner(data, null));
        reset();
    };

    return (
        <div className="bg-white p-10 mx-10 rounded-lg">
            <div className="font-semibold text-lg pb-3 text-deepDarkBlue">Add Account Manager</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-2">
                    <div className="font-semibold my-2"> Full Name</div>

                    <input
                        placeholder="Full Name"
                        className="rounded-md h-10 px-3 border-2 w-full border-lightBlueButtonBorder shadow-sm"
                        {...register('fullName')}
                    />
                </div>
                <div className="mb-2 ">
                    <div className="font-semibold my-2"> Email </div>

                    <input
                        placeholder="Email"
                        className="rounded-md h-10 px-3 border-2 w-full border-lightBlueButtonBorder shadow-sm"
                        {...register('email')}
                    />
                </div>
                <div className="mb-5 ">
                    <div className="font-semibold my-2"> SMS # </div>

                    <input
                        placeholder="Cell Phone #"
                        className="rounded-md h-10 px-3 border-2 w-full border-lightBlueButtonBorder shadow-sm"
                        {...register('phone')}
                    />
                </div>

                <button type="submit" className="flex items-center bg-blue-600 rounded-md px-4 py-2 mt-1 sm:mt-0 mb-2">
                    <span className="text-base font-semibold text-white"> Add New Manager</span>
                </button>
            </form>
        </div>
    );
};

export default AccountManagerContainer;
