import React, { useState } from 'react';

const Dropdown = ({ options, onChange, defaultValue, transactionId }) => {
    const [selectedValue, setSelectedValue] = useState('');

    const handleOnChange = event => {
        setSelectedValue(event.target.value);

        if (transactionId) {
            onChange(event, transactionId);
        } else {
            onChange(event);
        }
    };

    return (
        <select
            className="bg-transparent border-0 outline-none text-sm font-normal cursor-pointer py-1 px-2 w-5/6"
            onChange={handleOnChange}
            value={selectedValue}
        >
            <option value="" disabled hidden>
                <span>{defaultValue ? defaultValue : 'Category'}</span>
            </option>
            {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default Dropdown;
