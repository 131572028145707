export const LINKS = [
    {
        link: '/transactions',
        name: 'Categorize Transactions',
    },
    {
        link: '/verify-transactions',
        name: 'Verify Transactions',
    },
    {
        link: '/budget-creator',
        name: 'Master Transaction Database',
    },
];

export const FILE_INPUTS = [
    {
        name: 'Date Column:',
        value: 'date',
    },
    {
        name: 'Description Column',
        value: 'desc',
    },
    {
        name: 'Amount Column: ',
        value: 'amount',
    },
];

export const ACCOUNT_TYPES = ['credit', 'check-in'];

export const BUTTONS = [
    {
        image: 'bluedashboard',
        link: '',
    },
    {
        image: 'categorize',
        link: '',
    },
    {
        image: 'categories',
        link: '',
    },
    {
        image: 'verify',
        link: '',
    },
    {
        image: 'mastertableicon',
        link: '',
    },
];

export const STATISTICS = [
    {
        title: 'Current Monthly Spend',
        value: '1820',
        text: 'blue',
        backgroundColor: 'blue',
        backgroundImage: 'lightbluemask',
    },
    {
        title: 'Current Monthly Spend',
        value: '1820',
        text: 'white ',
        backgroundColor: 'darkblue',
        backgroundImage: 'darkbluemask',
    },
    {
        title: 'Current Monthly Spend',
        value: '1820',
        text: 'white',
        backgroundColor: 'black',
        backgroundImage: 'blackmask',
    },
    {
        title: 'Current Monthly Spend',
        value: '1820',
        text: 'blue',
        backgroundColor: 'blue',
        backgroundImage: 'lightbluemask',
    },
    {
        title: 'Current Monthly Spend',
        value: '1820',
        text: 'white',
        backgroundColor: 'darkblue',
        backgroundImage: 'darkbluemask',
    },
];

export const ALL_CATEGORIES = 'ALL_CATEGORIES';

export const DATE_FORMATS = [
    {
        format: 'MM/dd/yyyy',
        example: '03/30/2023',
    },
    {
        format: 'yyyy-MM-dd',
        example: '2023-03-30',
    },
    {
        format: 'dd-MMM-yyyy',
        example: '30-Mar-2023',
    },
    {
        format: 'MMMM d, yyyy',
        example: 'July 31, 2023',
    },
    {
        format: 'MM/dd/yyyy HH:mm',
        example: '07/31/2023 23:59',
    },
    {
        format: 'MMMM d, yyyy HH:mm:ss',
        example: 'July 31, 2023 23:59:59',
    },
    {
        format: 'EEE, MM/dd/yyyy',
        example: 'Sun, 07/31/2023',
    },
    {
        format: 'MMM, yyyy',
        example: 'Jul, 2023',
    },
];

export const BUDGET_CREATOR_HEADERS = ['Avg Monthly Spend', 'Budget', '% of Total Budget'];

export const USER_GEAR_ICON = 'user-gear';
export const SACK_DOLLAR_ICON = 'sack-dollar';
export const ANT_DESIGN_ICON = 'ant-design';
export const DASHBOARD_ICON = 'dashboard';

export const TRANSACTIONS_BUTTONS = [
    { label: 'Categorize', link: '/dashboard/transactions/categorize' },
    { label: 'Verify', link: '/dashboard/transactions/verify' },
    { label: 'Upload CSV', link: '/dashboard/transactions/upload-csv' },
];

export const REPORTING_BUTTONS = [
    { label: 'Detailed Summary', link: '/dashboard/reporting/detailed-summary' },
    { label: 'Category Insights', link: '/dashboard/reporting/category-insights' },
    { label: 'Detailed Table', link: '/dashboard/reporting/detailed-table' },
];

export const SETTINGS_BUTTONS = [
    { label: 'Bank Info', link: '/dashboard/settings/bank-info' },
    { label: 'Account Managers', link: '/dashboard/settings/account-owners' },
    { label: 'Add/Remove Categories', link: '/dashboard/settings/add-remove-categories' },
    { label: 'Edit/Create Budget', link: '/dashboard/settings/budget-creator' },
];

export const LEGEND_DETAILED_SUMMARY = [
    { label: 'Budget', color: 'mediumBlue' },
    { label: 'Actual Expenses', color: 'navyBlue' },
    { label: 'Average Monthly', color: 'lightBlue' },
];

export const MONTHLY_OPTIONS = [
    { value: 3, label: '3 months' },
    { value: 6, label: '6 months' },
    { value: 12, label: '12 months' },
];

// Routes
export const LOGIN_PAGE = '/';
export const CATEGORISE_TRANSACTIONS = '/transactions';
export const VERIFY_TRANSACTIONS = '/verify-transactions';
export const CONNECT_PLAID = '/connect-plaid';
export const VERIFY_EXPENSE_ACCOUNT = '/account-verification';
export const NICKNAME_ACCOUNTS = '/nickname-accounts';
export const ACCOUNT_MANAGEMENT = '/account-manager';

export const SELECT_CATEGORIES = '/select-categories';
export const TRANSACTION_CATEGORIZATION = '/transaction-categorization';
export const TRANSACTION_VERIFICATION = '/transaction-verification';

export const DASHBOARD = '/dashboard';

// Errors
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const CARD_OWNER_ADDED_MESSAGE = 'Account Manager Added';
export const BANK_ACCOUNT_ADDED = 'Bank Account Added';
export const NICKNAME_ADDED = 'Bank Nickname Updated';
export const REQUEST_ALL_FIELDS = 'no transaction selected';
export const ASC = 'ASC';
export const DESC = 'DESC';
export const NORMAL = 'NORMAL';
export const SIX_MONTHS = 6;
export const PLAID_CONNECTED = 'PLAID_CONNECTED';
export const PLAID_NOT_CONNECTED = 'PLAID_NOT_CONNECTED';
export const NON_PLAID = 'NON_PLAID';

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
