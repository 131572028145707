import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import TransactionsList from './TransactionsList';

const SummaryGraph = ({ showDetailedTransactions, data, category, transactions }) => {
    return (
        <div className="grid grid-cols-12 py-2 w-full ">
            <div className="text-sm font-semibold mx-4 col-span-12 md:col-span-2 my-2">{category}</div>
            <div className="mr-6 col-span-12 md:col-span-10 ">
                <ResponsiveContainer width="100%" height={60}>
                    <BarChart margin={{ right: 50 }} data={data} layout="vertical" barCategoryGap={0} barSize={25}>
                        <XAxis type="number" hide={true} />
                        <YAxis
                            type="category"
                            dataKey="name"
                            axisLine={false}
                            tickLine={false}
                            tick={props => (props.payload.value === '' ? null : props.tick)}
                            className="font-semibold text-sm"
                        />
                        <Tooltip />
                        <Bar key={Math.random()} dataKey="amount" fill="#8884d8" label={{ position: 'right', fill: '#000000' }} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            {showDetailedTransactions && (
                <div className="col-span-12 w-full">
                    <TransactionsList transactions={transactions} />
                </div>
            )}
        </div>
    );
};

export default SummaryGraph;
