import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBudgetCreatorData } from './dashboard.slice';
import moment from 'moment/moment';
import { DATE_TIME_FORMAT } from '../../constants/common';

const CategoryTable = () => {
    const dispatch = useDispatch();

    const { isBudgetCreaterDataLoading } = useSelector(state => state.dashboard);
    const { budgetCreatorData } = useSelector(state => state.dashboard);

    useEffect(() => {
        const startDate = moment().subtract(6, 'month').startOf('month').format(DATE_TIME_FORMAT);
        // including the current month here, but will not be counted for avg, only used for total_current month expenses
        const endDate = moment().endOf('month').format(DATE_TIME_FORMAT);
        dispatch(getBudgetCreatorData(startDate, endDate, true));
    }, []);

    return (
        <table className="w-full h-full">
            <thead>
                <tr>
                    <th className="text-xs px-2 font-semibold">Category</th>
                    <th className="text-xs px-2 font-semibold truncate">6 Month Avg</th>
                    <th className="text-xs px-2 font-semibold">Budget</th>
                    <th className="text-xs px-2 font-semibold truncate">Budget - Avg</th>
                    <th className="text-xs px-2 font-semibold truncate">Last Month</th>
                </tr>
            </thead>

            <tbody>
                {budgetCreatorData && (
                    <>
                        {Object.keys(budgetCreatorData).map(categoryType => (
                            <>
                                <tr>
                                    <td className="cell-text text-xs px-2 font-bold"> {categoryType} </td>
                                    <td className="cell-text text-xs px-2"></td>
                                    <td className="cell-text text-xs px-2"></td>
                                    <td className="cell-text text-xs px-2"></td>
                                    <td className="cell-text text-xs px-2"></td>
                                    <td className="cell-text text-xs px-2"></td>
                                    <td className="cell-text text-xs px-2"></td>
                                </tr>
                                {budgetCreatorData[categoryType].map((row, index) => {
                                    const difference = row.budget && row.avgMonthlySpend ? row.budget - row.avgMonthlySpend : 0;

                                    if (difference < 0) {
                                        return (
                                            <tr key={index}>
                                                <td className="cell-text text-xs px-2 truncate">{row.category}</td>
                                                <td className="cell-text text-xs px-2">
                                                    {row.avgMonthlySpend ? row.avgMonthlySpend : '0'}
                                                </td>
                                                <td className="cell-text text-xs px-2">{row.budget ? row.budget : '0'}</td>
                                                <td className="cell-text text-xs px-2 text-red-600 font-semibold">
                                                    {difference.toFixed(2)}
                                                </td>
                                                <td className="cell-text text-xs px-2 text-red-600 font-semibold">
                                                    {row.lastMonthSpend ? `$${row.lastMonthSpend}` : '$0'}
                                                </td>
                                            </tr>
                                        );
                                    }

                                    return null;
                                })}
                            </>
                        ))}
                    </>
                )}
            </tbody>
        </table>
    );
};

export default CategoryTable;
