import React, { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useDispatch, useSelector } from 'react-redux';
import { createLinkToken, exchangePublicToken } from '../user/user.slice';

const PlaidElement = () => {
    const dispatch = useDispatch();

    const { linkToken } = useSelector(state => state.user);

    useEffect(() => {
        dispatch(createLinkToken());
        // eslint-disable-next-line
    }, []);

    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: (public_token, metadata) => {
            // send public_token to server

            const { accounts, institution } = metadata;
            console.log('SUCCESS', public_token, metadata);
            dispatch(exchangePublicToken(public_token, institution.name, accounts));
        },
        onExit: error => {
            console.log('ERROR', error);
        },
    });

    return (
        <div>
            <button onClick={() => open()} disabled={!ready} className="flex items-center bg-blue-600 rounded-md px-4 py-2">
                <img className="mr-1 w-4 h-4" src="/images/plaidlogo.png" alt="Connect Plaid" />
                <span className="text-base font-semibold text-white ">Connect with plaid</span>
            </button>
        </div>
    );
};

export default PlaidElement;
