import React from 'react';
import { useSelector } from 'react-redux';

const CategorySelection = ({ handleCategorySelect }) => {
    const { includedCategoriesCollection } = useSelector(state => state.user);

    return (
        <div className="h-96 2xl:h-full bg-white pl-5 rounded-lg pb-4">
            <div className="text-md font-bold mt-8 pt-3">Categories</div>
            <hr className="border-gray-400 mt-2" />
            <div className="flex flex-col overflow-y-auto h-4/5">
                {includedCategoriesCollection && (
                    <div>
                        {Object.entries(includedCategoriesCollection).map(([collection, subCollections]) => (
                            <div key={collection} className="my-2">
                                <h2 className="font-semibold text-lg">{collection}:</h2>
                                <div className="flex flex-wrap gap-2 mt-2">
                                    {subCollections.map(subCollectionItem => (
                                        <button
                                            onClick={() => handleCategorySelect(subCollectionItem.id)}
                                            className="w-24 py-2 px-4 rounded-md border-1 border-blue-500 hover:bg-blue-500 hover:text-white transition-colors duration-300 text-base lg:text-sm flex items-center justify-center"
                                            key={subCollectionItem.subCollection}
                                        >
                                            <span className="text-xs"> {subCollectionItem.subCollection}</span>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CategorySelection;
