import React, { useCallback, useEffect, useState } from 'react';
import { FAILURE, REQUEST_ALL_FIELDS, TRANSACTION_VERIFICATION } from '../../constants/common';
import { useDispatch, useSelector } from 'react-redux';
import { getBankList, getIncludedCategoriesCollection } from '../user/user.slice';
import { categorise, getKeywordCount, getTransactionList } from '../transaction/transaction.slice';
import Loader from '../utilities/Loader';
import { useNavigate } from 'react-router';
import FirstTransation from './FirstTransation';
import _ from 'lodash';
import CategorySelection from './CategorySelection';
import ProgressBar from './ProgressBar';
import TransactionsList from './TransactionsList';
import { useUser } from '@clerk/clerk-react';
import KeywordsSection from './KeywordsSection';
import { showToast } from '../toast-handler/toast-handler';

const TransactionCategorizationComponent = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useUser();
    const [selectedKeyword, setSelectedKeyword] = useState(null);
    const [manualKeyWord, setManualKeyWord] = useState('');
    const { keywordCount } = useSelector(state => state.transaction);
    const { transactions, keywords, classified, totalTransactions, isKeywordCountLoading, firstTransaction, isTransactionsLoading } =
        useSelector(state => state.transaction);

    useEffect(() => {
        if (keywords !== null && Array.isArray(keywords) && keywords.length > 0) {
            setSelectedKeyword(keywords[0]);
        }
    }, [keywords]);

    useEffect(() => {
        dispatch(getBankList());
        dispatch(getTransactionList());
        dispatch(getIncludedCategoriesCollection());
        // eslint-disable-next-line
    }, []);

    const handleKeywordClick = button => {
        setSelectedKeyword(button);
        dispatch(getKeywordCount(button));
        setManualKeyWord('');
    };

    const handleManualKeywordDebounced = useCallback(
        _.debounce(value => {
            setSelectedKeyword(value);
            dispatch(getKeywordCount(value));
            console.log('debounced value', value);
        }, 1000),
        [],
    );

    const handleManualKeyword = value => {
        setManualKeyWord(value);
        handleManualKeywordDebounced(value);
    };

    const handleCategorySelect = categoryId => {
        if (transactions.length && categoryId) {
            const transactionsIdsToCategorize = transactions.map(tr => tr.id);
            dispatch(categorise(transactionsIdsToCategorize, categoryId));
            setManualKeyWord('');
        } else {
            showToast(REQUEST_ALL_FIELDS, FAILURE);
        }
    };

    return (
        <>
            {isTransactionsLoading ? (
                <div className="flex justify-center items-center w-full">
                    <Loader />
                </div>
            ) : (
                <>
                    {transactions && transactions.length > 0 ? (
                        <>
                            <div className=" bg-white rounded-xl px-4">
                                {firstTransaction ? <FirstTransation firstTransaction={firstTransaction} /> : <Loader />}

                                <div className="mx-auto w-full bg-darkWhite h-full rounded-lg">
                                    <div className="bg-lightblue p-8">
                                        <div className="grid grid-cols-12 gap-x-5">
                                            <div className="col-span-12 lg:col-span-6">
                                                <KeywordsSection
                                                    keywords={keywords}
                                                    selectedKeyword={selectedKeyword}
                                                    manualKeyWord={manualKeyWord}
                                                    handleKeywordClick={handleKeywordClick}
                                                    handleManualKeyword={handleManualKeyword}
                                                />

                                                <TransactionsList
                                                    transactions={transactions}
                                                    isKeywordCountLoading={isKeywordCountLoading}
                                                    keywordCount={keywordCount}
                                                    selectedKeyword={selectedKeyword}
                                                    firstName={user.firstName}
                                                />
                                            </div>
                                            <div className="col-span-12 lg:col-span-6 h-full">
                                                <ProgressBar totalTransactions={totalTransactions} classified={classified} />
                                                <CategorySelection
                                                    handleCategorySelect={handleCategorySelect}
                                                    keyword={selectedKeyword}
                                                    setManualKeyWord={setManualKeyWord}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="flex h-screen">
                            <div className="m-auto flex flex-col items-center justify-center">
                                <h1 className="text-center">All Transactions Categorized!</h1>
                                {!props.hideButton && (
                                    <div className="mt-8">
                                        <button
                                            onClick={() => navigate(TRANSACTION_VERIFICATION)}
                                            className="bg-blue-600 rounded-md px-4 py-2"
                                        >
                                            <span className="text-base font-semibold text-white">Done!</span>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default TransactionCategorizationComponent;
