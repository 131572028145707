import React, { useEffect } from 'react';
import Welcome from './Welcome';
import { NICKNAME_ACCOUNTS, LOGIN_PAGE } from '../../constants/common';
import LeftBanner from './LeftBanner';
import PlaidElement from './PlaidElement';
import ManualAccountModal from './ManualAccountModal';
import { useDispatch } from 'react-redux';
import { saveUser, getTransactionStatus, fetchPlaidTransactions } from '../user/user.slice';
import { useUser, useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';

const ConnectPlaid = () => {
    const { user } = useUser();
    const { getToken, isLoaded, isSignedIn } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (!isLoaded || !isSignedIn) {
        navigate(LOGIN_PAGE);
    }

    useEffect(() => {
        if (user) {
            const { fullName, emailAddresses } = user;
            const email = emailAddresses[0].emailAddress;
            dispatch(saveUser(fullName, email));
            dispatch(getTransactionStatus());
        }
        // eslint-disable-next-line
    }, [user]);

    return (
        <div className="grid grid-cols-12 font-manrope h-screen">
            <div className="col-span-12 md:col-span-5 lg:col-span-4 md:h-full lg:overflow-y-hidden">
                <LeftBanner
                    step="1"
                    logo="plaidlogo"
                    title="Connect your accounts with Plaid"
                    desc="This step integrates your financial accounts with our
					system. Click the button to begin securely connecting with
					Plaid, our trusted financial data partner."
                />
            </div>

            <div className="col-span-12 md:col-span-7 lg:col-span-8 mx-20 h-full md:flex md:flex-col">
                <Welcome />

                <div className="flex flex-row items-center mt-3">
                    <div className="flex flex-col">
                        <img src="/images/cupet.png" alt="Logo" />
                    </div>
                    <div className="flex flex-col ">
                        <img alt="chain" className="w-8 ml-15 mr-16" src="/images/chain.png" />
                    </div>
                    <div className="flex flex-col">
                        <img alt="plaid" src="/images/plaid.png" />
                    </div>
                </div>
                <div className="sm:items-center md:items-center lg:items-start items-center flex flex-col mt-3 mb-16">
                    <div className="flex justify-between w-full">
                        <h1 className="lg:text-3xl md:text-2xl sm:text-xs font-medium text-left my-3">Connect your accounts with Plaid</h1>
                    </div>

                    <div className="w-full">
                        <span className="lg:text-md sm:text-sm">
                            This step integrates your financial accounts with our system. Click the button to begin securely connecting with
                            Plaid, our trusted financial data partner.
                        </span>
                    </div>
                </div>

                <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row items-center justify-between mt-auto mb-4">
                    <PlaidElement />
                    <span className="flex items-center">OR</span>
                    <ManualAccountModal />

                    <div className="items-end justify-end">
                        <div>
                            <button
                                onClick={e => {
                                    e.preventDefault();
                                    dispatch(fetchPlaidTransactions());
                                    navigate(NICKNAME_ACCOUNTS);
                                }}
                                className="flex items-center bg-blue-600 rounded-md px-4 py-2 mt-1 sm:mt-0"
                            >
                                <span className="text-base font-semibold text-white ">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectPlaid;
