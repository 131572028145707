import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNickName, getBankList } from '../user/user.slice';
import { getTotalTransactionsByAccount } from './bank.slice';
import { PLAID_CONNECTED, PLAID_NOT_CONNECTED } from '../../constants/common';
import Loader from '../utilities/Loader';

const NicknameAccountsComponent = ({ showCount, equalDivision }) => {
    const dispatch = useDispatch();
    const [nicknames, setNicknames] = useState([]);
    const { accountsWithCount, isAccountsWithCountLoading, isStatusLoading } = useSelector(state => state.bank);

    useEffect(() => {
        dispatch(getBankList());
        dispatch(getTotalTransactionsByAccount());
        // eslint-disable-next-line
    }, []);

    const saveNickname = (e, bank, index) => {
        e.preventDefault();
        const nickname = nicknames[index];
        dispatch(setNickName(bank, nickname));
    };

    const handleInputChange = (e, index) => {
        const updatedNicknames = [...nicknames];
        updatedNicknames[index] = e.target.value;
        setNicknames(updatedNicknames);
    };

    const formatDate = dateString => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        });
        return formattedDate;
    };

    return (
        <div>
            <ul className="list-disc list-inside">
                {isAccountsWithCountLoading ? (
                    <div className="w-screen">
                        <div className="w-1/2 flex justify-center items-center">
                            <Loader />
                        </div>
                    </div>
                ) : (
                    <>
                        {accountsWithCount && (
                            <>
                                {accountsWithCount.map((bank, index) => {
                                    const { bankname, lastfourdigits, banknickname, totaltransactions, latestdate, status } = bank;
                                    return (
                                        <div className="grid grid-cols-12">
                                            {/*Added items-center and relative */}
                                            <div
                                                className={`col-span-12 sm:col-span-6 md:col-span-12 lg:col-span-${
                                                    equalDivision ? '6' : '4'
                                                } mt-2`}
                                            >
                                                <span className="text-blue-500">&#8226;</span>
                                                <span className="font-semibold mr-5">
                                                    {bankname} XXXX
                                                    {lastfourdigits} {banknickname}
                                                </span>
                                            </div>
                                            <div
                                                className={`col-span-12 sm:col-span-6 md:col-span-12 lg:col-span-${
                                                    equalDivision ? '6' : '8'
                                                }`}
                                            >
                                                <div className="flex flex-col md:flex-row">
                                                    <form onSubmit={e => saveNickname(e, bank, index)}>
                                                        <span className="relative">
                                                            <input
                                                                onChange={e => handleInputChange(e, index)}
                                                                type="text"
                                                                className="border-2 border-mediumBlue rounded-md px-3 py-1 mt-1 md:mt-0 h-8 w-full sm:w-auto"
                                                                placeholder={banknickname ? banknickname : 'Enter Nickname'}
                                                            />
                                                            <button
                                                                className="btn btn-primary h-8 absolute right-0 mt-1 sm:mt-0 rounded-l-none"
                                                                type="submit"
                                                            >
                                                                <img
                                                                    className="w-5 h-full"
                                                                    src="/images/whitearrow.png"
                                                                    alt="Connect Plaid"
                                                                />
                                                            </button>
                                                        </span>
                                                    </form>

                                                    {showCount && (
                                                        <>
                                                            {isStatusLoading ? (
                                                                <Loader />
                                                            ) : (
                                                                <>
                                                                    <div className="font-semibold ml-0 md:ml-10 md:my-0 my-2 flex items-center">
                                                                        {status === PLAID_CONNECTED ? (
                                                                            <img
                                                                                alt="greentick"
                                                                                className="w-6 mr-2"
                                                                                src="/images/greentick.png"
                                                                            />
                                                                        ) : status === PLAID_NOT_CONNECTED ? (
                                                                            <img
                                                                                alt="redcross"
                                                                                className="w-6 mr-2"
                                                                                src="/images/redcross.png"
                                                                            />
                                                                        ) : (
                                                                            <div className="w-8"></div>
                                                                        )}
                                                                        <span className="text-sm">
                                                                            {totaltransactions > 0 ? totaltransactions : 0} Total
                                                                            Transactions - Most Recent Transaction:{' '}
                                                                            {latestdate ? formatDate(latestdate) : 'N/A'}
                                                                        </span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </>
                )}
            </ul>
        </div>
    );
};

export default NicknameAccountsComponent;
