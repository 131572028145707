import React from 'react';
import {
    ACCOUNT_MANAGEMENT,
    CONNECT_PLAID,
    NICKNAME_ACCOUNTS,
    SELECT_CATEGORIES,
    TRANSACTION_CATEGORIZATION,
    TRANSACTION_VERIFICATION,
    VERIFY_EXPENSE_ACCOUNT,
} from '../../constants/common';
import { Link } from 'react-router-dom';

const BreadCrumbs = ({ stepNumber }) => {
    return (
        <div className="flex items-center mb-3 mx-4">
            <Link to={CONNECT_PLAID} className="relative">
                {stepNumber === 1 && <img src="/images/breadcrumb.png" alt="Selected Breadcrumb" className="absolute" />}
                <div className={`w-8 h-8 rounded-full relative border ${stepNumber >= 1 ? 'bg-white' : 'border-red-400'}`}>
                    <span className="absolute inset-0 flex items-center justify-center text-blue-400 font-bold text-sm">1</span>
                </div>
            </Link>

            <div className="h-px bg-blue-400 flex-grow"></div>

            <Link to={NICKNAME_ACCOUNTS} className="relative">
                {stepNumber === 2 && <img src="/images/breadcrumb.png" alt="Selected Breadcrumb" className="absolute inset-0 z-0" />}
                <div className={`w-8 h-8 rounded-full relative border ${stepNumber >= 2 ? 'bg-white' : 'border-blue-400'}`}>
                    <span className="absolute inset-0 flex items-center justify-center text-blue-400 font-bold text-sm">2</span>
                </div>
            </Link>

            <div className="h-px bg-blue-400 flex-grow"></div>

            <Link to={ACCOUNT_MANAGEMENT} className="relative">
                {stepNumber === 3 && <img src="/images/breadcrumb.png" alt="Selected Breadcrumb" className="absolute inset-0 z-0" />}
                <div className={`w-8 h-8 rounded-full relative border ${stepNumber >= 3 ? 'bg-white' : 'border-blue-400'}`}>
                    <span className="absolute inset-0 flex items-center justify-center text-blue-400 font-bold text-sm">3</span>
                </div>
            </Link>

            <div className="h-px bg-blue-400 flex-grow"></div>

            <Link to={SELECT_CATEGORIES} className="relative">
                {stepNumber === 4 && <img src="/images/breadcrumb.png" alt="Selected Breadcrumb" className="absolute inset-0 z-0" />}
                <div className={`w-8 h-8 rounded-full relative border ${stepNumber >= 4 ? 'bg-white' : 'border-blue-400'}`}>
                    <span className="absolute inset-0 flex items-center justify-center text-blue-400 font-bold text-sm">4</span>
                </div>
            </Link>

            <div className="h-px bg-blue-400 flex-grow"></div>

            <Link to={TRANSACTION_CATEGORIZATION} className="relative">
                {stepNumber === 5 && <img src="/images/breadcrumb.png" alt="Selected Breadcrumb" className="absolute inset-0 z-0" />}
                <div className={`w-8 h-8 rounded-full relative border ${stepNumber >= 5 ? 'bg-white' : 'border-blue-400'}`}>
                    <span className="absolute inset-0 flex items-center justify-center text-blue-400 font-bold text-sm">5</span>
                </div>
            </Link>

            <div className="h-px bg-blue-400 flex-grow"></div>

            <Link to={TRANSACTION_VERIFICATION} className="relative">
                {stepNumber === 6 && <img src="/images/breadcrumb.png" alt="Selected Breadcrumb" className="absolute inset-0 z-0" />}
                <div className={`w-8 h-8 rounded-full relative border ${stepNumber >= 6 ? 'bg-white' : 'border-blue-400'}`}>
                    <span className="absolute inset-0 flex items-center justify-center text-blue-400 font-bold text-sm">6</span>
                </div>
            </Link>

            <div className="h-px bg-blue-400 flex-grow"></div>

            <Link to={VERIFY_EXPENSE_ACCOUNT} className="relative">
                {stepNumber === 7 && <img src="/images/breadcrumb.png" alt="Selected Breadcrumb" className="absolute inset-0 z-0" />}
                <div className={`w-8 h-8 rounded-full relative border ${stepNumber >= 7 ? 'bg-white' : 'border-blue-400'}`}>
                    <span className="absolute inset-0 flex items-center justify-center text-blue-400 font-bold text-sm">7</span>
                </div>
            </Link>
        </div>
    );
};

export default BreadCrumbs;
