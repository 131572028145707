import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { getMasterDataTableData } from '../dashboard/dashboard.slice';

const Searchbar = () => {
    const dispatch = useDispatch();

    const handleInputChangeDebounced = useCallback(
        _.debounce(value => {
            if (value === '') {
                dispatch(getMasterDataTableData('', null, null));
            } else {
                dispatch(getMasterDataTableData(value, null, null));
            }
        }, 1000),
        [],
    );

    const handleInputChange = e => {
        handleInputChangeDebounced(e.target.value);
    };

    return (
        <div className="flex items-center border-2 border-blueBorder shadow  mr-2 rounded-md pl-3 h-10 w-full md:w-1/2 mb-5">
            <i className="fas fa-search search-icon text-gray-500"></i>
            <input
                onChange={handleInputChange}
                id="input2"
                className="border-none outline-none bg-transparent flex-grow ml-2 w-full"
                placeholder="Search..."
            />
        </div>
    );
};

export default Searchbar;
