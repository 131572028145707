import React from 'react';
import { SignUp } from '@clerk/clerk-react';

const SignUpPage = () => {
    return (
        <>
            <div className="w-screen h-screen flex">
                <div className="w-1/3 bg-cover bg-no-repeat px-10 bg-blue-600 flex flex-col justify-between">
                    <img src="/images/cupet.png" alt="Logo" className="h-36 my-3 w-1/2" />
                    <div className="pb-16 pl-4">
                        <div className="flex flex-row items-center mt-5">
                            <div className="flex flex-col mr-2">
                                <img alt="loginlogo" src="/images/loginlogo.png" />
                            </div>
                        </div>
                        <div className="w-4/5">
                            <h1 className="text-white text-3xl font-semibold text-left my-3">Welcome To Cupet!</h1>
                            <p className="text-white text-left font-light">
                                Cupet Offers Users The Capability To Integrate Their Bank Accounts, Classify Their Transactions, And Create
                                Personalized Budgets.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-2/3 flex justify-center items-center">
                    <SignUp />
                </div>
            </div>
        </>
    );
};

export default SignUpPage;
