import React from 'react';
import { UserButton } from '@clerk/clerk-react';

const UserIconHeader = () => {
    return (
        <div className="flex justify-end pt-2">
            <UserButton />
        </div>
    );
};

export default UserIconHeader;
