import { createSlice } from '@reduxjs/toolkit';
import network from '../../services/network';
import {
    GET_MONTHLY_INCOME,
    GET_MONTHLY_SAVINGS,
    SET_CATEGORY_BUDGET,
    SET_MONTHLY_INCOME,
    SET_MONTHLY_SAVINGS,
} from '../../constants/network-calls';
import { showToast } from '../toast-handler/toast-handler';
import Cookies from 'js-cookie';
import { FAILURE } from '../../constants/common';

const budgetSlice = createSlice({
    name: 'budget',
    initialState: {
        monthlyIncome: null,
        monthlySavings: null,
    },
    reducers: {
        setMonthlyIncome(state, action) {
            state.monthlyIncome = action.payload;
        },
        setMonthlySavings(state, action) {
            state.monthlySavings = action.payload;
        },
    },
});

// --------------------------------------------THUNK FUNCTIONS----------------------------------------

export const getMonthlyIncome = () => (dispatch, getState) => {
    const id = Cookies.get('userId');

    const headers = {
        Authorization: `${id}`,
    };

    network
        .fetch('POST', GET_MONTHLY_INCOME, { id }, null, headers)
        .then(res => {
            const { data } = res;
            dispatch(setMonthlyIncome(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getMonthlySavings = () => (dispatch, getState) => {
    const id = Cookies.get('userId');

    const headers = {
        Authorization: `${id}`,
    };

    network
        .fetch('POST', GET_MONTHLY_SAVINGS, { id }, null, headers)
        .then(res => {
            const { data } = res;
            dispatch(setMonthlySavings(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const setIncomeValue = income => (dispatch, getState) => {
    const id = Cookies.get('userId');

    const headers = {
        Authorization: `${id}`,
    };

    dispatch(setMonthlyIncome(income));

    network
        .fetch('POST', SET_MONTHLY_INCOME, { id, income }, null, headers)
        .then(res => {})
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const setSavingsValue = savings => (dispatch, getState) => {
    const id = Cookies.get('userId');

    const headers = {
        Authorization: `${id}`,
    };

    dispatch(setMonthlySavings(savings));

    network
        .fetch('POST', SET_MONTHLY_SAVINGS, { id, savings }, null, headers)
        .then(res => {})
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const setCategoryBudget = (userCategoryId, budget) => (dispatch, getState) => {
    const id = Cookies.get('userId');

    const headers = {
        Authorization: `${id}`,
    };

    network
        .fetch('POST', SET_CATEGORY_BUDGET, { id, userCategoryId, budget }, null, headers)
        .then(res => {})
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const { setMonthlyIncome, setMonthlySavings } = budgetSlice.actions;

export default budgetSlice.reducer;
