import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBudgetCreatorData } from '../dashboard/dashboard.slice';
import { getMonthlyIncome, getMonthlySavings, setCategoryBudget } from './budget.slice';
import Loader from '../utilities/Loader';
import Cleave from 'cleave.js/react';
import _ from 'lodash';
import { BUDGET_CREATOR_HEADERS } from '../../constants/common';

const BudgetCreatorTable = ({ income, savings, setLocalBudgetCreatorData, budgetCreatorData }) => {
    const dispatch = useDispatch();

    // colSpan={headerDates.length} use for no data

    const { isBudgetCreaterDataLoading } = useSelector(state => state.dashboard);

    useEffect(() => {
        dispatch(getMonthlyIncome());
        dispatch(getMonthlySavings());
    }, []);

    const handleInputChangeDebounced = useCallback(
        _.debounce((newData, categoryId, value) => {
            dispatch(setBudgetCreatorData(newData));
            dispatch(setCategoryBudget(categoryId, Number(value)));
        }, 1000),
        [],
    );

    const difference = () => {
        return income - savings;
    };

    const handleInputChange = (categoryGroup, categoryId, value) => {
        if (value.length > 0) {
            const newData = { ...budgetCreatorData };
            const categoryGroupData = [...newData[categoryGroup]];

            const categoryIndex = categoryGroupData.findIndex(item => item.categoryId === categoryId);

            if (categoryIndex !== -1) {
                const category = { ...categoryGroupData[categoryIndex] };
                category.budget = value;

                if (!isNaN(value)) {
                    category.percentOfTotal = (value / category.avgMonthlySpend) * 100;
                } else {
                    category.percentOfTotal = 0;
                }

                categoryGroupData[categoryIndex] = category;
                newData[categoryGroup] = categoryGroupData;
                setLocalBudgetCreatorData(newData);
                handleInputChangeDebounced(newData, categoryId, Number(value));
            }
        } else {
            value = 0;
            const newData = { ...budgetCreatorData };
            const categoryGroupData = [...newData[categoryGroup]];

            const categoryIndex = categoryGroupData.findIndex(item => item.categoryId === categoryId);

            if (categoryIndex !== -1) {
                const category = { ...categoryGroupData[categoryIndex] };
                category.budget = value;

                if (!isNaN(value)) {
                    category.percentOfTotal = (value / category.avgMonthlySpend) * 100;
                } else {
                    category.percentOfTotal = 0;
                }

                categoryGroupData[categoryIndex] = category;
                newData[categoryGroup] = categoryGroupData;
                setLocalBudgetCreatorData(newData);
                handleInputChangeDebounced(newData, categoryId, Number(value));
            }
        }
    };

    const calculateTotalBudget = () => {
        let totalBudget = 0;

        for (const categoryGroup in budgetCreatorData) {
            const categories = budgetCreatorData[categoryGroup];

            for (const category of categories) {
                totalBudget += Number(category.budget);
            }
        }

        return totalBudget;
    };

    return (
        <>
            {isBudgetCreaterDataLoading ? (
                <div className="flex w-full h-full items-center justify-center">
                    <Loader />
                </div>
            ) : (
                <>
                    {' '}
                    <div className="overflow-x-auto">
                        <table className="w-full border-collapse">
                            <thead>
                                <tr>
                                    <th className="p-2 text-left border-t-2 border-r-2 border-b-2 "></th>
                                    {BUDGET_CREATOR_HEADERS.map((header, index) => (
                                        <th
                                            key={index}
                                            className="p-2 text-center text-deepNavyBlue border-t-2 border-b-2 border-l-2 font-semibold text-xl"
                                        >
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {budgetCreatorData && (
                                    <>
                                        {Object.keys(budgetCreatorData).map(categoryType => (
                                            <>
                                                <tr>
                                                    <td className="border-l-0 font-bold"> {categoryType} Accounts</td>
                                                    <td className="border-l-2"></td>
                                                    <td className="border-l-2"></td>
                                                    <td className="border-l-2"></td>
                                                    <td className="border-l-2"></td>
                                                    <td className="border-l-2"></td>
                                                    <td className="border-l-2"></td>
                                                </tr>
                                                {budgetCreatorData[categoryType].map((row, index) => (
                                                    <tr key={index}>
                                                        <td className="border-l-0 pl-10">{row.category}</td>
                                                        <td className="text-center border-l-2">
                                                            {row.avgMonthlySpend ? row.avgMonthlySpend : '0'}
                                                        </td>
                                                        <td className="text-center border-l-2">
                                                            <Cleave
                                                                className="text-center bg-transparent border-none p-1 w-full"
                                                                value={row.budget ? row.budget : '0'}
                                                                onChange={e =>
                                                                    handleInputChange(categoryType, row.categoryId, e.target.value)
                                                                }
                                                                options={{
                                                                    numeral: true,
                                                                    numeralThousandsGroupStyle: 'none',
                                                                    numeralPositiveOnly: true,
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-center border-l-2">
                                                            {income && difference() != 0
                                                                ? ((row.budget / difference()) * 100).toFixed(2)
                                                                : '0.0'}
                                                            %
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="sticky bottom-0  flex justify-between mt-1 bg-blue-100 rounded-b-lg  py-3 text-sm md:text-md lg:text-lg text-blue-700 font-semibold px-10 ">
                        <div>Remaining Budget : {(difference() - calculateTotalBudget()).toFixed(2)}$</div>
                        <div>Total Budget : {difference().toFixed(2)}$</div>
                    </div>
                </>
            )}
        </>
    );
};

export default BudgetCreatorTable;
