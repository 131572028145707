import React, { useEffect, useState } from 'react';
import Dropdown from './Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { categorizeSingleTransaction } from '../transaction/transaction.slice';

const TransactionRow = ({ amount, desc, date, acceptedCategories, transactionId }) => {
    const dispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState(null);

    const options = acceptedCategories
        ? acceptedCategories.map(category => ({
              label: category.categoryName,
              value: category.id,
          }))
        : [];

    const handleChange = event => {
        setSelectedCategory(event.target.value);
    };

    const categorize = () => {
        dispatch(categorizeSingleTransaction(transactionId, selectedCategory));
    };

    return (
        <div className="grid grid-cols-6 items-center w-full my-2">
            <img alt="plaidround" className="h-10 col-span-1" src="images/plaidround.png" />
            <div className="col-span-2 flex flex-col ">
                <span className="font-light truncate">{desc}</span>
                <span className="text-sm text-gray-400">
                    {date}, ${amount}
                </span>
            </div>
            <div className="col-span-2">
                <Dropdown options={options} onChange={handleChange} />
            </div>
            <button
                onClick={() => categorize()}
                className="col-span-1 flex items-center bg-blue-600 rounded-md justify-center py-3 text-white font-semibold h-1/4"
            >
                <span className="text-xs">Submit</span>
            </button>
        </div>
    );
};

export default TransactionRow;
