import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPredictedCategory } from './utilities.slice';
import Spinner from 'react-bootstrap/Spinner';
import { formatDate } from './helperFunctions';

function OpenAITestingPage() {
    const dispatch = useDispatch();

    const { isCategoriesLoading, gptCategories, ourCategories, withoutGPTCategory, transactions, transactionsMatches, descFromGoogle } =
        useSelector(state => state.utilities);
    const [inputText, setInputText] = useState('');
    const [response, setResponse] = useState('');

    const handleSendRequest = async () => {
        dispatch(getPredictedCategory(inputText));
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="flex">
                <input
                    type="text"
                    className="border border-gray-300 rounded p-2 m-2"
                    value={inputText}
                    onChange={e => setInputText(e.target.value)}
                    placeholder="Enter text..."
                />
                <button className="bg-blue-500 text-white rounded p-2" onClick={handleSendRequest}>
                    Send Request
                </button>
            </div>
            {isCategoriesLoading ? (
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            ) : (
                <div className="grid grid-cols-12">
                    <div className="col-span-4 w-2/3 ml-20">
                        {withoutGPTCategory ? (
                            <div className="mt-4 ">
                                <b>Step 1: Category From Existing Transactions </b>
                                <p>
                                    {withoutGPTCategory ? (
                                        <>
                                            <div> {withoutGPTCategory}</div>
                                            <b>Categories with Count </b>
                                            {transactions.map(transaction => {
                                                const { category_name, count } = transaction;
                                                return (
                                                    <div className="flex">
                                                        <p>
                                                            <span className="text-red-500"> {category_name}</span> has <b>{count}</b>{' '}
                                                            transactions
                                                        </p>
                                                    </div>
                                                );
                                            })}

                                            <div className="h-96 overflow-y-auto overflow-x-auto">
                                                {transactionsMatches && transactionsMatches.length > 0 && (
                                                    <table className="w-full border-collapse text-sm">
                                                        <thead>
                                                            <tr className="bg-gray-200">
                                                                <th className="border px-1">#</th>
                                                                <th className="border px-1">Date</th>
                                                                <th className="border px-1">Description</th>
                                                                <th className="border px-1">Amount</th>
                                                                <th className="border px-1">Category Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {transactionsMatches.map((transaction, index) => {
                                                                const { date, desc, amount, categoryname } = transaction;

                                                                return (
                                                                    <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                                                                        <td className="border px-1">{index + 1}</td>
                                                                        <td className="border px-1">{formatDate(date)}</td>
                                                                        <td className="border px-1">{desc}</td>
                                                                        <td className="border px-1">${amount}</td>
                                                                        <td className="border px-1 truncate">{categoryname}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div>No match found from existing transactions. Moving on to OpenAI</div>
                                        </>
                                    )}
                                </p>
                            </div>
                        ) : (
                            <div className="mt-4">
                                <b>Step 1: Category From Existing Transactions </b>

                                <div>No match found from existing transactions. Moving on to OpenAI</div>
                            </div>
                        )}
                    </div>
                    <div className="col-span-4 pl-10 pr-16">
                        {gptCategories && (
                            <div className="mt-4">
                                {gptCategories.length > 0 && (
                                    <>
                                        <b>Step 2 : Categories from OpenAI </b>
                                        {gptCategories.map(category => (
                                            <p>{category.categoryName}</p>
                                        ))}
                                    </>
                                )}
                            </div>
                        )}

                        {descFromGoogle && (
                            <p>
                                <b>Description from Google API :</b> {descFromGoogle}
                            </p>
                        )}
                    </div>
                    <div className="col-span-4">
                        {ourCategories && (
                            <div className="mt-4">
                                {ourCategories.length > 0 && (
                                    <>
                                        <b>Step 3 : Category Chosen </b>
                                        {ourCategories.map(category => (
                                            <p>{category}</p>
                                        ))}

                                        {ourCategories.length === 1 ? (
                                            <div className="text-red-500">
                                                Step 4 : As category matched from user categories only returned one category, we will
                                                categorise
                                            </div>
                                        ) : (
                                            <div className="text-red-500">
                                                Step 4: More then one category matched from OpenAI and our user categories. Will be
                                                forwarding to user throught Twilio for a better match
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default OpenAITestingPage;
