import React from 'react';

const WhiteBlueButton = ({ value, selectedValue, handleKeywordClick }) => {
    return (
        <button
            className={`bg-blue-500  text-white py-1 px-3 rounded mx-1 ${
                selectedValue === value
                    ? 'bg-mediumBlue border-2 border-darkBlueButtonBorder'
                    : 'bg-white border-2  border-lightBlueButtonBorder'
            }`}
            onClick={() => handleKeywordClick(value)}
        >
            <span className={`text-sm  px-2 ${selectedValue === value ? 'text-white' : 'text-gray-400'}`}>{value} months</span>
        </button>
    );
};

export default WhiteBlueButton;
