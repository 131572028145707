import React, { useEffect } from 'react';
import { CONNECT_PLAID, DASHBOARD } from '../../constants/common';
import LeftBanner from './LeftBanner';
import Welcome from './Welcome';
import { useDispatch, useSelector } from 'react-redux';
import { getBankList, getOwnerList, fetchPlaidTransactions, getTransactionStatus } from '../user/user.slice';
import FilePopup from './FilePopup';
import { useNavigate } from 'react-router-dom';
import { getTotalTransactionsByAccount } from './bank.slice';

const AccountVerification = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { banks, transactionStatus } = useSelector(state => state.user);
    const { accountsWithCount } = useSelector(state => state.bank);

    useEffect(() => {
        dispatch(getBankList());
        dispatch(getTotalTransactionsByAccount());
        dispatch(getOwnerList());
        dispatch(getTransactionStatus());
        // eslint-disable-next-line
    }, []);

    const formatDate = dateString => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        });
        return formattedDate;
    };

    return (
        <div className="grid grid-cols-12  w-full font-manrope md:h-screen overflow-x-hidden relative">
            <div className="col-span-12  md:col-span-5 lg:col-span-4 h-full">
                <LeftBanner
                    step="7"
                    logo="wallet"
                    title="Verify all expense accounts are included"
                    desc="We want to ensure every dollar is accounted for. If any
				of your accounts can't be connected via Plaid, you'll
				have the option to manually upload a CSV file of your
				transactions here."
                    back={CONNECT_PLAID}
                />
            </div>

            <div className="flex flex-col col-span-12  md:col-span-7 lg:col-span-8 mx-20 h-screen">
                <Welcome />
                <div className="flex flex-col  mt-4  mb-2 2xl:py-5">
                    <div className="flex flex-col sm:flex-row justify-between w-full">
                        <h1 className="text-xl font-medium text-left my-3">Verify all expense accounts are included</h1>
                        <button
                            className="flex items-center bg-blue-600 rounded-md px-4 h-12 mt-2 sm:mt-0"
                            onClick={() => dispatch(fetchPlaidTransactions())}
                        >
                            <span className="text-white justify-center items-center"> Sync Transactions</span>
                        </button>
                    </div>

                    {transactionStatus && <p className="mb-3">Transaction Status: {transactionStatus}</p>}

                    <p className="mb-2">
                        We want to ensure every dollar is accounted for. If any of your accounts can't be connected via Plaid, you'll have
                        the option to manually upload a CSV file of your transactions here.
                    </p>
                </div>

                <div className="overflow-x-auto mt-2">
                    <ul className="list-disc list-inside">
                        {accountsWithCount && (
                            <>
                                {accountsWithCount.map(account => {
                                    const { bankname, lastfourdigits, banknickname, totaltransactions, earliestdate } = account;
                                    return (
                                        <div key={bankname} className="grid grid-cols-12">
                                            <div className="col-span-6">
                                                <span className="text-blue-500 w-1/2">&#8226;</span>
                                                {bankname} XXXX{lastfourdigits} {banknickname}{' '}
                                            </div>
                                            <div className="col-span-6">
                                                <span className="text-red-500">
                                                    {totaltransactions > 0 ? totaltransactions : 0} Total Transactions - Earliest Date:{' '}
                                                    {earliestdate ? formatDate(earliestdate) : 'N/A'}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </ul>
                </div>

                <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row  items-center justify-between mt-4  mt-auto mb-4">
                    {banks && banks.length && <FilePopup banks={banks} />}
                    <div className="items-end justify-end">
                        <div>
                            <button
                                onClick={e => {
                                    e.preventDefault();
                                    navigate(DASHBOARD);
                                }}
                                className="flex items-center bg-blue-600 rounded-md px-4 py-2 mt-1 sm:mt-0 mb-2"
                            >
                                <span className="text-base font-semibold text-white">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountVerification;
