import React from 'react';
import { CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip, AreaChart, Area, ReferenceLine } from 'recharts';

const Graph = ({ data, height, budgetLine, averageLine, budget }) => {
    const formatYAxisTick = tick => {
        if (tick >= 1000) {
            return `${tick / 1000}k`;
        }
        return tick;
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="custom-tooltip bg-white p-2">
                    <p>
                        <b>Date:</b> {label}
                    </p>
                    {payload &&
                        payload.length > 0 &&
                        payload.map((entry, index) => (
                            <p key={index}>
                                <b> {entry.dataKey === 'amount' ? 'Amount' : 'Average'}:</b> ${entry.value}
                            </p>
                        ))}
                </div>
            );
        }

        return null;
    };

    return (
        <ResponsiveContainer width="100%" height={height}>
            <AreaChart data={data} margin={{ top: 10, right: 30, left: -20, bottom: 0 }}>
                <defs>
                    <linearGradient id="colorAmount" x1="0" y1="0" x2="0" y2="100%">
                        <stop offset="5%" stopColor="#4B61FE" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#4B61FE" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <XAxis dataKey="name" tickLine={false} tick={{ fill: 'gray', fontSize: 12 }} />
                <YAxis
                    type="number"
                    tickCount={5}
                    interval={0}
                    tickFormatter={formatYAxisTick}
                    tickLine={false}
                    tick={{ fill: 'gray', fontSize: 12 }}
                />

                <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false} />
                <Tooltip content={<CustomTooltip />} />
                <Area type="monotone" dataKey="amount" stroke="#4B61FE" fillOpacity={1} fill="url(#colorAmount)" />

                {budgetLine && <ReferenceLine y={budget} stroke="red" label="" />}
                {averageLine && <Area type="monotone" dataKey="movingAverageMonthly" stroke="green" fill="none" fillOpacity={1} />}
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default Graph;
