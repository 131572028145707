import React, { useEffect, useState } from 'react';
import Graph from './Graph';
import GraphDropdown from './GraphDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getIncludedCategories } from '../user/user.slice';
import InformationTooltip from './InformationTooltip';
import { ALL_CATEGORIES } from '../../constants/common';
import { getYearlyTransactionsByCategory, setSelectedCategory } from './dashboard.slice';

const GraphContainer = () => {
    const dispatch = useDispatch();

    const [selectedUserCategoryId, setSelectedUserCategoryId] = useState(null);

    const { acceptedCategories } = useSelector(state => state.user);

    useEffect(() => {
        dispatch(getIncludedCategories());
    }, []);

    useEffect(() => {
        dispatch(setSelectedCategory(selectedUserCategoryId));
        const currentDate = new Date();
        const startDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1);
        dispatch(getYearlyTransactionsByCategory(selectedUserCategoryId, startDate, currentDate));
    }, [selectedUserCategoryId]);

    const options = acceptedCategories
        ? [
              {
                  label: 'All Expenses',
                  value: ALL_CATEGORIES,
              },
              ...acceptedCategories.map(category => ({
                  label: category.categoryName,
                  value: category.id,
              })),
          ]
        : [];

    const handleChange = event => {
        const userCategoryId = event.target.value;
        setSelectedUserCategoryId(userCategoryId);
    };

    useEffect(() => {
        if (acceptedCategories && acceptedCategories.length > 0) {
            // setSelectedUserCategoryId(acceptedCategories[3].id);
            setSelectedUserCategoryId(ALL_CATEGORIES);
        }
    }, [acceptedCategories]);

    // Get data here and send it to the GRAPH Component
    const { annualTransactionsGraphData } = useSelector(state => state.dashboard);

    const data = annualTransactionsGraphData?.map(item => {
        const date = new Date(item.year, item.month - 1);
        const monthName = date.toLocaleString('en-US', { month: 'short' });

        return {
            name: `${monthName}, ${item.year}`,
            amount: parseFloat(item.totalAmount),
        };
    });

    return (
        <div className="relative bg-white rounded-lg h-64 mr-4">
            <div className="absolute top-1 right-2">
                <InformationTooltip desc={'Graphs'} />
            </div>

            <div className="flex flex-col items-between px-3 pb-3 pt-4">
                <div className="flex justify-between items-center mb-4">
                    <span className="text-sm font-semibold w-1/2 ">Annual Transactions</span>
                    <GraphDropdown options={options} onChange={handleChange} defaultValue={options[0]} />
                </div>
                {acceptedCategories && acceptedCategories.length > 0 && <Graph data={data} height={160} />}
            </div>
        </div>
    );
};

export default GraphContainer;
