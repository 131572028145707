import React from 'react';
import Loader from '../utilities/Loader';

const KeywordsSection = ({ keywords, selectedKeyword, handleKeywordClick, handleManualKeyword, manualKeyWord }) => {
    return (
        <div className="w-full">
            <div className="text-md font-bold mb-1">Possible Keywords:</div>
            <div className="flex flex-wrap gap-1 mb-2 ">
                {keywords ? (
                    <div className="flex flex-col w-full">
                        <div className="flex mx-3 mb-2">
                            {keywords.map(keyword => (
                                <button
                                    key={keyword}
                                    className={`bg-blue-500  text-white py-1 px-2 rounded mx-1 ${
                                        selectedKeyword === keyword
                                            ? 'bg-blue-800 border-2 border-blue-800'
                                            : 'bg-white border-2  border-lightBlueButtonBorder'
                                    }`}
                                    onClick={() => handleKeywordClick(keyword)}
                                >
                                    <span className={`text-sm  px-2 ${selectedKeyword === keyword ? 'text-white' : 'text-gray-400'}`}>
                                        {keyword}
                                    </span>
                                </button>
                            ))}
                        </div>
                        <div className="flex flex-col w-full">
                            <span className="font-semibold mb-2 "> Manual Keyword:</span>{' '}
                            <input
                                className="rounded-md h-8 px-2 border-2 border-lightBlueButtonBorder"
                                value={manualKeyWord}
                                onChange={e => handleManualKeyword(e.target.value)}
                            />
                        </div>
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
};

export default KeywordsSection;
