import React from 'react';

const ProgressBar = ({ totalTransactions, classified }) => {
    let progress = Math.round((classified / totalTransactions) * 100);

    return (
        <div className="ml-6">
            {' '}
            <div className="text-md font-bold">
                {classified} transactions classified - {totalTransactions - classified} transactions left
            </div>
            <div class="progress">
                <div
                    class="progress-bar"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                >
                    {progress}% done
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
