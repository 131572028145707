import React, { useEffect } from 'react';
import StatCard from './StatCard';
import BudgetBox from './BudgetBox';
import RecentTransactions from './RecentTransactions';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAverageMonthlySpend,
    getCurrentMonthlySpend,
    getHighestMonthSpend,
    getLastMonthlySpend,
    getLowestMonthSpend,
    getYearlyTransactionsByCategory,
} from './dashboard.slice';
import GraphContainer from './GraphContainer';
import 'react-tooltip/dist/react-tooltip.css';
import IconWelcome from './IconWelcome';

const DashboardContent = () => {
    const dispatch = useDispatch();

    const { currentMonthlySpend, lastMonthlySpend, averageMonthlySpend, highestMonthSpend, lowestMonthSpend } = useSelector(
        state => state.dashboard,
    );

    useEffect(() => {
        dispatch(getCurrentMonthlySpend());
        dispatch(getLastMonthlySpend());
        dispatch(getAverageMonthlySpend());
        dispatch(getHighestMonthSpend());
        dispatch(getLowestMonthSpend());
        dispatch(getYearlyTransactionsByCategory());
    }, []);

    return (
        <div className="grid grid-cols-12 h-full">
            <div className="col-span-12 lg:col-span-8 w-full p-10">
                <IconWelcome />

                <div className="mb-5">
                    <h5 className="font-semibold text-md">Analytics</h5>
                    <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-1">
                        <BudgetBox />
                        <GraphContainer />
                    </div>
                </div>

                <h5 className="font-semibold text-md">Statistics</h5>
                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                    <StatCard
                        title="Current Monthly Spend"
                        value={currentMonthlySpend}
                        textColor={'blue'}
                        backgroundColor={'blue'}
                        backgroundImage={'lightbluemask'}
                        tooltip={'Current Monthly Spend'}
                    />
                    <StatCard
                        title="Last Month Spend"
                        value={lastMonthlySpend}
                        textColor={'white'}
                        backgroundColor={'darkblue'}
                        backgroundImage={'darkbluemask'}
                        tooltip={'Last Month Spend'}
                    />
                    <StatCard
                        title="Average Monthly"
                        value={averageMonthlySpend}
                        textColor={'white'}
                        backgroundColor={'black'}
                        backgroundImage={'blackmask'}
                        tooltip={'Average Monthly spend from last 6 months, excluding current month'}
                    />
                    <StatCard
                        title="Highest Month Spend"
                        value={highestMonthSpend}
                        textColor={'blue'}
                        backgroundColor={'blue'}
                        backgroundImage={'lightbluemask'}
                        tooltip={'Highest Month Spend from last 6 months, excluding current month'}
                    />
                    <StatCard
                        title="Lowest Month Spend"
                        value={lowestMonthSpend}
                        textColor={'white'}
                        backgroundColor={'darkblue'}
                        backgroundImage={'darkbluemask'}
                        tooltip={'Lowest Month Spend from last 6 months, excluding current month'}
                    />
                </div>
            </div>
            <div className="col-span-12 lg:col-span-4 h-screen ml-4 lg:ml-0">
                <RecentTransactions />
            </div>
        </div>
    );
};

export default DashboardContent;
