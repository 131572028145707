import React, { useEffect, useState } from 'react';
import { REPORTING_BUTTONS, DATE_TIME_FORMAT } from '../../constants/common';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import LeftNavigation from './LeftNavigation';
import SideBarContent from './SideBarContent';
import DetailedTable from '../reporting/DetailedTable';
import { getEarliestTransactionDate } from '../reporting/report.slice';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import moment from 'moment/moment';

const DetailedTableSection = () => {
    const dispatch = useDispatch();
    const [selectedDropdownDate, setSelectedDropdownDate] = useState(moment().subtract(1, 'month').endOf('month').format(DATE_TIME_FORMAT));

    const { earliestDate } = useSelector(state => state.report);

    useEffect(() => {
        dispatch(getEarliestTransactionDate());
        // eslint-disable-next-line
    }, []);

    const formatDate = date => {
        const options = { month: 'short', year: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
    };

    const getMonths = earliestDate => {
        const months = [];
        const currentDate = new Date();
        const earliestDateObj = new Date(earliestDate);

      
        while (currentDate >= earliestDateObj) {
            months.push(formatDate(currentDate));
            currentDate.setMonth(currentDate.getMonth() - 1);
        }
        return months;
    };

    const formattedMonths = getMonths(earliestDate);
    const dropdownOptions = formattedMonths.map(formattedMonth => ({
        value: new Date(formattedMonth),
        label: formattedMonth,
    }));

    const handleDropdownChange = selectedOption => {
        const selectedDate = selectedOption.value;
        setSelectedDropdownDate(selectedDate);
    };

    return (
        <div className="bg-darkWhite">
            <Header />
            <div className="flex">
                <div className="hidden md:block lg:block">
                    <LeftNavigation selectedButton={'ant-design'} />
                </div>
                <div className="grid grid-cols-12">
                    <div className="hidden md:col-span-0 lg:col-span-2 lg:block">
                        <SideBarContent
                            title={'Reporting'}
                            desc={'Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.'}
                            logo={'fas fa-sack-dollar'}
                            buttons={REPORTING_BUTTONS}
                        />
                    </div>
                    <div className="col-span-12 lg:col-span-10 font-manrope px-10 h-full w-screen md:w-full ">
                        <div className="flex flex-col md:flex-row w-full justify-between items-center mt-5 mb-3 ">
                            <span className="text-md font-semibold ">Detailed Table</span>
                            {earliestDate && (
                                <Dropdown
                                    className="ml-8 text-gray-300 border-2 border-blueBorder"
                                    options={dropdownOptions}
                                    onChange={selectedOption => handleDropdownChange(selectedOption)}
                                    placeholder={dropdownOptions[1].label}
                                />
                            )}
                        </div>
                        <div className="flex flex-col lg:flex-row items-center justify-between gap-y-5 lg:gap-y-0">
                            <DetailedTable selectedDate={selectedDropdownDate} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailedTableSection;
