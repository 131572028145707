import React, { useState, useEffect } from 'react';
import { DATE_TIME_FORMAT, REPORTING_BUTTONS } from '../../constants/common';
import Header from './Header';
import LeftNavigation from './LeftNavigation';
import SideBarContent from './SideBarContent';
import DetailedSummaryGraphSection from '../reporting/DetailedSummaryGraphSection';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { getEarliestTransactionDate } from '../reporting/report.slice';
import { getBudgetCreatorData } from './dashboard.slice';
import ToggleSwitch from '../utilities/ToggleSwitch';
import moment from 'moment/moment';

const SummarySection = () => {
    const dispatch = useDispatch();
    const [showDetailedTransactions, setShowDetailedTransactions] = useState(false);
    const [selectedDropdownDate, setSelectedDropdownDate] = useState(moment().subtract(1, 'month').endOf('month').format(DATE_TIME_FORMAT));

    const { earliestDate } = useSelector(state => state.report);

    useEffect(() => {
        dispatch(getEarliestTransactionDate());
        // eslint-disable-next-line
    }, []);

    const handleToggle = checked => {
        setShowDetailedTransactions(checked);
    };

    const formatDate = date => {
        const options = { month: 'short', year: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
    };

    const getMonths = earliestDate => {
        const months = [];
        const currentDate = new Date();
        const earliestDateObj = new Date(earliestDate);
        while (currentDate >= earliestDateObj) {
            months.push(formatDate(currentDate));
            currentDate.setMonth(currentDate.getMonth() - 1);
        }
        return months;
    };

    const formattedMonths = getMonths(earliestDate);
    const dropdownOptions = formattedMonths.map(formattedMonth => ({
        value: new Date(formattedMonth),
        label: formattedMonth,
    }));

    const handleDropdownChange = selectedOption => {
        const selectedDate = selectedOption.value;
        setSelectedDropdownDate(selectedDate);
        const dateObj = new Date(selectedDate);
        const startDate = moment(dateObj).subtract(6, 'month').startOf('month').format(DATE_TIME_FORMAT);
        // including the current month here, but will not be counted for avg, only used for total_current month expenses
        const endDate = moment(dateObj).endOf('month').format(DATE_TIME_FORMAT);
        dispatch(getBudgetCreatorData(startDate, endDate, null, true));
    };

    return (
        <div className="bg-darkWhite">
            <Header />
            <div className="flex">
                <div className="hidden md:block lg:block">
                    <LeftNavigation selectedButton={'ant-design'} />
                </div>
                <div className="grid grid-cols-12 w-full">
                    <div className="hidden md:col-span-0 lg:col-span-2 lg:block">
                        <SideBarContent
                            title={'Reporting'}
                            desc={'Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.'}
                            logo={'ant-design'}
                            buttons={REPORTING_BUTTONS}
                        />
                    </div>
                    <div className="col-span-12 lg:col-span-10 font-manrope px-10 h-screen w-full ">
                        <div className="flex flex-col md:flex-row w-full justify-between items-center mt-5 mb-3 ">
                            <span className="text-md font-semibold ">
                                {selectedDropdownDate ? moment(selectedDropdownDate).format('MMMM YYYY') : ''}- Summary & Detail
                            </span>
                            <div className="flex flex-col md:flex-row items-center ">
                                <div className="flex items-center py-3 ">
                                    <ToggleSwitch
                                        title={'Show Detailed Transactions'}
                                        checked={showDetailedTransactions}
                                        handleToggle={handleToggle}
                                    />
                                </div>

                                {earliestDate && (
                                    <Dropdown
                                        className="ml-8 text-gray-300 border-2 border-blueBorder"
                                        options={dropdownOptions}
                                        onChange={selectedOption => handleDropdownChange(selectedOption)}
                                        placeholder={dropdownOptions[1].label}
                                    />
                                )}
                            </div>
                        </div>
                        <DetailedSummaryGraphSection showDetailedTransactions={showDetailedTransactions} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SummarySection;
