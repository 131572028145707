import { RouterProvider } from 'react-router';
import { router } from './router';
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function App() {
    return (
        <div className="font-manrope">
            <RouterProvider router={router} />
        </div>
    );
}
