import { createSlice } from '@reduxjs/toolkit';
import network from '../../services/network';
import {
    CREATE_LINK_TOKEN,
    EXCHANGE_PUBLIC_TOKEN,
    SAVE_USER,
    SET_NICKNAME,
    ADD_OWNER,
    GET_OWNER_LIST,
    SAVE_MANUAL_ACCOUNT,
    GET_CATEGORIES,
    ACCEPT_CATEGORY,
    REJECT_CATEGORY,
    UPLOAD_FILE,
    SAVE_PLAID_TRANSACTIONS,
    GET_ACCOUNT_MANAGERS,
    GET_NO_MANAGER_LIST,
    CATEGORISED_TRANSACTIONS_LIST,
    UNASSIGN_MANAGER,
    UPDATE_MANAGER_DETAILS,
    ASSIGN_ACCOUNT_MANAGER,
    GET_TRANSACTION_STATUS,
    INCLUDED_CATEGORIES_COLLECTION,
    INCLUDED_CATEGORIES,
    GET_ACCOUNTS_LIST,
} from '../../constants/network-calls';
import { showToast } from '../toast-handler/toast-handler';
import Cookies from 'js-cookie';
import { BANK_ACCOUNT_ADDED, CARD_OWNER_ADDED_MESSAGE, FAILURE, NICKNAME_ADDED, SUCCESS } from '../../constants/common';
import { getTotalTransactionsByAccount, setAccountsWithCount } from '../steps/bank.slice';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        linkToken: null,
        accessToken: null,
        banks: null,
        owners: null,
        accountManagers: null,
        accountManagersWithAccounts: null,
        acceptedCategories: null,
        rejectedCategories: null,
        transactions: null,
        keywords: null,
        categorisedTransactionsList: null,
        classified: 0,
        totalTransactions: 0,
        transactionStatus: null,
        includedCategoriesCollection: null,
        isCategoriesLoading: false,
        isFileUploading: false,
        isBanksLoading: false,
        isAccountManagersLoading: false,
    },
    reducers: {
        setLinkToken(state, action) {
            state.linkToken = action.payload;
        },
        setAccessToken(state, action) {
            state.accessToken = action.payload;
        },
        setBanks(state, action) {
            state.banks = action.payload;
        },
        setOwners(state, action) {
            state.owners = action.payload;
        },
        setAcceptedCategories(state, action) {
            state.acceptedCategories = action.payload;
        },
        setRejectedCategories(state, action) {
            state.rejectedCategories = action.payload;
        },
        setTransactions(state, action) {
            state.transactions = action.payload;
        },
        setKeywords(state, action) {
            state.keywords = action.payload;
        },
        setAccountMangersWithAccounts(state, action) {
            state.accountManagersWithAccounts = action.payload;
        },
        setAccountMangers(state, action) {
            state.accountManagers = action.payload;
        },
        setCategorisedTransactions(state, action) {
            state.categorisedTransactionsList = action.payload;
        },
        setClassified(state, action) {
            state.classified = action.payload;
        },
        setTotalTransactions(state, action) {
            state.totalTransactions = action.payload;
        },
        setTransactionStatus(state, action) {
            state.transactionStatus = action.payload;
        },
        setIncludedCategoriesCollection(state, action) {
            state.includedCategoriesCollection = action.payload;
        },
        setIsCategoriesLoading(state, action) {
            state.isCategoriesLoading = action.payload;
        },
        setIsFileUploading(state, action) {
            state.isFileUploading = action.payload;
        },
        setIsBanksLoading(state, action) {
            state.isBanksLoading = action.payload;
        },
        setIsAccountManagersLoading(state, action) {
            state.isAccountManagersLoading = action.payload;
        },
    },
});

// --------------------------------------------THUNK FUNCTIONS----------------------------------------

export const saveUser = (fullName, email) => (dispatch, getState) => {
    network
        .fetch('POST', SAVE_USER, { fullName, email }, null)
        .then(res => {
            const { data } = res;
            const { id } = data;
            Cookies.set('userId', id);
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const saveManualAccount = bankDetails => (dispatch, getState) => {
    const userId = Cookies.get('userId');
    network
        .fetch('POST', SAVE_MANUAL_ACCOUNT, { userId, bankDetails }, null)
        .then(res => {
            const { data } = res;
            dispatch(setAccountsWithCount(data));
            showToast(BANK_ACCOUNT_ADDED, SUCCESS);
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const createLinkToken = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', CREATE_LINK_TOKEN, { userId }, null)
        .then(res => {
            const { data } = res;
            dispatch(setLinkToken(data['link_token']));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const exchangePublicToken = (publicToken, institution, accounts) => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', EXCHANGE_PUBLIC_TOKEN, { userId, publicToken, institution, accounts }, null)
        .then(res => {
            const { data } = res;
            const { accessToken, userAccounts } = data;
            Cookies.set('accessToken', accessToken);
            dispatch(setAccessToken(accessToken));
            dispatch(setBanks(userAccounts));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const plaidTransactions = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');
    const accessToken = Cookies.get('accessToken');

    network
        .fetch('POST', SAVE_PLAID_TRANSACTIONS, { userId, accessToken }, null)
        .then(res => {})
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const fetchPlaidTransactions = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', SAVE_PLAID_TRANSACTIONS, { userId }, null)
        .then(res => {
            // showToast('Transaction Sync Started, please refresh after 5 minutes', SUCCESS);
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getNoManagerList = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', GET_NO_MANAGER_LIST, { userId }, null)
        .then(res => {
            const { data } = res;
            dispatch(setBanks(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getBankList = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');
    dispatch(setIsBanksLoading(true));
    network
        .fetch('POST', GET_ACCOUNTS_LIST, { userId }, null)
        .then(res => {
            const { data } = res;
            dispatch(setBanks(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        })
        .finally(() => {
            dispatch(setIsBanksLoading(false));
        });
};

export const getTransactionStatus = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');
    network
        .fetch('POST', GET_TRANSACTION_STATUS, { userId }, null)
        .then(res => {
            const { data } = res;
            dispatch(setTransactionStatus(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const setNickName = (bankDetails, nickname) => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', SET_NICKNAME, { userId, bankDetails, nickname }, null)
        .then(res => {
            dispatch(getTotalTransactionsByAccount());
            showToast(NICKNAME_ADDED, SUCCESS);
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const addOwner = (partner, account) => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', ADD_OWNER, { userId, partner, account }, null)
        .then(res => {
            const { data } = res;

            const { managers, managersWithAccounts } = data;

            dispatch(setAccountMangersWithAccounts(managersWithAccounts));
            dispatch(setAccountMangers(managers));
            showToast(CARD_OWNER_ADDED_MESSAGE, SUCCESS);
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getOwnerList = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', GET_OWNER_LIST, { userId }, null)
        .then(res => {
            const { data } = res;
            dispatch(setOwners(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getAccountMangers = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    dispatch(setIsAccountManagersLoading(true));

    network
        .fetch('POST', GET_ACCOUNT_MANAGERS, { userId }, null)
        .then(res => {
            const { data } = res;
            const { managers, managersWithAccounts } = data;
            dispatch(setAccountMangersWithAccounts(managersWithAccounts));
            dispatch(setAccountMangers(managers));
        })
        .catch(err => {
            showToast(err, FAILURE);
        })
        .finally(() => {
            dispatch(setIsAccountManagersLoading(false));
        });
};

export const updateManagerDetails = (id, data) => (dispatch, getState) => {
    const { fullName, email, phone } = data;
    network
        .fetch('POST', UPDATE_MANAGER_DETAILS, { id, fullName, email, phone }, null)
        .then(res => {
            showToast('Account Manager Added', SUCCESS);
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const assignAccountManager = (managerId, accountId) => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', ASSIGN_ACCOUNT_MANAGER, { userId, managerId, accountId }, null)
        .then(res => {
            const { data } = res;

            const { managers, managersWithAccounts } = data;

            dispatch(setAccountMangersWithAccounts(managersWithAccounts));
            dispatch(setAccountMangers(managers));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getIncludedCategoriesCollection = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', INCLUDED_CATEGORIES_COLLECTION, { userId }, null)
        .then(res => {
            const { data } = res;
            dispatch(setIncludedCategoriesCollection(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getIncludedCategories = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', INCLUDED_CATEGORIES, { userId }, null)
        .then(res => {
            const { data } = res;
            dispatch(setAcceptedCategories(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getCategories = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    dispatch(setIsCategoriesLoading(true));
    network
        .fetch('POST', GET_CATEGORIES, { userId }, null)
        .then(res => {
            const { data } = res;
            const { accepted, rejected } = data;
            dispatch(setAcceptedCategories(accepted));
            dispatch(setRejectedCategories(rejected));
        })
        .catch(err => {
            showToast(err, FAILURE);
        })
        .finally(() => {
            dispatch(setIsCategoriesLoading(false));
        });
};

export const acceptCategory = categoryId => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', ACCEPT_CATEGORY, { userId, categoryId }, null)
        .then(res => {
            const { data } = res;
            const { accepted, rejected } = data;
            dispatch(setAcceptedCategories(accepted));
            dispatch(setRejectedCategories(rejected));
            showToast('Category moved to included!', SUCCESS);
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const rejectCategory = id => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', REJECT_CATEGORY, { userId, id }, null)
        .then(res => {
            const { data } = res;
            const { accepted, rejected } = data;
            dispatch(setAcceptedCategories(accepted));
            dispatch(setRejectedCategories(rejected));
            showToast('Category moved to excluded!', SUCCESS);
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const getCategorisedTransactionsList = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', CATEGORISED_TRANSACTIONS_LIST, { userId }, null)
        .then(res => {
            const { data } = res;
            dispatch(setCategorisedTransactions(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const unassign = accountId => (dispatch, getState) => {
    network
        .fetch('POST', UNASSIGN_MANAGER, { accountId }, null)
        .then(res => {})
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const uploadFile =
    (formData, fileHeaders, selectedAccount, selectedDateFormat, setShow, setDateValue, refreshForm) => (dispatch, getState) => {
        const userId = Cookies.get('userId');

        dispatch(setIsFileUploading(true));
        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        const date = fileHeaders.find(obj => obj.value === 'date').option;
        const desc = fileHeaders.find(obj => obj.value === 'desc').option;
        const amount = fileHeaders.find(obj => obj.value === 'amount').option;

        const url = UPLOAD_FILE;

        formData.append('desc', desc);
        formData.append('amount', amount);
        formData.append('account', selectedAccount);
        formData.append('date', date);
        formData.append('dateformat', selectedDateFormat);
        formData.append('userid', userId);

        network
            .fetch('POST', url, formData, null, {
                headers,
            })
            .then(res => {
                if (setShow) {
                    setShow(false);
                }
                setDateValue(null);
                dispatch(setIsFileUploading(false));
                showToast('File Uploaded Successfully', SUCCESS);
                refreshForm();
            })
            .catch(err => {
                showToast(err, FAILURE);
                dispatch(setIsFileUploading(false));
            })
            .finally(() => {
                dispatch(setIsFileUploading(false));
            });
    };

export const {
    setLinkToken,
    setAccessToken,
    setBanks,
    setOwners,
    setAcceptedCategories,
    setRejectedCategories,
    setTransactions,
    setKeywords,
    setAccountMangersWithAccounts,
    setAccountMangers,
    setCategorisedTransactions,
    setClassified,
    setTotalTransactions,
    setTransactionStatus,
    setIncludedCategoriesCollection,
    setIsFileUploading,
    setIsCategoriesLoading,
    setIsBanksLoading,
    setIsAccountManagersLoading,
} = userSlice.actions;

export default userSlice.reducer;
