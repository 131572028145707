import React, { useState, useEffect } from 'react';
import { ANT_DESIGN_ICON, MONTHLY_OPTIONS, REPORTING_BUTTONS, DATE_TIME_FORMAT } from '../../constants/common';
import Header from './Header';
import LeftNavigation from './LeftNavigation';
import SideBarContent from './SideBarContent';
import ToggleSwitch from '../utilities/ToggleSwitch';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { getEarliestTransactionDate } from '../reporting/report.slice';
import WhiteBlueButton from '../utilities/WhiteBlueButton';
import AllCategoryGraphs from '../reporting/AllCategoryGraphs';
import moment from 'moment/moment';

const CategoryInsightSection = () => {
    const dispatch = useDispatch();
    const [showBudgetLine, setShowBudgetLine] = useState(false);
    const [showAverageMonthly, setShowAverageMonthly] = useState(false);
    const [selectedDropdownDate, setSelectedDropdownDate] = useState(moment().subtract(1, 'month').endOf('month').format(DATE_TIME_FORMAT));
    const [selectedKeyword, setSelectedKeyword] = useState(12);
    const { earliestDate } = useSelector(state => state.report);

    useEffect(() => {
        dispatch(getEarliestTransactionDate());
        // eslint-disable-next-line
    }, []);

    const handleToggleBudgetLine = checked => {
        setShowBudgetLine(checked);
    };

    const handleToggleAverageMonthly = checked => {
        setShowAverageMonthly(checked);
    };

    const formatDate = date => {
        const options = { month: 'short', year: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
    };

    const getMonths = earliestDate => {
        const months = [];
        const currentDate = new Date();
        const earliestDateObj = new Date(earliestDate);

        while (currentDate >= earliestDateObj) {
            months.push(formatDate(currentDate));
            currentDate.setMonth(currentDate.getMonth() - 1);
        }
        return months;
    };

    const formattedMonths = getMonths(earliestDate);
    const dropdownOptions = formattedMonths.map(formattedMonth => ({
        value: new Date(formattedMonth),
        label: formattedMonth,
    }));

    const handleDropdownChange = selectedOption => {
        const selectedDate = selectedOption.value;
        setSelectedDropdownDate(selectedDate);
    };

    const handleKeywordClick = button => {
        setSelectedKeyword(button);
    };

    return (
        <div className="bg-darkWhite">
            <Header />
            <div className="flex">
                <div className="hidden md:block lg:block ">
                    <LeftNavigation selectedButton={ANT_DESIGN_ICON} />
                </div>
                <div className="grid grid-cols-12">
                    <div className="hidden md:col-span-0 lg:col-span-2 lg:block">
                        <SideBarContent
                            title={'Reporting'}
                            desc={'Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.'}
                            logo={'fas fa-sack-dollar'}
                            buttons={REPORTING_BUTTONS}
                        />
                    </div>
                    <div className="col-span-12 lg:col-span-10 font-manrope px-10 h-screen w-screen md:w-full ">
                        <div className="flex flex-col md:flex-row w-full justify-between items-center mt-5 mb-3 ">
                            <span className="text-md font-semibold ">Category Insights</span>
                        </div>
                        <div className="flex flex-col lg:flex-row items-center justify-between gap-y-5 lg:gap-y-0">
                            <span className="font-medium">Select</span>
                            <div className="flex gap-x-5 pl-10">
                                {MONTHLY_OPTIONS.map(keyword => (
                                    <WhiteBlueButton
                                        key={keyword.value}
                                        value={keyword.value}
                                        selectedValue={selectedKeyword || 12}
                                        handleKeywordClick={() => handleKeywordClick(keyword.value)}
                                    />
                                ))}
                            </div>

                            <div className="flex items-center">
                                <ToggleSwitch
                                    title={'Show Budget Line'}
                                    checked={showBudgetLine}
                                    handleToggle={handleToggleBudgetLine}
                                    color={'#ff0000'}
                                />
                                <ToggleSwitch
                                    title={'Averge Monthly'}
                                    checked={showAverageMonthly}
                                    handleToggle={handleToggleAverageMonthly}
                                    color={'#00cc00'}
                                />
                            </div>
                            {earliestDate && (
                                <Dropdown
                                    className="ml-8 text-gray-300 border-2 border-blueBorder"
                                    options={dropdownOptions}
                                    onChange={selectedOption => handleDropdownChange(selectedOption)}
                                    placeholder={dropdownOptions[1].label}
                                />
                            )}
                        </div>
                        <AllCategoryGraphs
                            selectedNumberOfMonths={selectedKeyword}
                            selectedMonthDate={selectedDropdownDate}
                            showBudgetLine={showBudgetLine}
                            showAverageMonthly={showAverageMonthly}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryInsightSection;
