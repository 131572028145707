import React from 'react';
import BreadCrumbs from './BreadCrumbs';
import { useNavigate } from 'react-router-dom';

const LeftBanner = ({ step, logo, title, desc, back }) => {
    const navigate = useNavigate();

    return (
        <div className=" bg-blue-600 md:fixed md:top-0 md:left-0 md:h-full md:w-5/12 lg:w-4/12 py-4 pb-2 md:overflow-y-auto">
            <div className="col-span-1 flex-col my-auto h-full">
                <img src="/images/cupet.png" alt="Logo" className="h-28 px-3" />
                <div className="px-5 lg:px-10 flex flex-col flex-grow h-5/6">
                    <BreadCrumbs stepNumber={parseInt(step)} />
                    <div className="mt-4 lg:mt-8 h-full flex flex-col justify-between">
                        <div className="flex flex-col w-full lg:w-4/5 mt-4 pb   -2">
                            <p className="text-white text-left">Step {step}</p>
                            <div className="flex flex-row items-center mt-2">
                                <div className="flex flex-col mr-2">
                                    <img src="/images/whitearrow.png" alt="White Arrow" />
                                </div>
                                <div className="flex flex-col">
                                    <img src={`/images/${logo}.png`} alt="Logo" />
                                </div>
                            </div>
                            <span className="text-xl lg:text-2xl text-white font-semibold text-left my-2 lg:my-2">{title}</span>
                            <p className="text-white text-left my-2 lg:my-4">{desc}</p>
                        </div>
                        <div>
                            {step !== '1' && (
                                <button
                                    onClick={e => {
                                        e.preventDefault();
                                        navigate(back);
                                    }}
                                    className="w-24 flex items-center bg-blue-600 rounded-md px-4 py-2 border mt-4 border-white"
                                >
                                    <span className="text-base font-semibold text-white">Back</span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeftBanner;
