import { useRouteError } from 'react-router';

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div className="container">
            <div className="row vh-100">
                <div className="d-flex col flex-column align-items-center justify-content-center">
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error.statusText || error.message}</i>
                    </p>
                </div>
            </div>
        </div>
    );
}
