import React from 'react';
import Switch from 'react-switch';

const ToggleSwitch = ({ title, checked, handleToggle, color }) => {
    return (
        <>
            <span className="mx-3 text-sm ">{title}</span>
            <Switch
                onChange={handleToggle}
                checked={checked}
                onColor={color ? color : '#007acc'}
                handleDiameter={24}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={24}
                width={40}
                className="react-switch"
            />
        </>
    );
};

export default ToggleSwitch;
