import React from 'react';
import InformationTooltip from './InformationTooltip';

const StatCard = ({ title, value, textColor, backgroundImage, backgroundColor, information, tooltip }) => {
    const backgroundImageStyle = {
        backgroundImage: `url(/images/${backgroundImage}.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    };

    return (
        <div
            className={
                backgroundColor === 'black'
                    ? `bg-gray-700 h-20  rounded-lg flex flex-col justify-center pl-2 `
                    : backgroundColor === 'darkblue'
                    ? `bg-darkBlueStatisticBox h-20  rounded-lg flex flex-col justify-center pl-2 `
                    : `bg-lightBlueStatisticBox h-20  rounded-lg flex flex-col justify-center pl-2 `
            }
            style={backgroundImageStyle}
        >
            <div className="flex justify-between pr-2">
                <span className={textColor === 'white' ? `text-xs text-white font-medium` : `text-xs text-darkBlueText font-medium`}>
                    {title}
                </span>
                <InformationTooltip desc={tooltip} />
            </div>
            <span className="text-xl flex">
                <span className={textColor === 'white' ? `text-md text-white font-semibold` : `text-md text-darkBlueText font-semibold`}>
                    {value ? value : 0}$
                </span>
            </span>
        </div>
    );
};

export default StatCard;
