import React from 'react';
import BudgetCreator from '../settings/BudgetCreator';
import Header from './Header';
import LeftNavigation from './LeftNavigation';
import { SETTINGS_BUTTONS, USER_GEAR_ICON } from '../../constants/common';
import SideBarContent from './SideBarContent';

const EditCreateSection = () => {
    return (
        <div className="bg-darkWhite">
            <Header />
            <div className="flex">
                <div className="hidden md:block lg:block">
                    <LeftNavigation selectedButton={USER_GEAR_ICON} />
                </div>
                <div className="grid grid-cols-12">
                    <div className="hidden md:col-span-0 lg:col-span-2 lg:block">
                        <SideBarContent
                            title={'Settings'}
                            desc={'Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.'}
                            logo={'fa-solid fa-user-gear'}
                            buttons={SETTINGS_BUTTONS}
                        />
                    </div>
                    <div className="col-span-12 lg:col-span-10 ">
                        <>
                            <BudgetCreator />
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditCreateSection;
