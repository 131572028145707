import { useUser, UserButton } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const { user } = useUser();
    const navigate = useNavigate();

    return (
        <div className="flex items-center justify-between bg-white p-4 h-24 border-b-2 border-blue-100 font-manrope">
            <div className="flex items-center">
                <div className="mr-4 hidden md:block">
                    <img src="/images/cupet.png" alt="Logo" className="h-24" />
                </div>
            </div>

            <button className="bg-blue-600 text-white p-2" onClick={() => navigate('/dashboard/openai')}>
                Go to OpenAI Classification
            </button>

            <div>
                <div className="flex">
                    <UserButton />
                    {user && <span className="px-3 py-1 font-semibold hidden md:block">{user.fullName}</span>}
                </div>
            </div>
        </div>
    );
};

export default Header;
