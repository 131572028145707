import React from 'react';
import { useDispatch } from 'react-redux';
import { acceptCategory, rejectCategory } from '../user/user.slice';

const CategoryBox = ({ title, categories, accepted }) => {
    const dispatch = useDispatch();

    const moveCategory = id => {
        if (accepted) {
            dispatch(rejectCategory(id));
        } else {
            dispatch(acceptCategory(id));
        }
    };

    return (
        <div className="bg-lightBlue rounded-lg flex flex-col min-h-full">
            <div className="bg-mediumBlue text-white p-2 rounded-t-lg flex justify-center">
                <h2 className="text-lg font-medium">{title}</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 pl-4 py-4">
                {categories && (
                    <>
                        {categories.map(category => {
                            return (
                                <>
                                    <button
                                        key={category.id}
                                        onClick={() => moveCategory(category.id)}
                                        className="text-decoration-none text-dark h-4 flex justify-start"
                                        id={category.id}
                                    >
                                        <span className="text-xs hover:text-red-500 truncate">{category.categoryName}</span>
                                    </button>
                                </>
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
};

export default CategoryBox;
