import { createSlice } from '@reduxjs/toolkit';
import network from '../../services/network';
import {
    COUNT_KEYWORD,
    GET_TRANSACTION_LIST,
    CATEGORISE,
    DELETE_CATEOGRY,
    VERIFICATION_TRANSACTIONS,
    CATEGORISED_VERIFICATION_TRANSACTIONS,
    VERIFICATION_TRANSACTIONS_BY_CATEGORY,
    VERIFY_TRANSACTIONS,
    GET_ALL_UNCATEGORISED_TRANSACTIONS,
    CATEGORISE_SINGLE_TRANSACTION,
} from '../../constants/network-calls';
import { showToast } from '../toast-handler/toast-handler';
import Cookies from 'js-cookie';
import { FAILURE, SUCCESS } from '../../constants/common';
import {
    getAverageMonthlySpend,
    getCurrentMonthlySpend,
    getLastMonthlySpend,
    getLowestMonthSpend,
    getYearlyTransactionsByCategory,
} from '../dashboard/dashboard.slice';

const transactionSlice = createSlice({
    name: 'transaction',
    initialState: {
        keywordCount: null,
        transactions: null,
        keywords: null,
        totalTransactions: 0,
        classified: 0,
        verifyTransactions: null,
        currentCategory: null,
        index: 0,
        excludedTransactionIds: [],
        nextTransactionIsLoading: false,
        firstTransaction: null,
        isKeywordCountLoading: false,
        keywordsLoaded: false,
        isTransactionsLoading: false,
        isVerifiedTransactionLoading: false,
        isUncategorisedTransactionsLoading: false,
    },
    reducers: {
        setKeywordCount(state, action) {
            state.keywordCount = action.payload;
        },
        setTransactions(state, action) {
            state.transactions = action.payload;
        },
        setKeywords(state, action) {
            state.keywords = action.payload;
        },
        setVerifyTransactions(state, action) {
            state.verifyTransactions = action.payload;
        },
        setIndex(state, action) {
            state.index = action.payload;
        },
        setCurrentCategory(state, action) {
            state.currentCategory = action.payload;
        },
        setExcludedTransactions(state, action) {
            state.excludedTransactionIds = action.payload;
        },
        setClassified(state, action) {
            state.classified = action.payload;
        },
        setTotalTransactions(state, action) {
            state.totalTransactions = action.payload;
        },
        setNextTransactionIsLoading(state, action) {
            state.nextTransactionIsLoading = action.payload;
        },
        setFirstTransaction(state, action) {
            state.firstTransaction = action.payload;
        },
        setIsKeywordCountLoading(state, action) {
            state.isKeywordCountLoading = action.payload;
        },
        setKeywordsLoaded(state, action) {
            state.keywordsLoaded = action.payload;
        },
        setisTransactionsLoading(state, action) {
            state.isTransactionsLoading = action.payload;
        },
        setisVerifiedTransactionsLoading(state, action) {
            state.isVerifiedTransactionLoading = action.payload;
        },
        setIsUncategorisedTransactionsLoading(state, action) {
            state.isUncategorisedTransactionsLoading = action.payload;
        },
    },
});

// --------------------------------------------THUNK FUNCTIONS----------------------------------------

export const getTransactionList = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');
    dispatch(setKeywordsLoaded(false));
    dispatch(setisTransactionsLoading(true));

    network
        .fetch('POST', GET_TRANSACTION_LIST, { userId }, null)
        .then(res => {
            const { data } = res;
            const { keywords, totalTransactionsList, categorizedTransactions, matchingTransactions, firstTransaction } = data;
            if (matchingTransactions.length > 0) {
                dispatch(setTransactions(matchingTransactions));
                dispatch(setKeywords(keywords));
                dispatch(setKeywordCount(matchingTransactions.length));
                dispatch(setTotalTransactions(totalTransactionsList.length));
                dispatch(setClassified(categorizedTransactions.length));
                dispatch(setFirstTransaction(firstTransaction));
                dispatch(setKeywordsLoaded(true));
            }
        })
        .catch(err => {
            showToast(err, FAILURE);
        })
        .finally(() => {
            dispatch(setKeywordsLoaded(false));
            dispatch(setisTransactionsLoading(false));
        });
};

export const getAllUncategorisedTransactionList = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');
    dispatch(setIsUncategorisedTransactionsLoading(true));

    network
        .fetch('POST', GET_ALL_UNCATEGORISED_TRANSACTIONS, { userId }, null)
        .then(res => {
            const { data } = res;
            dispatch(setTransactions(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        })
        .finally(() => {
            dispatch(setIsUncategorisedTransactionsLoading(false));
        });
};

export const getKeywordCount = keyword => (dispatch, getState) => {
    const userId = Cookies.get('userId');
    dispatch(setIsKeywordCountLoading(true));
    network
        .fetch('POST', COUNT_KEYWORD, { userId, keyword }, null)
        .then(res => {
            const { data } = res;
            const { firstTransaction, matching } = data;

            if (matching && matching.length > 0) {
                dispatch(setTransactions(matching));
                dispatch(setKeywordCount(matching.length));
                dispatch(setFirstTransaction(firstTransaction));
            } else {
                dispatch(setTransactions([]));
                dispatch(setKeywordCount(0));
            }
        })
        .catch(err => {
            console.log(err);
            showToast(err, FAILURE);
        })
        .finally(() => {
            dispatch(setIsKeywordCountLoading(false));
        });
};

export const excludeTransactionFromLists = transactionId => (dispatch, getState) => {
    const { excludedTransactionIds, transactions } = getState().transaction;
    // Find the index of the transaction in the transactions array
    const transactionIndex = transactions.findIndex(transaction => transaction.id === transactionId);
    if (transactionIndex !== -1) {
        const updatedTransactions = [...transactions.slice(0, transactionIndex), ...transactions.slice(transactionIndex + 1)];
        const updatedExcludedTransactions = [...excludedTransactionIds, transactionId];
        dispatch(setTransactions(updatedTransactions));
        dispatch(setExcludedTransactions(updatedExcludedTransactions));
    }
};

export const categorise = (transactionIdsToCategorize, categoryId) => (dispatch, getState) => {
    const userId = Cookies.get('userId');
    dispatch(setNextTransactionIsLoading(true));
    dispatch(setKeywordsLoaded(false));
    network
        .fetch('POST', CATEGORISE, { userId, categoryId, transactionIdsToCategorize }, null)
        .then(res => {
            const { data } = res;
            const { keywords, totalTransactionsList, categorizedTransactions, matchingTransactions, firstTransaction } = data;
            if (matchingTransactions && matchingTransactions.length > 0) {
                dispatch(setTransactions(matchingTransactions));
                dispatch(setFirstTransaction(firstTransaction));
                dispatch(setKeywords(keywords));
                dispatch(setKeywordCount(matchingTransactions.length));
                dispatch(setTotalTransactions(totalTransactionsList.length));
                dispatch(setClassified(categorizedTransactions.length));
                dispatch(setVerifyTransactions(data));
                dispatch(setKeywordsLoaded(true));
                dispatch(setExcludedTransactions([]));
            } else {
                dispatch(setTransactions(null));
            }
        })
        .catch(err => {
            showToast(err, FAILURE);
        })
        .finally(() => {
            dispatch(setNextTransactionIsLoading(false));
        });
};

export const categorizeSingleTransaction = (transactionId, categoryId) => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    // const headers = {
    //     Authorization: `${id}`,
    // };

    network
        .fetch('POST', CATEGORISE_SINGLE_TRANSACTION, { userId, transactionId, categoryId }, null)
        .then(res => {
            const { data } = res;
            dispatch(setTransactions(data));
            dispatch(getCurrentMonthlySpend());
            dispatch(getLastMonthlySpend());
            dispatch(getAverageMonthlySpend());
            dispatch(getLowestMonthSpend());
            dispatch(getYearlyTransactionsByCategory(getState().dashboard.selectedCategory));
        })
        .catch(err => {
            showToast(err, FAILURE);
        })
        .finally(() => {
            showToast('Transaction has been categorized', SUCCESS);
        });
};

export const unCategorise = transactionId => (dispatch, getState) => {
    network
        .fetch('POST', DELETE_CATEOGRY, { transactionId }, null)
        .then(res => {
            const verifyTransactions = getState().transaction.verifyTransactions;
            const updatedTransactions = verifyTransactions.filter(transaction => transaction.id !== transactionId);
            dispatch(setVerifyTransactions(updatedTransactions));
        })
        .catch(err => {
            console.log(err);
            showToast(err, FAILURE);
        });
};

export const getVerificationTransactionsByCategory = categoryId => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', VERIFICATION_TRANSACTIONS_BY_CATEGORY, { userId, categoryId }, null)
        .then(res => {
            const { data } = res;
            dispatch(setVerifyTransactions(data));
        })
        .catch(err => {
            console.log(err);
            showToast(err, FAILURE);
        });
};

export const getVerificationTransactions = () => (dispatch, getState) => {
    const userId = Cookies.get('userId');
    dispatch(setisVerifiedTransactionsLoading(true));
    network
        .fetch('POST', VERIFICATION_TRANSACTIONS, { userId }, null)
        .then(res => {
            const { data } = res;
            if (data[0]) {
                const { id } = data[0].userCategory;
                const category = data[0].userCategory.category;

                dispatch(setCurrentCategory({ id: id, categoryName: category.categoryName }));
                dispatch(getVerificationTransactionsByCategory(id));
            } else {
                dispatch(setVerifyTransactions(null));
            }
        })
        .catch(err => {
            console.log(err);
            showToast(err, FAILURE);
        })
        .finally(() => {
            dispatch(setisVerifiedTransactionsLoading(false));
        });
};

export const returnCategory = categoryId => (dispatch, getState) => {
    network
        .fetch('POST', CATEGORISED_VERIFICATION_TRANSACTIONS, { categoryId }, null)
        .then(res => {
            const { data } = res;
            dispatch(setVerifyTransactions(data));
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const verifyTransactionCategory = categoryId => (dispatch, getState) => {
    const userId = Cookies.get('userId');

    network
        .fetch('POST', VERIFY_TRANSACTIONS, { userId, categoryId }, null)
        .then(res => {
            dispatch(getVerificationTransactions());
        })
        .catch(err => {
            showToast(err, FAILURE);
        });
};

export const {
    setKeywordCount,
    setIsKeywordCountLoading,
    setTransactions,
    setKeywords,
    setVerifyTransactions,
    setIndex,
    setCurrentCategory,
    setExcludedTransactions,
    setClassified,
    setTotalTransactions,
    setNextTransactionIsLoading,
    setFirstTransaction,
    setKeywordsLoaded,
    setisTransactionsLoading,
    setisVerifiedTransactionsLoading,
    setIsUncategorisedTransactionsLoading,
} = transactionSlice.actions;

export default transactionSlice.reducer;
